import { API } from 'aws-amplify';
import schoolAdminQueryResult from '../mocks/api/school_admin_search_results_user_profiles_mock.json';

const USER_PROFILE_API_NAME = 'userprofile';

export const SearchApi = {

    /**
     * Get user profiles' details for a given `APP_ROLE` and a set of filters to be applied.
     * @param {string} [userId] - ID of the user to exclude from the result set
     * @param {string} [accountRole] - Role of the user who is executing the query
     * @param {object} [filters] - Filters to be applied in the query
     * @returns An array containing `UserProfiles` attributes as well as `LastEvaluatedKey` from scan operation.
    */
    getUserProfiles: async (userId, accountRole, filters, lastEvaluatedKey = null) => {
        //if (process.env.NODE_ENV === 'development') return schoolAdminQueryResult;

        const init = {
            headers: {},
            response: true,
            queryStringParameters: {
                accountRole: accountRole,
                requestUserId: userId,
            },
            body: {
                filters: filters
            }
        };

        if (lastEvaluatedKey) {
            init.queryStringParameters['lastEvaluatedKey'] = lastEvaluatedKey;
        }

        return API.post(
            USER_PROFILE_API_NAME,
            `/search`,
            init
        );
    },
};
