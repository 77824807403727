import { Card } from "@mui/material";
import PropTypes from 'prop-types';

const CardRaisedRounded = ({ sx = [], children }) => {

    return (
        <Card
            sx={[{
                borderRadius: "4px",
            },
            ...(Array.isArray(sx) ? sx : [sx])
            ]}
            raised={true}
        >
            {children}
        </Card>
    );
};


CardRaisedRounded.propTypes = {
    children: PropTypes.node,
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};

export default CardRaisedRounded;