import React, { useEffect, useState } from "react";
import { UserProfileApi } from "../../apis/UserProfileApi";
import { useDispatch, useSelector } from "react-redux";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { savePersonSearched } from "../../store/slices/personSearchedSlice";
import { PersonalInformationPublic } from "./PersonalInformationPublic";
import { useParams } from "react-router-dom";
import { APP_ROLES } from "../../constants/appConstants";

export const PersonalInfoTabPublic = () => {
  const [user, setUser] = useState({});
  const [socialMedia, setSocialMedia] = useState({
    linkedin: "",
    facebook: "",
    instagram: "",
    twitter: "",
  });
  const dispatch = useDispatch();
  let { id } = useParams();
  const userRole = useSelector((state) => state.user.userLoggedIn.profileType);

  useEffect(() => {
    dispatch(toggleLoader({ isLoading: true }));

    id &&
      UserProfileApi.getUserById(id, "PersonalInfoPublic")
        .then((response) => {
          if (Object.keys(response?.data).length !== 0) {
            setUser(response.data[0]);
            if (response.data[0]["socialMedia"] && displaySocialMedia()) {
              setSocialMedia(response.data[0].socialMedia);
            }
            dispatch(savePersonSearched(response.data[0]));
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(toggleLoader({ isLoading: false }));
          dispatch(
            showAlert({
              message: "An error ocurred while fetching. Please, try again.",
              severity: "error",
            })
          );
        })
        .finally(() => {
          dispatch(toggleLoader({ isLoading: false }));
        });
  }, [id]);

  const displaySocialMedia = () => {
    return userRole !== APP_ROLES.SUPPORTERS
  }

  return (
    <>
      <PersonalInformationPublic
        user={user}
        socialMedia={socialMedia}
        displaySocialMedia={displaySocialMedia()}
      />
    </>
  );
};
