import { createSlice } from "@reduxjs/toolkit";
import { APP_ROLES } from "../../constants/appConstants";

export const AlumniSlice = createSlice({
    name: "alumni",
    initialState: {
        alumniSelected: {
            userName: null,
            firstName: "",
            lastName: "",
            profileType: [""],
            alumniId: null,
        }
    },
    reducers: {
        alumniSelected: (state, action) => {
            return { 
                ...state, 
                alumniSelected: {
                    ...action.payload,
                    profileType: [APP_ROLES.ALUMNI]
                } 
            };
        }
    },
});

export const { alumniSelected } = AlumniSlice.actions;

export default AlumniSlice.reducer;