import TwoColumnsLayout from "../shared/TwoColumnsLayout";
import SlotUser from "../slotUser/SlotUser";
import ProfileInformationContent from "./ProfileInformationContent";

const ProfileInformation = ({ }) => {

    return (
        <TwoColumnsLayout>
            <SlotUser />
            <ProfileInformationContent />
        </TwoColumnsLayout>
    );
};

export default ProfileInformation;