import { API } from "aws-amplify";

const USER_POOL_API_NAME = "userpool";

export const UserPoolApi = {

  adminCreateUserCognito: async (formData, accountType) => {
    //Basic validations
    if (
      !formData.birthdate ||
      !formData.given_name ||
      !formData.family_name ||
      !formData.email
    )
    { 
      throw new Error("Some required fields are missing");
    } else if (!accountType) {
      throw new Error("The account type is missing");
    }

    if (formData.cohort) {
      delete formData.cohort
    }
    
    const init = {
      headers: {},
      response: true,
      body: {formData, accountType},
    };

    return await API.post(USER_POOL_API_NAME, `/admin-create-user`, init);
  },
  /**
   * Toggle user's Cognito status from `Enabled` to `Disabled` or viceversa. As consequence, user is also globally signend out.
   * @param {string} [userName] - Cognito's User ID or sub.
   */
  toggleCognitoUserStatus: async (userName) => {
    //if (process.env.NODE_ENV === "development") return;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    return API.put(USER_POOL_API_NAME, `/toggle-status/${userName}`, init);
  },
  /**
   * -Provide missed login credentials to end users- Resend the welcome email with a regenerated password
   * @param {string} [userEmail] - Email (username) of the cognito user.
   */
  sendRecoveryEmail: async (userEmail) => {
    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
      body: { userEmail },
    };

    await API.post(USER_POOL_API_NAME, `/send-recovery`, init);
  },
};
