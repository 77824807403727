import { React, useState, useEffect } from "react";
import { Box, Card, CardActions, Divider, IconButton, Typography } from "@mui/material";
import UserProfileAvatar from "../shared/UserProfileAvatar";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfilePicture, showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import ConfirmDialogBox from "../shared/ConfirmDialogBox";
import { AnnouncementApi } from "../../apis/AnnouncementApi";
import { APP_ROLES } from "../../constants/appConstants";
import { convertISOToCustomFormat, saveISO8601Time } from "../../utils/utils";
import { TextFieldFormat } from "./TextFieldFormat";
import TextButton from "../shared/TextButton";

export const AnnouncementCard = ({ announcementData, role, handleDelete }) => {
  const {
    publisherFirstName,
    publisherLastName,
    publishedAt,
    message,
    editedAt,
    announcementId,
    postNumber,
    publisherId,
    image,
    files,
  } = announcementData;
  const [wasEdited, setWasEdited] = useState(Boolean(editedAt));
  const [isEditing, setIsEditing] = useState(false);
  const [editedMessage, setEditedMessage] = useState(JSON.parse(message));
  const [messageDisplay, setMessageDisplay] = useState(JSON.parse(message));
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const dispatch = useDispatch();
  const userLoggedInId = useSelector((state) => state.user.userLoggedIn.userName);
  const [moreOption, setMoreOption] = useState(null);
  const openMoreOptions = Boolean(moreOption);
  const [imageS3Key, setImageS3Key] = useState(image);
  const [fileS3Key, setFileS3Key] = useState(files);
  const profilePictures = useSelector((state) => state.shared.profilePictures.items);

  useEffect(() => {
    const user = {
      id: publisherId,
      firstName: publisherFirstName,
      lastName: publisherLastName,
    };

    const found = profilePictures.find((e) => e.key === user.id);
    if (!found) {
      dispatch(fetchUserProfilePicture(user));
    }
  }, []);

  const handleMoreOptionsClick = (event) => {
    setMoreOption(event.currentTarget);
  };

  const handleMoreOptionsClose = () => {
    setMoreOption(null);
  };

  const handleChangeEdit = (message) => {
    setEditedMessage(message);
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    dispatch(toggleLoader({ isLoading: true }));
    const announcementData = {
      announcementId: announcementId,
      postNumber: postNumber,
      message: JSON.stringify(editedMessage),
      editedAt: saveISO8601Time(),
    };
    if (imageS3Key) {
      announcementData.image = imageS3Key;
    }
    if (fileS3Key) {
      announcementData.files = fileS3Key;
    }
    AnnouncementApi.updateAnnouncement(announcementData)
      .then((response) => {
        setWasEdited(true);
        setIsEditing(false);
        setMessageDisplay(editedMessage);
      })
      .catch((err) => {
        console.error(err);
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
          showAlert({
            message: "Failed to edit the Announcement",
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(toggleLoader({ isLoading: false }));
      });
  };

  const editAnnouncement = () => {
    setIsEditing(true);
  };

  const cancelEditing = () => {
    setEditedMessage(messageDisplay);
    setIsEditing(false);
  };

  const okDelete = () => {
    setOpenConfirmDelete(false);
    dispatch(toggleLoader({ isLoading: true }));
    AnnouncementApi.deleteAnnouncement(announcementId, postNumber)
      .then((response) => {
        handleDelete();
      })
      .catch((err) => {
        console.error(err);
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
          showAlert({
            message: "Failed to delete the Announcement",
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(toggleLoader({ isLoading: false }));
      });
  };

  const cancelDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleOnSuccessUploadImage = ({ key }) => {
    setImageS3Key(key);
    dispatch(
      showAlert({
        message: "Your image was uploaded successfully",
        severity: "success",
      })
    );
  };

  const handleOnSuccessUploadFile = ({ key }) => {
    fileS3Key ? setFileS3Key([...fileS3Key, key]) : setFileS3Key([key]);
    dispatch(
      showAlert({
        message: "Your file was uploaded successfully",
        severity: "success",
      })
    );
  };

  const copyLinkToClipboard = () => {
    const link = `${window.location.origin}${window.location.pathname}?postNumber=${postNumber}`;
    window.navigator.clipboard
      .writeText(link)
      .then(() => {
        dispatch(
          showAlert({
            message: "Link copied to clipboard!",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          showAlert({
            message: "Error copying the link to clipboard!",
            severity: "error",
          })
        );
      });
  };

  return (
    <Card
      sx={{
        marginY: 3,
        border: "1px solid #cccccc85",
        borderRadius: 2,
        boxShadow: 1,
        p: 0.5,
      }}
    >
      <Box sx={{ padding: 1.5 }}>
        {/* HEADER */}
        <Box sx={{ maxwidth: "100%", display: "flex" }}>
          <UserProfileAvatar
            source={profilePictures.find((e) => e.key === publisherId)?.value.profilePictureKey}
            userProfile={{ firstName: publisherFirstName, lastName: publisherLastName }}
          />
          <Box>
            <Typography sx={{ paddingY: 0.5, paddingX: 2, color: "grey" }}>
              {publisherFirstName} {publisherLastName}
            </Typography>
            <Box sx={{ display: "flex", paddingX: 2 }}>
              <Typography sx={{ marginRight: 0.5, fontSize: 12, color: "grey" }}>{convertISOToCustomFormat(publishedAt)}</Typography>
              <Typography sx={{ fontSize: 12, color: "grey" }}>{wasEdited ? "- Edited" : null}</Typography>
            </Box>
          </Box>
          {role === APP_ROLES.SCHOOL_ADMIN && (
            <Box sx={{ marginLeft: "auto" }}>
              {userLoggedInId === publisherId && (
                <IconButton onClick={handleMoreOptionsClick} sx={{ padding: 0.5 }}>
                  <MoreHorizIcon />
                </IconButton>
              )}
              <Menu
                anchorEl={moreOption}
                open={openMoreOptions}
                onClose={handleMoreOptionsClose}
                onClick={handleMoreOptionsClose}
              >
                <MenuItem id="edit" onClick={editAnnouncement}>
                  Edit
                </MenuItem>
                <MenuItem id="delete" onClick={() => setOpenConfirmDelete(true)}>
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>
        {/* BODY */}
        {isEditing ? (
          <>
            <TextFieldFormat
              key={isEditing ? "edit-mode" : "read-mode"}
              message={editedMessage}
              setMessage={(message) => handleChangeEdit(message)}
              isEditing={isEditing}
              cancelEditing={cancelEditing}
              handleSubmitEdit={handleSubmitEdit}
              readOnly={false}
              handleOnSuccessUploadImage={handleOnSuccessUploadImage}
              handleOnSuccessUploadFile={handleOnSuccessUploadFile}
            />
          </>
        ) : (
          <>
            <Box sx={{ paddingX: 2 }}>
              <TextFieldFormat
                image={imageS3Key}
                files={fileS3Key}
                message={messageDisplay}
                readOnly={true}
                key={isEditing ? "edit-mode" : "read-mode"}
              />
            </Box>
            <Divider />
            <CardActions sx={{ padding: 0, marginTop: 0.5 }}>
              <TextButton onClick={() => copyLinkToClipboard()} label={"Share"} />
            </CardActions>
          </>
        )}
      </Box>
      <ConfirmDialogBox
        open={openConfirmDelete}
        title={"Confirm Deletion"}
        message={"Click OK to delete the announcement"}
        okText={"Ok"}
        cancelText={"Cancel"}
        handleOk={okDelete}
        handleClose={cancelDelete}
      />
    </Card>
  );
};
