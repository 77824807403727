import { Chip, Container, Divider, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import TextFieldIcon from '../../shared/TextFieldIcon';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { SUBCATEGORIES } from './MoreFiltersDialog';
import { PIVOT_SPECIAL_CHAR } from '../../../constants/appConstants';

function CategoryName({ initialFirstName, initialLastName, updateFiltersApplied }) {
    const [searchInputFirstName, setSearchInputFirstName] = useState("");
    const [searchInputLastName, setSearchInputLastName] = useState("");
    const [firstNames, setFirstNames] = useState(initialFirstName);
    const [lastNames, setLastNames] = useState(initialLastName);

    const handleOnKeyDownFirstName = (event,) => {
        ///prevent adding empty spaces
        if (event.key === "Enter" && searchInputFirstName.trim() !== "") {
            ///prevent adding the same string but with different capitalization (camelCase, PascalCase, etc)
            const optionCapitalized = searchInputFirstName.trim().toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            ///add searchInput to names
            const nameAdded = new Set([...firstNames, optionCapitalized]);
            setFirstNames(nameAdded);

            ///clear searchInput
            setSearchInputFirstName("");
        }
    };

    const handleOnChangeSearchInputFirstName = (event) => {
        setSearchInputFirstName(event.target.value);
    };

    const handleOnDeleteFirstName = (chipToDelete) => {
        const newSet = new Set(firstNames.values());
        const deleted = newSet.delete(chipToDelete);
        if (deleted) setFirstNames(newSet);
    };

    const handleOnClickClearSelectionFirstName = () => {
        const emptySet = new Set();
        setFirstNames(emptySet);
    };

    const chipsFirstNames = useMemo(() => {
        let components = [];
        firstNames.forEach((name) => components.push(<Chip sx={{ margin: 0.5 }} key={name} label={name} onDelete={() => handleOnDeleteFirstName(name)} />))

        return components;
    }, [firstNames]);

    const handleOnKeyDownLastName = (event) => {
        ///prevent adding empty spaces
        if (event.key === "Enter" && searchInputLastName.trim() !== "") {
            ///prevent adding the same string but with different capitalization (camelCase, PascalCase, etc)
            const optionCapitalized = searchInputLastName.trim().toLocaleLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            ///add searchInput to names
            const nameAdded = new Set([...lastNames, optionCapitalized]);
            setLastNames(nameAdded);

            ///clear searchInput
            setSearchInputLastName("");
        }
    };

    const handleOnChangeSearchInputLastName = (event) => {
        setSearchInputLastName(event.target.value);
    };

    const handleOnDeleteLastName = (chipToDelete) => {
        const newSet = new Set(lastNames.values());
        const deleted = newSet.delete(chipToDelete);
        if (deleted) setLastNames(newSet);
    };

    const handleOnClickClearSelectionLastName = () => {
        const emptySet = new Set();
        setLastNames(emptySet);
    };

    useEffect(() => {
        ///update parent's filters
        updateFiltersApplied(SUBCATEGORIES.LAST_NAME, lastNames);
    }, [lastNames]);

    useEffect(() => {
        ///update parent's filters
        updateFiltersApplied(SUBCATEGORIES.FIRST_NAME, firstNames);
    }, [firstNames]);

    const chipsLastName = useMemo(() => {
        let components = [];
        lastNames.forEach((name) => components.push(<Chip sx={{ margin: 0.5 }} key={name} label={name} onDelete={() => handleOnDeleteLastName(name)} />))

        return components;
    }, [lastNames]);

    return (
        <Container maxWidth="md">
            <Stack marginTop={2} direction={"row"} spacing={1} justifyContent="space-between" alignItems="baseline" divider={<Divider orientation="vertical" flexItem>AND</Divider>}>
                <Container fixed className='column-first-name'>
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="baseline">
                        <TextFieldIcon
                            variant="filled"
                            label="Search by first name"
                            icon={<SearchIcon />}
                            helperText="Hitting enter will add the name to the list below"
                            value={searchInputFirstName}
                            onChange={handleOnChangeSearchInputFirstName}
                            onKeyDown={handleOnKeyDownFirstName}
                        />

                        <Tooltip title="Clear selection" placement='top'>
                            <IconButton onClick={handleOnClickClearSelectionFirstName}>
                                <FilterAltOffIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                    <Divider
                        sx={{
                            marginY: (theme) => theme.spacing(2),
                        }}
                    />

                    <Typography variant='overline'>
                        Selected first names
                    </Typography>

                    <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', listStyle: 'none', p: 0.5, m: 0, }} >
                        {chipsFirstNames}
                    </Paper>
                </Container>

                <Container fixed className="column-last-name">
                    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="baseline">
                        <TextFieldIcon
                            variant="filled"
                            label="Search by last name"
                            icon={<SearchIcon />}
                            helperText="Hitting enter will add the name to the list below"
                            value={searchInputLastName}
                            onChange={handleOnChangeSearchInputLastName}
                            onKeyDown={handleOnKeyDownLastName}
                        />

                        <Tooltip title="Clear selection" placement='top'>
                            <IconButton onClick={handleOnClickClearSelectionLastName}>
                                <FilterAltOffIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                    <Divider
                        sx={{
                            marginY: (theme) => theme.spacing(2),
                        }}
                    />

                    <Typography variant='overline'>
                        Selected last names
                    </Typography>

                    <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', listStyle: 'none', p: 0.5, m: 0, }} >
                        {chipsLastName}
                    </Paper>
                </Container>
            </Stack>
        </Container>
    )
}

export default CategoryName;