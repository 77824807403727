import { React, useState } from "react";
import styled from "styled-components";
import { Divider, Grid } from "@mui/material";
import ContainedButton from "../shared/ContainedButton";
import OutlinedButton from "../shared/OutlinedButton";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import GridItemOneColumnProfileInfo from "../shared/GridItemOneColumnProfileInfo";
import TypographyPersonalInfoGroupHeading from "../shared/TypographyPersonalInfoGroupHeading";
import FieldText from "../shared/FieldText";
import { GENDER, YEARS_AT_BWS } from "../../constants/appConstants";
import SelectControl from "../shared/Select";
import ConfirmDialogBox from "../shared/ConfirmDialogBox";
import { validatePhoneNumber, validateZipCode } from "../../utils/utils";

const Container = styled.form`
  width: 100%;
  height: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding-right: 0.2rem;
  margin-top: 0.2rem;
`;

function EditPersonalInformation({
  user,
  socialMedia,
  onChange,
  onSubmit,
  socialMediaOnChange,
  handleCancelButton,
  errors,
  socialErrors,
}) {
  const currentDate = new Date().toISOString().slice(0, 10);
  const [openConfirmBack, setOpenConfirmBack] = useState(false);

  const okBack = () => {
    setOpenConfirmBack(false);
    handleCancelButton();
  };

  const cancelBack = () => {
    setOpenConfirmBack(false);
  };

  const hasErrors = () => {
    const hasValidValues = Object.keys(errors).length > 0;
    const hasSocialValidValues = Object.keys(socialErrors).length > 0;
    return !hasValidValues && !hasSocialValidValues;
  };

  return (
    <Container onSubmit={onSubmit}>
      <Grid container spacing={2} marginLeft={2} paddingTop={2}>
        <GridItemProfileInfo>
          <FieldText
            name="firstName"
            label="First Name"
            type="text"
            error={!!errors.firstName}
            helperText={errors.firstName}
            defaultValue={user.firstName}
            onChange={onChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="lastName"
            label="Last Name"
            type="text"
            error={!!errors.lastName}
            helperText={errors.lastName}
            defaultValue={user.lastName}
            onChange={onChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="parentEmail"
            label="Parent/Guardian Email"
            type="text"
            error={!!errors.parentEmail}
            helperText={errors.parentEmail}
            defaultValue={user.parentEmail}
            onChange={onChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="birthdate"
            label="Date of birth"
            type="date"
            error={!!errors.birthdate}
            helperText={errors.birthdate}
            value={user.birthdate}
            onChange={onChange}
            inputProps={{ max: currentDate }}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="phone"
            label="Phone No."
            type="text"
            error={!validatePhoneNumber(user.phone)}
            helperText={
              !validatePhoneNumber(user.phone)
                ? "Please use phone# in (***)***-**** format."
                : ""
            }
            defaultValue={user.phone}
            onChange={onChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="parentNames"
            label="Parent/Guardian INFO(Names)"
            type="text"
            error={!!errors.parentNames}
            helperText={errors.parentNames}
            defaultValue={user.parentNames}
            onChange={onChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <SelectControl
            menuItems={GENDER}
            name="gender"
            onChange={(e) => onChange(e)}
            value={user.gender || "N/A"}
            label="GENDER"
          ></SelectControl>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="race"
            label="Race"
            type="text"
            error={!!errors.race}
            helperText={errors.race}
            defaultValue={user.race}
            onChange={onChange}
          />
        </GridItemProfileInfo>
      </Grid>
      <Divider
        sx={{
          paddingTop: 2,
          borderStyle: "dashed",
          width: "95%",
          margin: "auto",
        }}
      />
      <Grid container spacing={2} marginLeft={2} paddingTop={2}>
        <GridItemProfileInfo>
          <FieldText
            name="yearsAttendedBWS"
            label="Years attended at BWS"
            type="text"
            error={!!errors.yearsAttendedBWS}
            helperText={errors.yearsAttendedBWS}
            defaultValue={user.attendedYears}
            onChange={onChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <SelectControl
            menuItems={YEARS_AT_BWS}
            name="cohort"
            onChange={(e) => onChange(e)}
            value={user.cohort || "N/A"}
            label="BWS Graduation Year"
          ></SelectControl>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="address"
            label="Address"
            type="text"
            error={!!errors.address}
            helperText={errors.address}
            defaultValue={user.address}
            onChange={onChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="city"
            label="City"
            type="text"
            error={!!errors.city}
            helperText={errors.city}
            defaultValue={user.city}
            onChange={onChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="userState"
            label="State"
            type="text"
            error={!!errors.userState}
            helperText={errors.userState}
            defaultValue={user.userState}
            onChange={onChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="zipCode"
            label="Zip Code"
            type="text"
            error={!validateZipCode(user.zipCode)}
            helperText={
              !validateZipCode(user.zipCode) ? "Incorrect zipcode." : ""
            }
            defaultValue={user.zipCode}
            onChange={onChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="annualIncome"
            label="Annual Income(Range)"
            type="text"
            error={!!errors.annualIncome}
            helperText={errors.annualIncome}
            defaultValue={user.annualIncome}
            onChange={onChange}
          />
        </GridItemProfileInfo>
      </Grid>
      <Divider
        sx={{
          paddingTop: 2,
          borderStyle: "dashed",
          width: "95%",
          margin: "auto",
        }}
      />
      <Grid container marginLeft={3} spacing={2} paddingTop={2}>
        <GridItemOneColumnProfileInfo>
          <TypographyPersonalInfoGroupHeading>
            SOCIAL NETWORK HANDLES
          </TypographyPersonalInfoGroupHeading>
        </GridItemOneColumnProfileInfo>
      </Grid>
      <Grid container spacing={2} marginLeft={2} paddingTop={1}>
        <GridItemProfileInfo>
          <FieldText
            name="linkedin"
            label="Linkedin link"
            type="text"
            error={!!socialErrors.linkedin}
            helperText={socialErrors.linkedin}
            defaultValue={socialMedia.linkedin}
            onChange={socialMediaOnChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="facebook"
            label="Facebook Link"
            type="text"
            error={!!socialErrors.facebook}
            helperText={socialErrors.facebook}
            defaultValue={socialMedia.facebook}
            onChange={socialMediaOnChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="instagram"
            label="Instagram Link"
            type="text"
            error={!!socialErrors.instagram}
            helperText={socialErrors.instagram}
            defaultValue={socialMedia.instagram}
            onChange={socialMediaOnChange}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="twitter"
            label="Twitter Link"
            type="text"
            error={!!socialErrors.twitter}
            helperText={socialErrors.twitter}
            defaultValue={socialMedia.twitter}
            onChange={socialMediaOnChange}
          />
        </GridItemProfileInfo>
      </Grid>

      <ConfirmDialogBox
        open={openConfirmBack}
        title={"Confirm Cancelation"}
        message={
          "You will lose any unsaved data. Click on cancel and save your changes. "
        }
        okText={"Ok"}
        cancelText={"Cancel"}
        handleOk={okBack}
        handleClose={cancelBack}
      />

      <ButtonsContainer>
        <OutlinedButton label="Back" onClick={() => setOpenConfirmBack(true)} />
        <ContainedButton
          type="submit"
          label="Done"
          disabled={
            !user["firstName"] ||
            !validatePhoneNumber(user["phone"]) ||
            !validateZipCode(user["zipCode"]) ||
            !hasErrors()
          }
        />
      </ButtonsContainer>
    </Container>
  );
}

export default EditPersonalInformation;
