import Button from "@mui/material/Button";

const TextButtonIcon = ({ label, icon, ...props }) => {

    return (
        <Button
            sx={{
                height: "40px",
                textTransform: "none"
            }}
            variant="text"
            startIcon={icon}
            {...props}
        >
            {label}
        </Button>
    );
};

export default TextButtonIcon;