import { API } from "aws-amplify";

const USER_PROFILE_API_NAME = "userprofile";

export const UserProfileApi = {
  postUsers: async (formData, userProfileId) => {
    const user = {
      userProfileId: userProfileId,
      firstName: formData.given_name,
      lastName: formData.family_name,
      email: formData.email,
      birthdate: formData.birthdate,
      isActive: true,
      accountType: formData.accountType,
    };
    if(formData.cohort) user.cohort = formData.cohort;

    const init = {
      headers: {},
      response: true,
      body: user,
    };

    return API.post(USER_PROFILE_API_NAME, "/user-profile", init);
  },

  postUserAlumni: async (children, parentData) => {
    const child = children.map((child) => {
      return {
        userProfileId: child.alumniId,
        firstName: child.given_name,
        lastName: child.family_name,
        email: child.email,
        birthdate: child.birthdate,
        cohort: child.cohort,
        isActive: true,
        parentEmail: parentData.email,
        accountType: child.accountType,
      };
    });

    child.forEach((c) => {
      const init = {
        headers: {},
        response: true,
        body: c,
      };

      return API.post(USER_PROFILE_API_NAME, "/user-profile", init);
    });
  },

  getUserById: (userId, typeOfData) => {
    if (process.env.NODE_ENV === "development")
      return new Promise((resolve, reject) => {
        resolve();
      });

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    return API.get(
      USER_PROFILE_API_NAME,
      `/user-profile/${userId}?ReqFor=${typeOfData}`,
      init
    );
  },

  patchUserProfile: (userData) => {
    if (process.env.NODE_ENV === "development") return;

    const init = {
      headers: {},
      response: true,
      body: userData,
    };

    return API.patch(USER_PROFILE_API_NAME, `/user-profile`, init);
  },

  /**
   * Toggle current value of `isActive` attribute in `UserProfile` table for a given person. `SchoolAdmin` is asked for a reason of performing such action
   * @param {string} userProfileId ID of the User profile to update `isActive` attribute.
   * @param {boolean} isActive Value of `isActive` attribute to be updated with.
   * @param {string} reason Reason provided by end user.
   * @returns Promise with the response from the API endpoint.
   */
  toggleIsActive: async (userProfileId, isActive, reason) => {
    //if (process.env.NODE_ENV === "development") return;

    const bodyRequest = {
      userProfileId: userProfileId,
      isActive: isActive,
      reason: `Action: ${
        isActive ? "Reactivate" : "Deactivate"
      } | Because: ${reason}`,
    };

    const init = {
      headers: {},
      resposne: true,
      body: bodyRequest,
    };

    return API.patch(USER_PROFILE_API_NAME, "/user-profile", init);
  },
};
