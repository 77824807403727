import { Box, Typography, IconButton } from "@mui/material";
import { useEffect } from "react";
import { React, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { EducationInformationData } from "../../apis/EducationInformationApi";
import { AddSchoolForm } from "./AddSchoolForm";
import { AddEducationInfoPopup } from "./AddEducationInfoPopup";
import { SchoolDetails } from "./SchoolDetails";
import {
  TypographyStyled,
  DividerLine,
} from "../../utils/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { APP_ROLES, ProfilePopupNumber } from "../../constants/appConstants";
import { CollegeDetails } from "./CollegeDetails";
import { CertificationDetails } from "./CertificationDetails";
import { AddCollegeForm } from "./AddCollegeForm";
import { AddCertificationForm } from "./AddCertificationForm";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import ConfirmDialogBox from "../shared/ConfirmDialogBox";
import styled from "styled-components";
import { Divider, Grid } from "@mui/material";
import TypographyPersonalInfoGroupHeading from "../shared/TypographyPersonalInfoGroupHeading";
import GridItemOneColumnProfileInfo from "../shared/GridItemOneColumnProfileInfo";
import ContainedButton from "../shared/ContainedButton";
import OutlinedButton from "../shared/OutlinedButton";
import { useParams } from "react-router-dom";

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding-right: 0.2rem;
  margin-top: 0.2rem;
`;

export const EducationInformationTab = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmBack, setOpenConfirmBack] = useState(false);
  const [arrayToDelete, setArrayToDelete] = useState("");
  const [indexToDelete, setIndexToDelete] = useState();
  const [educationInformation, setEducationInformation] = useState({});
  const [editingInfo, setEditingInfo] = useState([]);
  const [renderPopup, setRenderPopup] = useState(0);
  const dispatch = useDispatch();
  const loggedInUserInfo = useSelector((state) => state.user);
  const userLoggedIn = loggedInUserInfo.userLoggedIn;
  const alumniSelected = useSelector((state) => state.alumni.alumniSelected);
  let { id } = useParams();

  useEffect(() => {
    const userId = id ? id : getUserProfileId();

    dispatch(toggleLoader({ isLoading: true }));
    userId &&
      EducationInformationData.getEducationInformation(userId)
        .then((response) => {
          //If user don't have data it will start the state with mocked data
          if (Object.keys(response.data[0]).length !== 0) {
            setEducationInformation(response.data[0]);
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(toggleLoader({ isLoading: false }));
          dispatch(
            showAlert({
              message: "An error ocurred while fetching. Please, try again.",
              severity: "error",
            })
          );
        })
        .finally(() => {
          dispatch(toggleLoader({ isLoading: false }));
        });
  }, [userLoggedIn]);

  // It deep clone the info that retrieves from the DB because the user will need to edit a cloned array
  useEffect(() => {
    setEditingInfo(JSON.parse(JSON.stringify(educationInformation)));
  }, [educationInformation]);

  const getUserProfileId = () => {
    //if user logged in is type of Parent, then we need to fetch Alumni's profile picture. otherwise, user's logged in profile picture.
    return (userLoggedIn.profileType === APP_ROLES.PARENTS) ? alumniSelected.alumniId : userLoggedIn.userName;
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSubmit = () => {
    setEducationInformation(editingInfo); // Once the user hit the "Done" button it replace the original Array with the edited one
    const userId = id ? id : getUserProfileId();
    EducationInformationData.postEducationInformation(editingInfo, userId);
    setIsEditing(false);
  };

  const handleChange = (e, index, formToEdit) => {
    const updatedData = { ...editingInfo };
    updatedData[formToEdit][index][e.target.name] = e.target.value;
    setEditingInfo(updatedData);
  };

  //Cheking if you have to insert one array or create the atributte
  const addFormData = (formData, arrayName) => {
    let updatedData = { ...editingInfo };
    updatedData[arrayName]
      ? updatedData[arrayName].push(formData)
      : (updatedData = { ...editingInfo, [arrayName]: [formData] });
    setEditingInfo(updatedData);
  };

  const handleDelete = (ArrayNameToDelete, index) => {
    setOpenConfirmDelete(true);
    setArrayToDelete(ArrayNameToDelete);
    setIndexToDelete(index);
  };

  const okDelete = () => {
    const clonedArray = { ...editingInfo };
    clonedArray[arrayToDelete].splice(indexToDelete, 1);
    setEditingInfo(clonedArray);
    setOpenConfirmDelete(false);
  };

  const cancelDelete = () => {
    setOpenConfirmDelete(false);
  };

  const okBack = () => {
    setEditingInfo(JSON.parse(JSON.stringify(educationInformation)));
    setIsEditing(false);
    setOpenConfirmBack(false);
  };

  const cancelBack = () => {
    setOpenConfirmBack(false);
  };

  function renderConditional() {
    if (isEditing === false) {
      return (
        //Here it displays the data from the "original" array
        <>
          <Grid container marginLeft={3} spacing={2} paddingTop={3}>
            <GridItemOneColumnProfileInfo>
              <TypographyPersonalInfoGroupHeading>
                SCHOOL DETAILS
              </TypographyPersonalInfoGroupHeading>
            </GridItemOneColumnProfileInfo>
          </Grid>
          {educationInformation.schoolDetails?.map(
            (arrayData, index, array) => (
              <Fragment key={index}>
                <SchoolDetails arrayData={arrayData} />
                {index < array.length - 1 && (
                  <DividerLine className="second-divider" />
                )}
              </Fragment>
            )
          )}
          <Divider
            sx={{
              color: "black",
              paddingTop: 2,
              borderStyle: "dashed",
              width: "95%",
              margin: "auto",
            }}
          />
          <Grid container marginLeft={3} spacing={2} paddingTop={3}>
            <GridItemOneColumnProfileInfo>
              <TypographyPersonalInfoGroupHeading>
                COLLEGE/UNIVERSITY DETAILS
              </TypographyPersonalInfoGroupHeading>
            </GridItemOneColumnProfileInfo>
          </Grid>

          {educationInformation.collegeDetails &&
            educationInformation.collegeDetails.map(
              (arrayData, index, array) => (
                <Fragment key={index}>
                  <CollegeDetails arrayData={arrayData} />
                  {index < array.length - 1 && (
                    <DividerLine className="second-divider" />
                  )}
                </Fragment>
              )
            )}
          <Divider
            sx={{
              color: "black",
              paddingTop: 2,
              borderStyle: "dashed",
              width: "95%",
              margin: "auto",
            }}
          />

          <Grid container marginLeft={3} spacing={2} paddingTop={3}>
            <GridItemOneColumnProfileInfo>
              <TypographyPersonalInfoGroupHeading>
                CERTIFICATIONS/TRAININGS
              </TypographyPersonalInfoGroupHeading>
            </GridItemOneColumnProfileInfo>
          </Grid>

          {educationInformation.certificationDetails &&
            educationInformation.certificationDetails.map(
              (arrayData, index, array) => (
                <Fragment key={index}>
                  <CertificationDetails arrayData={arrayData} />
                  {index < array.length - 1 && (
                    <DividerLine className="second-divider" />
                  )}
                </Fragment>
              )
            )}
          <Divider
            sx={{
              color: "black",
              paddingTop: 2,
              borderStyle: "dashed",
              width: "95%",
              margin: "auto",
            }}
          />
        </>
      );
    } else {
      return (
        //Here displays the data from the "duplicate" and editable array
        <>
          <ConfirmDialogBox //confirmDialogBox to delete sections of information (when presing trash icon)
            open={openConfirmDelete}
            title={"Confirm Deletion"}
            message={"Click OK to delete the information"}
            okText={"Ok"}
            cancelText={"Cancel"}
            handleOk={okDelete}
            handleClose={cancelDelete}
          />
          <Grid container marginLeft={3} spacing={2} paddingTop={3}>
            <GridItemOneColumnProfileInfo>
              <TypographyPersonalInfoGroupHeading>
                SCHOOL DETAILS
              </TypographyPersonalInfoGroupHeading>
            </GridItemOneColumnProfileInfo>
          </Grid>
          {editingInfo.schoolDetails &&
            editingInfo.schoolDetails.map((arrayData, index, array) => {
              const uniqueKey = `${array.length}-${index}`;
              return (
                <Fragment key={uniqueKey}>
                  <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                    <IconButton
                      onClick={() => handleDelete("schoolDetails", index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>

                  <Grid container marginLeft={1}>
                    <GridItemOneColumnProfileInfo>
                      <AddSchoolForm
                        arrayData={arrayData}
                        handleChange={(e) =>
                          handleChange(e, index, "schoolDetails")
                        }
                      />
                    </GridItemOneColumnProfileInfo>
                  </Grid>

                  {index < array.length - 1 && (
                    <Divider
                      sx={{
                        color: "black",
                        paddingTop: 2,
                        borderStyle: "dashed",
                        width: "95%",
                        margin: "auto",
                      }}
                    />
                  )}
                </Fragment>
              );
            })}
          <Grid container marginLeft={3} spacing={2} paddingTop={2}>
            <GridItemOneColumnProfileInfo>
              <TypographyStyled
                onClick={() => setRenderPopup(ProfilePopupNumber.addSchool)}
              >
                + Add school
              </TypographyStyled>
            </GridItemOneColumnProfileInfo>
          </Grid>

          {renderPopup === ProfilePopupNumber.addSchool && (
            <AddEducationInfoPopup
              open={true}
              onClose={() => setRenderPopup(0)}
              arrayData={{}}
              addFormData={addFormData}
              arrayName={"schoolDetails"}
              popupName={"Add school"}
              popupNumber={ProfilePopupNumber.addSchool}
            />
          )}

          <Divider
            sx={{
              color: "black",
              paddingTop: 2,
              borderStyle: "dashed",
              width: "95%",
              margin: "auto",
            }}
          />

          <Grid container marginLeft={3} spacing={2} paddingTop={3}>
            <GridItemOneColumnProfileInfo>
              <TypographyPersonalInfoGroupHeading>
                COLLEGE/UNIVERSITY DETAILS
              </TypographyPersonalInfoGroupHeading>
            </GridItemOneColumnProfileInfo>
          </Grid>

          {editingInfo.collegeDetails &&
            editingInfo.collegeDetails.map((arrayData, index, array) => {
              const uniqueKey = `${array.length}-${index}`;
              return (
                <Fragment key={uniqueKey}>
                  <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                    <IconButton
                      onClick={() => handleDelete("collegeDetails", index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <AddCollegeForm
                    arrayData={arrayData}
                    handleChange={(e) =>
                      handleChange(e, index, "collegeDetails")
                    }
                  />
                  {index < array.length - 1 && (
                    <DividerLine className="second-divider" />
                  )}
                </Fragment>
              );
            })}

          <Grid container marginLeft={3} spacing={2} paddingTop={2}>
            <GridItemOneColumnProfileInfo>
              <TypographyStyled
                onClick={() => setRenderPopup(ProfilePopupNumber.addCollege)}
              >
                + Add College/University Details
              </TypographyStyled>
            </GridItemOneColumnProfileInfo>
          </Grid>

          {renderPopup === ProfilePopupNumber.addCollege && (
            <AddEducationInfoPopup
              open={true}
              onClose={() => setRenderPopup(0)}
              arrayData={{}}
              addFormData={addFormData}
              arrayName={"collegeDetails"}
              popupName={"Add College/University"}
              popupNumber={ProfilePopupNumber.addCollege}
            />
          )}

          <Divider
            sx={{
              color: "black",
              paddingTop: 2,
              borderStyle: "dashed",
              width: "95%",
              margin: "auto",
            }}
          />

          <Grid container marginLeft={3} spacing={2} paddingTop={3}>
            <GridItemOneColumnProfileInfo>
              <TypographyPersonalInfoGroupHeading>
                CERTIFICATIONS/TRAININGS
              </TypographyPersonalInfoGroupHeading>
            </GridItemOneColumnProfileInfo>
          </Grid>

          {editingInfo.certificationDetails &&
            editingInfo.certificationDetails.map((arrayData, index, array) => {
              const uniqueKey = `${array.length}-${index}`;
              return (
                <Fragment key={uniqueKey}>
                  <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                    <IconButton
                      onClick={() =>
                        handleDelete("certificationDetails", index)
                      }
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <AddCertificationForm
                    arrayData={arrayData}
                    handleChange={(e) =>
                      handleChange(e, index, "certificationDetails")
                    }
                  />
                  {index < array.length - 1 && (
                    <Divider
                      sx={{
                        color: "black",
                        paddingTop: 2,
                        borderStyle: "dashed",
                        width: "95%",
                        margin: "auto",
                      }}
                    />
                  )}
                </Fragment>
              );
            })}

          <Grid container marginLeft={3} spacing={2} paddingTop={2}>
            <GridItemOneColumnProfileInfo>
              <TypographyStyled
                onClick={() =>
                  setRenderPopup(ProfilePopupNumber.AddCertification)
                }
              >
                + ADD CERTIFICATIONS/TRAININGS
              </TypographyStyled>
            </GridItemOneColumnProfileInfo>
          </Grid>

          {renderPopup === ProfilePopupNumber.AddCertification && (
            <AddEducationInfoPopup
              open={true}
              onClose={() => setRenderPopup(0)}
              arrayData={{}}
              addFormData={addFormData}
              arrayName={"certificationDetails"}
              popupName={"Add Certification/Trainings"}
              popupNumber={ProfilePopupNumber.AddCertification}
            />
          )}

          <ButtonsContainer>
            <OutlinedButton
              label="Back"
              onClick={() => setOpenConfirmBack(true)}
            />
            <ContainedButton
              type="submit"
              label="Done"
              onClick={handleSubmit}
            />
          </ButtonsContainer>

          <ConfirmDialogBox
            open={openConfirmBack}
            title={"Confirm Cancelation"}
            message={
              "You will lose any unsaved data. Click on cancel and save your changes. "
            }
            okText={"Ok"}
            cancelText={"Cancel"}
            handleOk={okBack}
            handleClose={cancelBack}
          />
        </>
      );
    }
  }

  return (
    <>
      <Title>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.125rem",
            paddingTop: 2,
            marginLeft: 5,
            width: "85%",
          }}
          variant="h6"
        >
          Education Information
        </Typography>
        {(loggedInUserInfo.hasAdminAccess || !id) && !isEditing && (
          <EditIcon
            sx={{
              flex: "none",
              paddingBottom: 0.5,
              height: 18,
              cursor: "pointer",
            }}
            onClick={handleEditClick}
          />
        )}
      </Title>
      <DividerLine sx={{ width: "95%", margin: "auto" }} />
      <Box>{renderConditional()}</Box>
    </>
  );
};
