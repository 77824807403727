/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      messages {
        items {
          chatId
          content {
            text
          }
          sender {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          sentAt
          updatedAt
          messageSenderId
        }
        nextToken
      }
      participants {
        items {
          id
          chatId
          participantId
          chat {
            id
            createdAt
            updatedAt
          }
          participant {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messages {
          items {
            chatId
            sentAt
            updatedAt
            messageSenderId
          }
          nextToken
        }
        participants {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($chatId: ID!, $sentAt: AWSDateTime!) {
    getMessage(chatId: $chatId, sentAt: $sentAt) {
      chatId
      content {
        text
        image {
          key
        }
        attachments {
          key
        }
      }
      sender {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status {
        readBy {
          participantId
          at
        }
      }
      sentAt
      updatedAt
      messageSenderId
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $chatId: ID
    $sentAt: ModelStringKeyConditionInput
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMessages(
      chatId: $chatId
      sentAt: $sentAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        chatId
        content {
          text
          image {
            key
          }
          attachments {
            key
          }
        }
        sender {
          id
          firstName
          lastName
          profilePictureKey
          isSchoolAdmin
          isActive
          chats {
            nextToken
          }
          createdAt
          updatedAt
        }
        status {
          readBy {
            participantId
            at
          }
        }
        sentAt
        updatedAt
        messageSenderId
      }
      nextToken
    }
  }
`;
export const getParticipant = /* GraphQL */ `
  query GetParticipant($id: ID!) {
    getParticipant(id: $id) {
      id
      firstName
      lastName
      profilePictureKey
      isSchoolAdmin
      isActive
      chats {
        items {
          id
          chatId
          participantId
          chat {
            id
            createdAt
            updatedAt
          }
          participant {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listParticipants = /* GraphQL */ `
  query ListParticipants(
    $filter: ModelParticipantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listParticipants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      title
      description
      navLink
      type
      createdAt
      read
      seen
      topic
      notificationForUserId
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        navLink
        type
        createdAt
        read
        seen
        topic
        notificationForUserId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatParticipants = /* GraphQL */ `
  query GetChatParticipants($id: ID!) {
    getChatParticipants(id: $id) {
      id
      chatId
      participantId
      chat {
        id
        messages {
          items {
            chatId
            sentAt
            updatedAt
            messageSenderId
          }
          nextToken
        }
        participants {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      participant {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChatParticipants = /* GraphQL */ `
  query ListChatParticipants(
    $filter: ModelChatParticipantsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatParticipants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatId
        participantId
        chat {
          id
          messages {
            nextToken
          }
          participants {
            nextToken
          }
          createdAt
          updatedAt
        }
        participant {
          id
          firstName
          lastName
          profilePictureKey
          isSchoolAdmin
          isActive
          chats {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByChatIdAndSentAt = /* GraphQL */ `
  query MessagesByChatIdAndSentAt(
    $chatId: ID!
    $sentAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChatIdAndSentAt(
      chatId: $chatId
      sentAt: $sentAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        chatId
        content {
          text
          image {
            key
          }
          attachments {
            key
          }
        }
        sender {
          id
          firstName
          lastName
          profilePictureKey
          isSchoolAdmin
          isActive
          chats {
            nextToken
          }
          createdAt
          updatedAt
        }
        status {
          readBy {
            participantId
            at
          }
        }
        sentAt
        updatedAt
        messageSenderId
      }
      nextToken
    }
  }
`;
export const notificationsByDate = /* GraphQL */ `
  query NotificationsByDate(
    $type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        navLink
        type
        createdAt
        read
        seen
        topic
        notificationForUserId
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatParticipantsByChatId = /* GraphQL */ `
  query ChatParticipantsByChatId(
    $chatId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatParticipantsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatParticipantsByChatId(
      chatId: $chatId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatId
        participantId
        chat {
          id
          messages {
            nextToken
          }
          participants {
            nextToken
          }
          createdAt
          updatedAt
        }
        participant {
          id
          firstName
          lastName
          profilePictureKey
          isSchoolAdmin
          isActive
          chats {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatParticipantsByParticipantId = /* GraphQL */ `
  query ChatParticipantsByParticipantId(
    $participantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelChatParticipantsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatParticipantsByParticipantId(
      participantId: $participantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        chatId
        participantId
        chat {
          id
          messages {
            nextToken
          }
          participants {
            nextToken
          }
          createdAt
          updatedAt
        }
        participant {
          id
          firstName
          lastName
          profilePictureKey
          isSchoolAdmin
          isActive
          chats {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
