import { useSearchParams } from "react-router-dom";
import TwoColumnsLayout from "../shared/TwoColumnsLayout";
import SlotUser from "../slotUser/SlotUser";
import MyFeedContent from "./MyFeedContent";

const MyFeed = ({ }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let tab = searchParams.get("tab") || "posts"

  if (searchParams.get("eventNumber")) {
    tab = "events"
  }

  return (
    <TwoColumnsLayout>
      <SlotUser />
      <MyFeedContent openAt={tab} />
    </TwoColumnsLayout>
  );
};

export default MyFeed;
