import { Avatar, Box, ListItem, Skeleton } from '@mui/material'
import React from 'react'

function ChatListItemSkeleton() {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
                <Skeleton sx={{ margin: 1 }} variant="circular">
                    <Avatar />
                </Skeleton>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Skeleton width="85%" variant='rounded'>
                    <ListItem />
                </Skeleton>
            </Box>
        </Box>
    )
}

export default ChatListItemSkeleton