import { ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MessagingApi } from "../../apis/MessagingApi";
import {
  storeChat,
  selectChat,
} from "../../store/slices/messagingSlice";
import { attributesToFilterChatsFrom } from "../../utils/utils";
import UserProfileAvatar from "../shared/UserProfileAvatar";
import ChatListItemSkeleton from "./ChatListItemSkeleton";
import { fetchUserProfilePicture } from "../../store/slices/sharedSlice";

const ChatListItem = ({ chatParticipantItem }) => {
  const dispatch = useDispatch();
  const chats = useSelector((state) => state.messaging.chats);
  const activeChatId = useSelector((state) => state.messaging.activeChat.chatId);
  const searchInput = useSelector((state) => state.messaging.searchInputChat);
  const [isLoading, setIsLoading] = useState(false);
  const [chat, setChat] = useState(null);
  const [otherParticipants, setOtherParticipants] = useState([{firstName:null, lastName:null}]);
  const [hideListItem, setHideListItem] = useState(false);
  const [isSearchable, setIsSearchable] = useState(true);
  const participantsProfilePictures = useSelector((state) => state.shared.profilePictures.items);

  useEffect(() => {
    const chatFound = chats.items.find(c => c.id === chatParticipantItem.chatId);

    if (chatFound) {
      setChat(chatFound);
    }
    else {
      setIsLoading(true);

      MessagingApi.getChat(chatParticipantItem.chatId)
        .then((result) => {
          setChat(result.data.getChat);
          dispatch(storeChat(result.data.getChat))
        })
        .catch((err) => { })
        .finally(() => {
          setIsLoading(false);
        }
        );
    };
  }, []);

  useEffect(() => {
    if (chat === null) return;

    const others = chat.participants?.items
      .filter(i => i.participantId !== chatParticipantItem.participantId)
      .map(i => i.participant);
    setOtherParticipants(others);

    /// statusOfAllParticipants can only contain `true` or `false` values. If it only contains false, then it means all participants in this chat
    /// are inactive.
    const statusOfAllParticipants = new Set(others.map(p => p.isActive));
    const allParticipantsAreInactive = (statusOfAllParticipants.size === 1 && statusOfAllParticipants.has(false));

    //hide ListItem if all Participants are inactive
    if (allParticipantsAreInactive) {
      setHideListItem(true)

      //skip searchInput's side effect in this `Chat`, since it should not be visible to end user from previous evaluation.
      setIsSearchable(false);
      return;
    };
  }, [chat]);
    

  useEffect(() => {
    if (Array.of(...otherParticipants).length === 0) return;

    otherParticipants.map((user) => {
      const found = participantsProfilePictures.find(e => e.key === user.id);
      if (!found) {
        dispatch(fetchUserProfilePicture(user));
      }
    });

  }, [otherParticipants]);

  useEffect(() => {
    if (!isSearchable || searchInput === "") return;
    const _searchInput = Array.of(...searchInput.split(" "))
    .join("#")
    .toString()
    .toLocaleUpperCase();
    
    //look up for formatted `_searchInput` in `otherParticipants`
    const result = otherParticipants.filter((op) =>
    attributesToFilterChatsFrom(op).includes(_searchInput)
    );
    const found = result.length === 0 ? false : true;
    
    found ? setHideListItem(false) : setHideListItem(true);
  }, [searchInput]);

  const handleChatSelection = () => {
    dispatch(selectChat({ chatId: chat.id }));
  };

  if (isLoading) return <ChatListItemSkeleton />;

  if (hideListItem) return <></>;

  return (
    <ListItemButton divider={true} selected={chat?.id === activeChatId} onClick={handleChatSelection}>
      <ListItemAvatar>
        <UserProfileAvatar
          source={participantsProfilePictures.find(e => e.key === otherParticipants[0]?.id)?.value.profilePictureKey}
          size={44}
          userProfile={otherParticipants[0]}
        />
      </ListItemAvatar>
      <ListItemText
        primary={`${otherParticipants[0]?.firstName} ${otherParticipants[0]?.lastName}`}
      />
    </ListItemButton>
  );
};

export default ChatListItem;
