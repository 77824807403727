import { Badge, Grid } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { APP_ROLES } from "../../constants/appConstants";
import SelectDropdown from "../shared/SelectDropdown";
import OutlinedButton from "../shared/OutlinedButton";
import ContainedButton from "../shared/ContainedButton";
import TextFieldIcon from "../shared/TextFieldIcon";
import SearchIcon from '@mui/icons-material/Search';
import { withClearOption } from "../../utils/utils";
import MoreFiltersDialog, { initialStateFilters } from "./filters/MoreFiltersDialog";

const _MIN_YEAR = 1999; //to be defined
const _CURRENT_YEAR = new Date(Date.now()).getFullYear();

const FiltersContainer = ({ viewAs, handleSearchOnChange, handleClickApply }) => {
    const [accountType, setAccountType] = useState('');
    const [cohort, setCohort] = useState('');
    const [typeAccountsOptions, setTypeAccountOptions] = useState([]);
    const [graduationYearOptions, setGraduationYearOptions] = useState(
        Array.from({ length: _CURRENT_YEAR - _MIN_YEAR + 1 }, (_, i) => ({
            description: _CURRENT_YEAR - i,
            value: (_CURRENT_YEAR - i).toString()
        })));
    const [graduationIsDisabled, setGraduationIsDisabled] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [moreFiltersIsOpen, setMoreFiltersIsOpen] = useState(false);
    const [moreFilters, setMoreFilters] = useState(initialStateFilters);
    const [countOfCategories, setCountOfCategories] = useState(0);

    useEffect(() => {
        switch (viewAs) {
            case APP_ROLES.SCHOOL_ADMIN:
                const typeOptions = withClearOption([
                    {
                        description: "Alumni",
                        value: APP_ROLES.ALUMNI
                    },
                    {
                        description: "School Admin",
                        value: APP_ROLES.SCHOOL_ADMIN
                    },
                    {
                        description: "Supporters/Donors",
                        value: APP_ROLES.SUPPORTERS
                    }
                ]);

                setTypeAccountOptions(typeOptions);
                break;
            case APP_ROLES.ALUMNI:
                const typeOptionsAlumni = withClearOption([
                    {
                        description: "Alumni",
                        value: APP_ROLES.ALUMNI,
                    },
                    {
                        description: "Admin",
                        value: APP_ROLES.SCHOOL_ADMIN,
                    },
                    {
                        description: "Supporters/Donors",
                        value: APP_ROLES.SUPPORTERS,
                    },
                ]);

                setTypeAccountOptions(typeOptionsAlumni);
                break;

            default:
                break;
        }
    }, [viewAs]);

    useEffect(() => {
        if (accountType === APP_ROLES.ALUMNI) {
            setGraduationIsDisabled(false)
        } else {
            setCohort(''); //clear any present selection
            setGraduationIsDisabled(true);
        }
    }, [accountType]);

    const handleAddMoreFiltersClick = () => {
        setMoreFiltersIsOpen(true);
    }

    const handleAddMoreFiltersOnClose = () => {
        setMoreFiltersIsOpen(false);
    }

    const handleOnClickApply = () => {
        ///extract key/value from object `moreFilters` which has a custom-defined schema
        const reduced = Object.entries(moreFilters).reduce((acc, [key, obj]) => {
            return {
                ...acc,
                [key]: (!obj.value || typeof obj.value === "boolean")
                    ? (obj.value)
                    : Array.from(obj.value)
            };
        }, {});

        ///lifting state up
        const _filters = {
            accountType: accountType,
            cohort: cohort,
            ...reduced
        };
        handleClickApply(_filters);
    };

    const handleOnClickAdd = (filters) => {
        const count = Object.keys(filters).reduce((accumulator, key) => {
            ///if wentToCollege's value is typeof boolean, or Set collections' size are greater than zero, 
            ///then it means end user applied a filter in that category. update badge counter with number of filter categories applied.
            return accumulator + ((typeof filters[key].value === "boolean" || filters[key].value?.size > 0) ? 1 : 0)
        }, 0);
        setCountOfCategories(count);
        setMoreFilters(filters)
    };

    return (
        <>
            <Grid container spacing={2} paddingY={(theme) => theme.spacing(4)} paddingX={(theme) => theme.spacing(2)}>
                <Grid item xs={3}>
                    <SelectDropdown label="Type of Account" value={accountType} options={typeAccountsOptions} onChange={(ev) => setAccountType(ev.target.value)} />
                </Grid>

                <Grid item xs={3}>
                    <SelectDropdown label="BWS Graduation Year" value={cohort} options={graduationYearOptions} onChange={(ev) => setCohort(ev.target.value)} disabled={graduationIsDisabled} />
                </Grid>

                <Grid item xs="auto">
                    <Badge color="primary" badgeContent={countOfCategories}>
                        <OutlinedButton label="Add More Filters" color="primary" onClick={handleAddMoreFiltersClick} />
                    </Badge>
                </Grid>

                <Grid item xs="auto">
                    <ContainedButton label="Apply" color="primary" onClick={handleOnClickApply} />
                </Grid>

                <Grid item xs />

                <Grid item xs={3}>
                    <TextFieldIcon label="Search by name or e-mail" value={searchTerm} icon={<SearchIcon />} onKeyDown={(ev) => { setSearchTerm(ev.target.value); }} onChange={(ev) => { setSearchTerm(ev.target.value); handleSearchOnChange(ev); }} />
                </Grid>
            </Grid>

            <MoreFiltersDialog isOpen={moreFiltersIsOpen} handleOnClose={handleAddMoreFiltersOnClose} handleOnAdd={handleOnClickAdd} />
        </>
    );
};

export default FiltersContainer;