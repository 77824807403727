import { StorageManager } from "@aws-amplify/ui-react-storage";
import { AttachFile } from "@mui/icons-material";
import { Dialog, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

const ShareFileUpload = ({ onSuccessUpload, onErrorUpload, bucketPath }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const processFile = ({ file }) => {
    const uniqueId = Date.now(); //timestamp as unique Id
    const originalName = file.name;
    const fileExtension = originalName.split(".").pop();
    const newKey = `${originalName}_${uniqueId}.${fileExtension}`;
    return { file, key: newKey };
  };

  return (
    <>
      <Tooltip title="Attach a file">
        <IconButton onClick={handleClickOpen}>
          <AttachFile />
        </IconButton>
      </Tooltip>

      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>Upload a file</DialogTitle>

        <StorageManager
          acceptedFileTypes={[".pdf", ".xls", ".xlsx", ".doc", ".docx"]}
          accessLevel="public"
          isResumable={false}
          maxFileCount={3}
          maxSize={7000000} //equals to 7MB. to be defined
          onUploadSuccess={onSuccessUpload}
          onUploadError={onErrorUpload}
          path={bucketPath}
          processFile={processFile}
          shouldAutoProceed={false}
          showThumbnails={false}
        />
      </Dialog>
    </>
  );
};

export default ShareFileUpload;
