import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

const SelectDropdown = ({ label, value, options, disabled = false, ...props }) => {

    return (
        <TextField
            disabled={disabled}
            fullWidth
            size="small"
            select
            label={label}
            value={value}
            {...props}
        >
            {options?.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.description}
                </MenuItem>
            )
            )}
        </TextField>
    );
};

export default SelectDropdown;