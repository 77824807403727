import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    chatsUserBelongsTo: {
        items: undefined,
        nextToken: null
    },
    chats: {
        items: [],
    },
    activeChat: {
        chatId: null,
    },
    activeChatParticipants: {
        participants: [],
    },
    adminParticipants: {
        items: []
    },
    searchInputChat: '',
};

const MessagingSlice = createSlice({
    name: "messaging",
    initialState,
    reducers: {
        storeChatUserBelongsTo: (state, action) => {
            return {
                ...state,
                chatsUserBelongsTo: {
                    ...state.chatsUserBelongsTo,
                    items: state.chatsUserBelongsTo.items?.concat(action.payload) || action.payload
                }
            };
        },
        storeChatUserBelongsToNextToken: (state, action) => {
            return {
                ...state,
                chatsUserBelongsTo: {
                    ...state.chatsUserBelongsTo,
                    nextToken: action.payload
                }
            };
        },
        storeChat: (state, action) => {
            return {
                ...state,
                chats: {
                    ...state.chats,
                    items: [...state.chats.items, action.payload]
                }
            };
        },
        selectChat: (state, action) => {
            return {
                ...state,
                activeChat: { 
                    ...state.activeChat,
                    ...action.payload 
                }
            }
        },
        storeChatParticipants: (state, action) => {
            return {
                ...state,
                activeChatParticipants: {
                    ...state.activeChatParticipants,
                    participants: [...action.payload],
                }
            }
        },
        storeAdminParticipants: (state, action) => {
            return {
                ...state,
                adminParticipants: {
                    ...state.adminParticipants,
                    items: [...state.adminParticipants.items, ...action.payload]
                }
            };
        },
        updateSearchInput: (state, action) => {
            return {
                ...state,
                searchInputChat: action.payload
            };
        },
    }
});

export const { storeChatUserBelongsTo, storeChatUserBelongsToNextToken, storeChat, selectChat, storeAdminParticipants, updateSearchInput, storeChatParticipants } = MessagingSlice.actions

export default MessagingSlice.reducer