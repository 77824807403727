import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import { Editor, Transforms, createEditor, Element as SlateElement } from "slate";
import { Box, Link } from "@mui/material";
import { SpecialButtons } from "./SpecialButtons";
import ContainedButton from "../shared/ContainedButton";
import TextButton from "../shared/TextButton";
import { useDispatch } from "react-redux";
import { showAlert } from "../../store/slices/sharedSlice";
import { GetApp } from "@mui/icons-material";
import { Storage } from "aws-amplify";
import { BUCKET_ANNOUNCEMENTS_PATH } from "../../constants/appConstants";

const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const TextFieldFormat = ({
  message,
  setMessage,
  readOnly,
  handleSubmit,
  handleSubmitEdit,
  isEditing,
  cancelEditing,
  handleOnSuccessUploadImage,
  handleOnSuccessUploadFile,
  image,
  files,
}) => {
  const dispatch = useDispatch();
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);
  const [storageImage, setStorageImage] = useState({});
  const [filesUrl, setFilesUrl] = useState([]);

  useEffect(() => {
    getFiles();
  }, []);

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
      split: true,
    });
    let newProperties;
    if (LIST_TYPES.includes(format)) {
      newProperties = {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
      };
    }
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const isBlockActive = (editor, format, blockType = "type") => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
      })
    );
    return !!match;
  };

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const Element = ({ attributes, children, element }) => {
    switch (element.type) {
      case "bulleted-list":
        return <ul {...attributes}>{children}</ul>;
      case "list-item":
        return <li {...attributes}>{children}</li>;
      case "numbered-list":
        return <ol {...attributes}>{children}</ol>;
      case "link":
        return (
          <a href={element.href} rel="noreferrer" target="_blank">
            {children}
          </a>
        );
      default:
        return <p {...attributes}>{children}</p>;
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }
    if (leaf.italic) {
      children = <em>{children}</em>;
    }
    if (leaf.underline) {
      children = <u>{children}</u>;
    }
    return <span {...attributes}>{children}</span>;
  };

  const MarkButton = ({ format, type }) => {
    const editor = useSlate();
    return (
      <SpecialButtons
        type={type}
        active={isMarkActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      />
    );
  };

  const BlockButton = ({ format, type }) => {
    const editor = useSlate();
    return (
      <SpecialButtons
        type={type}
        active={isBlockActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
      />
    );
  };

  const OtherButton = ({ type, format }) => {
    const editor = useSlate();
    return (
      <SpecialButtons
        type={type}
        active={isBlockActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
        bucketPath={BUCKET_ANNOUNCEMENTS_PATH}
        handleAddLink={(url) => handleAddLink(isBlockActive(editor, format), url)}
        onSuccessUploadImage={handleOnSuccessUploadImage}
        onSuccessUploadFile={handleOnSuccessUploadFile}
        onErrorUpload={handleOnErrorUpload}
      />
    );
  };

  const getAttachmentName = (attachment) => {
    //e.g: folder1/announcements/_image.png_1684787553262.png
    const fileNameWithUniqueId = attachment?.split('/').pop(); //_image.png_1684787553262.png
    const delimiterIndex = fileNameWithUniqueId?.lastIndexOf('_');
    const originalName = fileNameWithUniqueId?.slice(0, delimiterIndex); //_image.png
    return originalName;
  }

  const getFiles = async () => {
    if (image) {
      let imageURL = "";
      imageURL = await Storage.get(image);
      setStorageImage({name: getAttachmentName(image), url: imageURL});
    }
    if (files) {
      let filesURL = [];
      const attachmentPromises = files.map( async (file) => {
        return { name: getAttachmentName(file), url: await Storage.get(file) };
      });
      filesURL = await Promise.all(attachmentPromises);
      setFilesUrl(filesURL);
    }
  };

  const handleAddLink = (isActive, url) => {
    if (!isActive) {
      if (url) {
        const link = {
          type: "link",
          href: url,
          children: [{ text: url }],
        };
        Transforms.insertNodes(editor, link);
      }
    } else {
      //For removing the link node
      Transforms.setNodes(editor, { type: "paragraph" });
    }
  };

  const handleOnErrorUpload = (error) => {
    dispatch(
      showAlert({
        message: "An error ocurred while uploading your file. Please, try again.",
        severity: "error",
      })
    );
  };

  const count = (message) => {
    if (!readOnly){
      const content = Editor.string(editor, editor);
      const count = content.length;
      setMessage(message)
    }
  }

  return (
    <Box
      sx={{
        "& p, a, ul, ol, span ": {
          mb: 0,
          mt: 0,
          mr: 0,
          ml: 0,
          fontSize: "1rem",
          lineHeight: "1.2",
        },
        "& ol, ul": {
          paddingLeft: 4,
        },
      }}
    >
      <Slate editor={editor} value={message} onChange={setMessage}>
        {readOnly ? (
          <>
            <Editable
              style={{
                overflow: "auto",
                color: "#000",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
                marginBlock: "13px",
              }}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              readOnly={readOnly}
            />
            {image && (
              <Box
                sx={{
                  marginY:1,
                  display: "flex",
                }}
              >
                <img
                  src={storageImage.url}
                  alt={storageImage.name}
                  style={{ cursor: "pointer" }}
                />
              </Box>
            )}
            {filesUrl && filesUrl.map((file, index) => (
              <Box
                key={index}
                sx={{
                  marginY: 1,
                  display: "flex",
                }}
              >
                <Link
                  href={file.url}
                  target="_blank"
                  download
                  underline="none"
                  color="secondary"
                  display="flex"
                  alignItems="center"
                >
                  <GetApp /> {`${file.name}`}
                </Link>
              </Box>
            ))}
          </>
        ) : (
          <>
            <Editable
              placeholder="Share thoughts, ideas, updates and opportunities"
              style={{
                maxHeight: "1000px",
                minHeight: "150px",
                overflow: "clip",
                borderBottom: "2px solid #590006",
                backgroundColor: "#a3a1a136",
                color: "#000",
                marginTop: "15px",
                padding: "10px",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
              }}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
            />
            <Box sx={{ display: "flex", marginBottom: 3, alignItems: "center" }}>
              <Box sx={{ marginRight: "auto", "& button": { marginRight: 0.3 } }}>
                <MarkButton format="bold" type="bold" />
                <MarkButton format="italic" type="italic" />
                <MarkButton format="underline" type="underline" />
                <BlockButton format="numbered-list" type="number" />
                <BlockButton format="bulleted-list" type="bullet" />
                <OtherButton format="link" type="link" />
                <OtherButton format="image" type="image" />
                <OtherButton format="file" type="file" />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", marginTop: 1.5 }}>
                {!isEditing ? (
                  <Box>
                    <ContainedButton label={"Post"} onClick={handleSubmit} />
                  </Box>
                ) : (
                  <Box sx={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
                    <Box sx={{ marginRight: 3 }}>
                      <TextButton label={"Cancel"} onClick={cancelEditing} />
                    </Box>
                    <Box>
                      <ContainedButton
                        label={"Edit"}
                        onClick={handleSubmitEdit}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}
      </Slate>
    </Box>
  );
};
