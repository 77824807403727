import { TextField } from "@mui/material";

const FieldText = ({ label, ...props }) => {
  const minWidth = "12rem";
  const fontsize = "0.75rem";
  return (
    <TextField
      label={label}
      sx={{ width: minWidth }}
      InputProps={{
        style: { fontSize: fontsize, color: "black" },
      }}
      InputLabelProps={{
        style: {
          fontSize: fontsize,
          color: "black",
          textTransform: "uppercase",
        },
        shrink: true,
      }}
      FormHelperTextProps={{
        style: { fontSize: fontsize, color: "black" },
      }}
      size="small"
      variant="outlined"
      {...props}
    />
  );
};

export default FieldText;
