import { Container, FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Stack, Tooltip } from '@mui/material';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import React, { useState } from 'react'

function CategoryWentToCollege({ initial, updateFiltersApplied }) {
    const [went, setWent] = useState(initial);

    const handleChange = (event) => {
        const _went = event.target.value === "true";
        setWent(_went);

        ///update parent's filters
        updateFiltersApplied(_went);
    };

    const handleOnClickClearSelection = () => {
        setWent(null)

        ///update parent's filters
        updateFiltersApplied(null);
    };

    return (
        <Container maxWidth="sm">
            <Stack marginTop={2} direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                <FormControl>
                    <FormLabel>Went to college?</FormLabel>
                    <RadioGroup
                        value={went}
                        onChange={handleChange}
                        row
                    >
                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>

                <Tooltip title="Clear selection" placement='top'>
                    <IconButton onClick={handleOnClickClearSelection}>
                        <FilterAltOffIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        </Container>
    )
}

export default CategoryWentToCollege;