import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import * as subscriptions from '../graphql/subscriptions';
import { getChatMocked, listRecentMessagesMock, listAdminParticipants, listUserChatsMocked } from '../mocks/api/messaging_api_mock';


/**
 * Function to search for matching chat IDs in all Set collections
 * @author ChatGPT - Free Research Preview
 * @param {array} data 
 * @returns `true` and ID if a matching chatID is found in all `Set` collections, otherwise, false.
 */
const searchMatchingChatIDs = (data) => {
    const allChatIDs = new Set();
    for (const item of data) {
        for (const chatID of item.chats) {
            if (allChatIDs.has(chatID)) {
                return { found: true, chatId: chatID };
            }
            allChatIDs.add(chatID);
        }
    }
    return { found: false, chatId: null };
};

export const MessagingApi = {
    /**
     * Fetchs top recent chats a given `UserProfile` is part of.
     * @param {string} participantId `UserProfileId` of the user to list all chats. 
     * @returns GraphQLResult<any>
     */
    listUserChats: async (participantId) => {
        if (process.env.NODE_ENV === 'development') return listUserChatsMocked;
        const variables = {
            participantId: participantId,
            limit: 20
        };

        const result = await API.graphql(
            graphqlOperation(queries.chatParticipantsByParticipantId, variables)
        );

        return result;
    },

    /**
     * Fetchs next chats for  a given `UserProfile`.
     * @param {string} participantId `UserProfileId` of the user to list all chats. 
     * @returns GraphQLResult<any>
     */
    listMoreUserChats: async (participantId, nextToken) => {
        if (process.env.NODE_ENV === 'development') return;
        //if `nextToken` is `null`, then it means that there is no more data to return from the API
        if (nextToken === null) return; //define what needs to be returned.

        const variables = {
            participantId: participantId,
            limit: 20,
            nextToken: nextToken,
        };

        const result = await API.graphql(
            graphqlOperation(queries.chatParticipantsByParticipantId, variables)
        );

        return result
    },

    /**
     * Fetch most recent messages for a given `Chat`.
     * @param {string} chatId ID of Chat to load most recent messages from.
     * @returns GraphQLResult<any>
     */
    listRecentMessagesByChat: async (chatId) => {
        if (process.env.NODE_ENV === 'development') return listRecentMessagesMock;

        const variables = {
            chatId: chatId,
            limit: 25,
            sortDirection: "DESC"
        };

        const result = await API.graphql(
            graphqlOperation(queries.listMessages, variables)
        );

        return result;
    },

    /**
     * Fetch more recent messages for a given `Chat`.
     * @param {string} chatId ID of the Chat to load most recent messages from.
     * @param {string} nextToken Token to load more messages.
     * @returns GraphQLResult<any>
     */
    listMoreRecentMessagesByChat: async (chatId, nextToken) => {
        if (process.env.NODE_ENV === 'development') return;

        if (nextToken === null) return; //define what needs to be returned.

        const variables = {
            chatId: chatId,
            limit: 25,
            sortDirection: "DESC",
            nextToken: nextToken
        };

        const result = await API.graphql(
            graphqlOperation(queries.listMessages, variables)
        );

        return result;
    },

    /**
     * Subscribe for newly sent messages for a given `Chat`
     * @param {string} chatId ID of the Chat
     * @returns GraphQLResult<any>
     */
    listenForNewMessages: (chatId) => {
        if (process.env.NODE_ENV === "development") return;

        const variables = {
            filter: {
                chatId: {
                    eq: chatId
                }
            }
        };

        const subscription = API.graphql(
            graphqlOperation(subscriptions.onCreateMessage, variables)
        );

        return subscription;
    },

    /**
     * Creates a new `Chat` between a list of `Participant`s so that they can start sending messages right away.
     * @param {Array<String>} participantIds 
     * @returns GraphQLResult<any>
     */
    startNewChat: async (participantIds) => {
        const newChatResult = await API.graphql(
            graphqlOperation(mutations.createChat, { input: {} })
        );

        const newChat = newChatResult.data.createChat;

        let results = [];

        participantIds.forEach(async participantId => {
            const result = await API.graphql(
                graphqlOperation(mutations.createChatParticipants, {
                    input: {
                        chatId: newChat.id,
                        participantId: participantId
                    }
                })
            );

            results.push(result);
        });

        return newChat;
    },

    /**
     * Creates a new `Message` for a specific `Chat`. It supports formatted text, an image to be uploaded to an S3 Bucket, or a list of attachments. 
     * @param {Object} messageDetails A message to be sent to a specific `chatId`, assuming `sender` as the current user logged in, with a body of tpye `MessageContent`
     * @returns 
     */
    sendMessage: async (messageDetails) => {
        const newMessage = await API.graphql(
            graphqlOperation(mutations.createMessage, { input: messageDetails })
        );

        return newMessage;
    },

    /**
     * Lists all active `SchoolAdmin`s.
     * @returns GraphQLResult<any>
     */
    listAdminParticipants: async () => {
        if (process.env.NODE_ENV === 'development') return listAdminParticipants;
        const variables = {
            filter: {
                and: [
                    {
                        isSchoolAdmin: {
                            eq: true
                        }
                    },
                    {
                        isActive: {
                            eq: true
                        }
                    }
                ]
            }
        };

        const result = await API.graphql(
            graphqlOperation(queries.listParticipants, variables)
        );

        return result;
    },

    /**
     * Updates a given `Participant` record with its profile picture S3 key.
     * @param {string} userId `UserProfileId` of the `Participant` to be updated
     * @param {string} profilePicturekey S3 key to store under `Participant` record
     * @returns GraphQLResult<any>
     */
    updateParticipantProfilePictureKey: async (userId, profilePicturekey) => {
        const updatedItem = {
            id: userId,
            profilePictureKey: profilePicturekey
        };

        const result = await API.graphql(
            graphqlOperation(
                mutations.updateParticipant,
                {
                    input: updatedItem,
                }
            )
        );

        return result;
    },

    /**
     * Updates a given `Participant` record with its new `isActive` status.
     * @param {string} userId `UserProfileId` of the `Participant` to be updated
     * @param {boolean} isActive New status
     * @returns GraphQLResult<any>
     */
    updateParticipantIsActive: async (userId, isActive) => {
        const updatedItem = {
            id: userId,
            isActive: isActive
        };

        const result = await API.graphql(
            graphqlOperation(
                mutations.updateParticipant,
                {
                    input: updatedItem,
                }
            )
        );

        return result;
    },
    /**
     * Updates `Participant`'s details.
     * @param {string} userId `UserProfileId` of the `Participant` to be updated
     * @param {object} details Object with key-value pairs to be updated
     * @returns GraphQLResult<any>
     */
    updateParticipantDetails: async (userId, details) => {
        const updatedItem = {
            id: userId,
            ...details
        };

        const result = await API.graphql(
            graphqlOperation(
                mutations.updateParticipant,
                {
                    input: updatedItem,
                }
            )
        );

        return result;
    },

    /**
     * Creates a new record in `Participant` 
     * @param {Participant} participant An object containing a subset of `UserProfile`'s attributes to reflect newly signed up profile.
     * @returns GraphQLResult<any>
     */
    createParticipant: async (participant) => {
        const result = await API.graphql(
            graphqlOperation(
                mutations.createParticipant,
                {
                    input: participant,
                }
            )
        );

        return result;
    },

    /**
     * Gets a specific `Chat`
     * @param {string} id ID of `Chat` to be fetched
     * @returns GraphQLResult<any>
     */
    getChat: async (id) => {
        if (process.env.NODE_ENV === 'development') return getChatMocked;

        const variables = {
            id: id,
        };

        const result = await API.graphql(
            graphqlOperation(queries.getChat, variables)
        );

        return result;
    },

    /**
     * Subscribe for newly created chats for a given `Participant`
     * @param {string} participantId ID of the Participant. Used to filter down subscription events.
     * @returns GraphQLResult<any>
     */
    listenForNewChats: (participantId) => {
        if (process.env.NODE_ENV === "development") return;

        const variables = {
            filter: {
                participantId: {
                    eq: participantId
                }
            }
        };

        const subscription = API.graphql(
            graphqlOperation(subscriptions.onCreateChatParticipants, variables)
        );

        return subscription;
    },

    /**
     * Given a set of User's ID, checks if a Chat has been already started between them.
     * @param {Array<string>} othersParticipantIds 
     * @returns An object with a `found` boolean and ID of the `Chat`, if exists.
     */
    lookUpForChatBetweenParticipants: async (othersParticipantIds) => {
        let queriesResults = [];

        for await (const id of othersParticipantIds) {
            const variables = {
                participantId: id
            };

            const result = await API.graphql(
                graphqlOperation(queries.chatParticipantsByParticipantId, variables)
            );

            const items = new Set(result.data?.chatParticipantsByParticipantId.items.map(i => i.chatId));

            queriesResults.push({
                participantId: id,
                chats: items
            });
        };

        //now, lookup for same chatId in all Sets collections. if found, then there is an existing chat.
        const found = searchMatchingChatIDs(queriesResults);

        return found;
    }
};