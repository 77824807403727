import { Avatar, Box, ListItem, Skeleton } from '@mui/material';
import React from 'react'

function ActiveChatBodySkeleton() {
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent:'flex-end'}}>
                <Box>
                    <Skeleton sx={{ margin: 1 }} variant="circular">
                        <Avatar />
                    </Skeleton>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Skeleton width="25%" variant='rounded'>
                        <ListItem />
                    </Skeleton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                    <Skeleton sx={{ margin: 1 }} variant="circular">
                        <Avatar />
                    </Skeleton>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Skeleton width="15%" variant='rounded'>
                        <ListItem />
                    </Skeleton>
                </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                    <Skeleton sx={{ margin: 1 }} variant="circular">
                        <Avatar />
                    </Skeleton>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Skeleton width="20%" variant='rounded'>
                        <ListItem />
                    </Skeleton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                    <Skeleton sx={{ margin: 1 }} variant="circular">
                        <Avatar />
                    </Skeleton>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <Skeleton width="8%" variant='rounded'>
                        <ListItem />
                    </Skeleton>
                </Box>
            </Box>
        </>
    )
}

export default ActiveChatBodySkeleton;