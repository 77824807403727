import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AlumniNetworkApi } from "../../apis/AlumniNetworkApi";
import { APP_ROLES, APP_ROUTES } from "../../constants/appConstants";
import NetworkUserProfileCard from "./NetworkUserProfileCard";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const ConnectedNetwork = () => {
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const alumniSelected = useSelector((state) => state.alumni.alumniSelected);
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [showSendMessage, setShowSendMessage] = useState(true);
  const navigate = useNavigate();
  const handleUserProfileCardOnClick = (_userProfileId) => {
    navigate(`${APP_ROUTES.PROFILE_DETAILS}/${_userProfileId}`);
  };
  useEffect(() => {
    let userId = "";
    if (userLoggedIn.profileType == APP_ROLES.PARENTS) {
      userId = alumniSelected.alumniId;
      setShowSendMessage(false);
    } else {
      userId = userLoggedIn.userName;
      setShowSendMessage(true);
    }
    AlumniNetworkApi.getConnections(userId, null, "Connected")
      .then((resp) => { setConnectedUsers(resp.data) })
      .catch((err) => console.log(err));
  }, [userLoggedIn, alumniSelected]);

  return (
    <>
      {connectedUsers.length === 0 && <Box m={1} pt={1}><Typography variant="body2">You don't have any alumni in your network.</Typography></Box>}
      {connectedUsers.map((up) => (
        <NetworkUserProfileCard
          key={up.connectionProfileId}
          profile={up.connectionInfo.connInfo}
          handleCardOnClick={(ev) => handleUserProfileCardOnClick(up.connectionInfo.connInfo.userProfileId)}
          makeApiCalls={null}
          currentUserInfo={null}
          showSendMessage={showSendMessage}
        />
      ))}
    </>
  );
};

export default ConnectedNetwork;
