import { React, useState, useEffect } from "react";
import { Box, Divider, IconButton, Tooltip } from "@mui/material";
import { AnnouncementApi } from "../../apis/AnnouncementApi";
import { AnnouncementCard } from "./AnnouncementCard";
import { useSelector, useDispatch } from "react-redux";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { APP_ROLES, NOTIFICATION_TOPICS } from "../../constants/appConstants";
import TextButton from "../shared/TextButton";
import { saveISO8601Time } from "../../utils/utils";
import { TextFieldFormat } from "./TextFieldFormat";
import { useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Close } from "@mui/icons-material";
import { NotificationApi } from "../../apis/NotificationApi";

export const AnnouncementTab = () => {
  const [announcementList, setAnnouncementList] = useState([]);
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const role = userLoggedIn.profileType;
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState(1);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState({});
  const [imageS3Key, setImageS3Key] = useState();
  const [fileS3Key, setFileS3Key] = useState();
  const [message, setMessage] = useState([{ type: "paragraph", children: [{ text: "" }] }]);
  let [searchParams, setSearchParams] = useSearchParams();
  const post = searchParams.get("postNumber");

  useEffect(() => {
    dispatch(toggleLoader({ isLoading: true }));
    if (post) {
      AnnouncementApi.getSingleAnnouncement(post)
        .then((response) => {
          setAnnouncementList([response.data]);
          setLastEvaluatedKey({});
        })
        .catch((err) => {
          console.error(err);
          dispatch(toggleLoader({ isLoading: false }));
          dispatch(
            showAlert({
              message: "An error ocurred while fetching the Announcement. Please, try again.",
              severity: "error",
            })
          );
        })
        .finally(() => {
          dispatch(toggleLoader({ isLoading: false }));
        });
    } else {
      AnnouncementApi.getAllAnnouncements(lastEvaluatedKey)
        .then((response) => {
          let list = [...announcementList];
          response.data.items.forEach((item) => {
            list.push(item);
          });
          setAnnouncementList(list);

          if (response.data.LastEvaluatedKey) {
            setLastEvaluatedKey(response.data.LastEvaluatedKey);
          } else {
            setLastEvaluatedKey({});
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(toggleLoader({ isLoading: false }));
          dispatch(
            showAlert({
              message: "An error ocurred while fetching the Announcements. Please, try again.",
              severity: "error",
            })
          );
        })
        .finally(() => {
          dispatch(toggleLoader({ isLoading: false }));
        });
    }
  }, [pagination, post]);

  const handleBack = () => {
    if (post) {
      searchParams.delete("postNumber");
      setSearchParams(searchParams);
      setAnnouncementList([]);
    }
  };

  const handlePagination = () => {
    if (Object.keys(lastEvaluatedKey).length !== 0) {
      setPagination(pagination + 1);
    }
  };

  const handleChange = (message) => {
    setMessage(message);
  };

  const handleDelete = (index) => {
    let list = [...announcementList];
    list.splice(index, 1);
    setAnnouncementList(list);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(toggleLoader({ isLoading: true }));

    const announcementData = {
      publisherId: userLoggedIn.userName,
      publisherFirstName: userLoggedIn.firstName,
      publisherLastName: userLoggedIn.lastName,
      publishedAt: saveISO8601Time(),
      message: JSON.stringify(message),
      editedAt: "",
    };
    if (imageS3Key) {
      announcementData.image = imageS3Key;
    }
    if (fileS3Key) {
      announcementData.files = fileS3Key;
    }

    AnnouncementApi.postAnnouncement(announcementData)
      .then((response) => {
        setAnnouncementList([response.data.body, ...announcementList]);
        setMessage([{ type: "paragraph", children: [{ text: "" }] }]);
        setImageS3Key();
        setFileS3Key();

        NotificationApi.createNotification(
          "New Post!",
          `A new post was recently made by ${userLoggedIn.firstName} ${userLoggedIn.lastName}`,
          `${window.location.pathname}?postNumber=${response.data.body.postNumber}`,
          NOTIFICATION_TOPICS.ADMINPOST
        )
          .then((data) => {})
          .catch((err) => console.log(err));

        ///Record a new event to trigger the Pinpoint Journey which notifies all users about this new announcement
        NotificationApi.recordNewEvent("schoolAdminPostedNewAnnouncement");
      })
      .catch((err) => {
        console.error(err);
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
          showAlert({
            message: "Failed to create new Announcement",
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(toggleLoader({ isLoading: false }));
      });
  };

  const handleOnSuccessUploadImage = ({ key }) => {
    setImageS3Key(key);
    dispatch(
      showAlert({
        message: "Your image was uploaded successfully",
        severity: "success",
      })
    );
  };

  const handleOnSuccessUploadFile = ({ key }) => {
    fileS3Key ? setFileS3Key([...fileS3Key, key]) : setFileS3Key([key]);
    dispatch(
      showAlert({
        message: "Your file was uploaded successfully",
        severity: "success",
      })
    );
  };

  return (
    <>
      {role === APP_ROLES.SCHOOL_ADMIN && (
        <Box>
          <TextFieldFormat
            key={announcementList?.length}
            message={message}
            setMessage={(message) => handleChange(message)}
            readOnly={false}
            handleSubmit={handleSubmit}
            handleOnSuccessUploadImage={handleOnSuccessUploadImage}
            handleOnSuccessUploadFile={handleOnSuccessUploadFile}
          />
          {imageS3Key && (
            <TextButton
              label={`Sharing "${imageS3Key.split("_")[0].slice(7)}"`}
              endIcon={<Close />}
              onClick={() => setImageS3Key(null)}
            />
          )}
          {fileS3Key &&
            fileS3Key.map((file, index) => (
              <TextButton
                key={index}
                label={`Sharing "${file.split("_")[0].slice(7)}"`}
                endIcon={<Close />}
                onClick={() => setFileS3Key([])}
              />
            ))}
          <Divider sx={{ borderWidth: 1 }} />
        </Box>
      )}
      {post && (
        <Tooltip title="Back">
          <IconButton sx={{ marginTop: 1.5 }} onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      )}
      {announcementList?.map((announcement, index, array) => (
        <AnnouncementCard
          key={`${array.length}-${index}`}
          announcementData={announcement}
          role={role}
          handleDelete={() => handleDelete(index)}
        />
      ))}
      <Box sx={{ textAlign: "center" }}>
        <TextButton
          label={"Load more"}
          onClick={handlePagination}
          disabled={Object.keys(lastEvaluatedKey).length === 0}
        >
          Load more
        </TextButton>
      </Box>
    </>
  );
};
