import { Chip, Container, Divider, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import TextFieldIcon from '../../shared/TextFieldIcon';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { validateEmailAddress } from '../../../utils/utils';

const SEARCH_INPUT_HELPER_TEXT = "Hitting enter will add the email address to the list below";

function CategoryEmail({ initial, updateFiltersApplied }) {
    const [searchInput, setSearchInput] = useState("");
    const [emails, setEmails] = useState(initial);
    const [helperText, setHelperText] = useState(SEARCH_INPUT_HELPER_TEXT);

    const handleOnKeyDown = (event) => {
        ///prevent adding empty spaces
        if (event.key === "Enter" && searchInput.trim() !== "" && validateEmailAddress(searchInput.trim())) {
            ///add searchInput to emails
            const mailAdded = new Set([...emails, searchInput.trim().toLocaleLowerCase()]);
            setEmails(mailAdded);
            
            ///clear searchInput
            setSearchInput("");
            setHelperText(SEARCH_INPUT_HELPER_TEXT);
        } else {
            setHelperText("Email address invalid.")
        }
    };

    const handleOnChangeSearchInput = (event) => {
        setSearchInput(event.target.value);
    };

    const handleOnDelete = (chipToDelete) => {
        const newSet = new Set(emails.values());
        const deleted = newSet.delete(chipToDelete);
        if (deleted) setEmails(newSet);
    };

    const handleOnClickClearSelection = () => {
        const emptySet = new Set();
        setEmails(emptySet);
    };

    useEffect(() => {
        ///update parent's filters
        updateFiltersApplied(emails);
    }, [emails]);

    const chips = useMemo(() => {
        let components = [];
        emails.forEach((email) => components.push(<Chip sx={{ margin: 0.5 }} key={email} label={email} onDelete={() => handleOnDelete(email)} />))

        return components;
    }, [emails]);

    return (
        <Container maxWidth="sm">
            <Stack marginTop={2} direction="row" spacing={2} justifyContent="space-between" alignItems="baseline">
                <TextFieldIcon
                    variant="filled"
                    label="Search by email"
                    icon={<SearchIcon />}
                    helperText="Hitting enter will add the email address to the list below"
                    value={searchInput}
                    onChange={handleOnChangeSearchInput}
                    onKeyDown={handleOnKeyDown}
                />

                <Tooltip title="Clear selection" placement='top'>
                    <IconButton onClick={handleOnClickClearSelection}>
                        <FilterAltOffIcon />
                    </IconButton>
                </Tooltip>
            </Stack>

            <Divider
                sx={{
                    marginY: (theme) => theme.spacing(2),
                }}
            />

            <Typography variant='overline'>
                Selected email addressess
            </Typography>

            <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', listStyle: 'none', p: 0.5, m: 0, }} >
                {chips}
            </Paper>

        </Container>
    )
}

export default CategoryEmail;