import { React, useState } from "react";
import {
  TitleWrapper,
  DialogPopup,
} from "../../utils/styles";
import { DialogTitle, DialogContent, IconButton, DialogActions, Divider } from "@mui/material";
import { AddSchoolForm } from "./AddSchoolForm";
import { AddCollegeForm } from "./AddCollegeForm";
import { AddCertificationForm } from "./AddCertificationForm";
import { ProfilePopupNumber } from "../../constants/appConstants";
import CloseIcon from '@mui/icons-material/Close';
import OutlinedButton from "../shared/OutlinedButton";
import ContainedButton from "../shared/ContainedButton";

//This Component is the popup that is in charge of rendering the 3 popups
export const AddEducationInfoPopup = ({
  popupName,
  popupNumber,
  onClose,
  arrayData,
  addFormData,
  arrayName,
}) => {
  const [formData, setFormData] = useState({});

  const handleSubmit = () => {
    addFormData(formData, arrayName);
    setFormData({});
    onClose();
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const renderForm = () => {
    switch (popupNumber) {
      case ProfilePopupNumber.addSchool:
        return (
          <AddSchoolForm arrayData={arrayData} handleChange={handleChange} />
        );
      case ProfilePopupNumber.addCollege:
        return (
          <AddCollegeForm arrayData={arrayData} handleChange={handleChange} />
        );
      case ProfilePopupNumber.AddCertification:
        return (
          <AddCertificationForm
            arrayData={arrayData}
            handleChange={handleChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <DialogPopup open={true} onClose={onClose}>
      <DialogTitle>
        <TitleWrapper>
          {popupName}
          <IconButton
            aria-label="Close confirmation dialog"
            sx={{
              marginLeft: 2
            }}
            color="primary"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </TitleWrapper>
      </DialogTitle>
      <DialogContent sx={{ marginTop: 2 }}>
        <div style={{ overflow: "hidden", height: "100%", width: "100%" }}>
          <div
            style={{
              padding: 0,
              height: "95%",
              width: "95%",
              boxSizing: "content-box",
            }}
          >
            {renderForm()}
          </div>
        </div>
      </DialogContent>
      <Divider sx={{ marginY: (theme) => theme.spacing(1) }} />
      <DialogActions>
        <OutlinedButton label={"Back"} onClick={onClose} fullWidth={false} />
        <ContainedButton type="submit" label={"Add"} onClick={handleSubmit} fullWidth={false} />
      </DialogActions>
    </DialogPopup>
  );
};
