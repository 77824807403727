import { Fragment, React, useEffect, useState } from "react";
import { TitleWrapper, DialogPopup } from "../../utils/styles";
import {
  DialogTitle,
  DialogContent,
  Divider,
  Button,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import { NotificationApi } from "../../apis/NotificationApi";
import { useDispatch, useSelector } from "react-redux";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import AppNotificationCard from "./AppNotificationCard";
import { APP_ROLES } from "../../constants/appConstants";
import CloseIcon from '@mui/icons-material/Close';

const AppNotifications = ({ onClose }) => {
  const [allNotifications, setAllNotifications] = useState([]);
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const alumniSelected = useSelector((state) => state.alumni.alumniSelected);
  const [nextPageToken, setNextToken] = useState(null);

  const dispatch = useDispatch();

  const loadNotifications = (userId) => {    
    
    dispatch(toggleLoader({ isLoading: true }));
    userId &&
      NotificationApi.fetchNotifications(userId, nextPageToken)
        .then((response) => {
          const { items, nextToken } = response.data.notificationsByDate;
          setNextToken(nextToken);

          //Mark notifications seen
          items.forEach((notfcn) => {
            if (!notfcn.seen) {
              NotificationApi.updateNotificaion(notfcn.id, true, false);
            }
          });

          setAllNotifications([...allNotifications, ...items]);
        })
        .catch((err) => {
          console.error(err);
          dispatch(toggleLoader({ isLoading: false }));
          dispatch(
            showAlert({
              message:
                "An error ocurred while fetching notifications. Please, try again.",
              severity: "error",
            })
          );
        })
        .finally(() => {
          dispatch(toggleLoader({ isLoading: false }));
        });
  };

  useEffect(() => {
    let userId = "";
    if (userLoggedIn.profileType == APP_ROLES.PARENTS) {
      userId = alumniSelected.alumniId;
    } else {
      userId = userLoggedIn.userName;
    }
    loadNotifications(userId);
  }, [userLoggedIn, alumniSelected]);

  const loadMore = () => {
    let userId = "";
    if (userLoggedIn.profileType == APP_ROLES.PARENTS) {
      userId = alumniSelected.alumniId;
    } else {
      userId = userLoggedIn.userName;
    }
    loadNotifications(userId);
    nextPageToken && loadNotifications(userId);
  };

  return (
    <>
      <DialogPopup
        open={true}
        onClose={onClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "450px",
            },
          },
        }}
      >
        <DialogTitle>
          <TitleWrapper>
            Notifications
            <IconButton
              aria-label="Close confirmation dialog"
              sx={{
                marginLeft: 2
              }}
              color="primary"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </TitleWrapper>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              paddingY: 2,
              paddingX: 0,
              height: "550px",
              overflowX: "auto"
            }}
          >
            {allNotifications.map((appNotification) => (
              <Fragment key={appNotification.id}>
                <AppNotificationCard
                  appNotification={appNotification}
                  closeDialog={onClose}
                />
                <Divider
                  sx={{
                    color: "black",
                    // paddingTop: 2,
                    borderStyle: "dashed",
                    width: "100%",
                    marginX: 0,
                    marginY: 1
                  }}
                />
              </Fragment>
            ))}
            <Stack direction={"row"}  justifyContent="center" alignItems="center" sx={{ paddingY: 1 }}>
              <Button
                disabled={nextPageToken == null}
                variant="text"
                onClick={(ev) => loadMore()}
              >
                See more
              </Button>

            </Stack>
          </Box>
        </DialogContent>
      </DialogPopup>
    </>
  );
};

export default AppNotifications;
