import { CheckBox } from "@mui/icons-material";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Pagination,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/system";
import React, { useState } from "react";
import ContainedButton from "../shared/ContainedButton";

const PaginationContainer = ({
  count,
  page,
  handlePageChange,  
  handleSelectAll,
  hideSelectAll,
}) => {
  const [allSelected, setAllSelected] = useState(false);

  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    setAllSelected(checked);
    handleSelectAll(checked);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      paddingTop={(theme) => theme.spacing(2)}
      paddingX={(theme) => theme.spacing(2)}
    >
      <Grid xs="auto" item>
        {!hideSelectAll && (
          <Grid container justifyContent="space-between">
            <Grid>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allSelected}
                      onChange={handleSelectAllChange}
                    />
                  }
                  label="Select All"
                />
              </FormGroup>
            </Grid>           
          </Grid>
        )}
      </Grid>

      <Grid item xs="auto">
        <Pagination
          color="standard"
          siblingCount={1}
          size="medium"
          count={count}
          page={page}
          onChange={handlePageChange}
        />
      </Grid>
    </Grid>
  );
};

export default PaginationContainer;
