import { Grid } from "@mui/material";

const GridItemOneColumnProfileInfo = ({ children }) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {children}
    </Grid>
  );
};

export default GridItemOneColumnProfileInfo;
