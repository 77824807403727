import { React, useState } from "react";
import { Grid } from "@mui/material";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import FieldText from "../shared/FieldText";

export const AddJobsForm = ({ jobsDetailsInfo, handleChange }) => {
  const [job, setJob] = useState(jobsDetailsInfo);
  const [errors, setErrors] = useState({});

  const onChange = (e) => { 
    const { name, value } = e.target;
    let updateValue = value;
    let updateError = { ...errors };
    if (name === "rolesAndResponsibility") {
      if(value.length >= 200){
        updateValue = value.slice(0, 200);
        updateError[name] = "Maximum length exceeded (200 characters)";
      }
    }
    else if (value.length >= 50) {
      updateValue = value.slice(0, 50);
      updateError[name] = "Maximum length exceeded (50 characters)";
    } else {
      updateValue = value;
      delete updateError[name];
    }
    setErrors(updateError);
    handleChange(e);
    setJob({ ...job, [name]: updateValue });
  };

  return (
    <>
      <Grid container spacing={2} marginLeft={2} paddingTop={2}>
        <GridItemProfileInfo>
          <FieldText
            name="title"
            label="TITLE"
            type="text"
            error={!!errors.title}
            helperText={errors.title}
            value={job.title}
            onChange={(e) => onChange(e)}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="companyName"
            label="COMPANY NAME"
            type="text"
            error={!!errors.companyName}
            helperText={errors.companyName}
            onChange={(e) => onChange(e)}
            defaultValue={job.companyName || ""}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="startDate"
            label="START DATE"
            type="date"
            onChange={(e) => onChange(e)}
            defaultValue={job.startDate || ""}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="endDate"
            label="END DATE"
            type="date"
            onChange={(e) => onChange(e)}
            defaultValue={job.endDate || ""}
          />
        </GridItemProfileInfo>
      </Grid>
      <Grid container spacing={2} marginLeft={2} paddingTop={2}>
        <GridItemProfileInfo>
          <FieldText
            name="rolesAndResponsibility"
            label="ROLES & RESPONSIBILITIES"
            type="text"
            error={!!errors.rolesAndResponsibility}
            helperText={errors.rolesAndResponsibility}
            multiline
            minRows={3}
            onChange={(e) => onChange(e)}
            defaultValue={job.rolesAndResponsibility || ""}
          />
        </GridItemProfileInfo>
      </Grid>
    </>
  );
};
