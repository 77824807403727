import React from 'react'
import Avatar from "@mui/material/Avatar";
import EventIcon from '@mui/icons-material/Event';
import CampaignIcon from '@mui/icons-material/Campaign';
import MessageIcon from '@mui/icons-material/Message';
import PeopleIcon from '@mui/icons-material/People';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { NOTIFICATION_TOPICS } from "../../constants/appConstants";
import Box from "@mui/material/Box";

const TopicAvatar = ({ topic }) => {
    const IconBasedOnTopic = () => {
        switch (topic) {
            case NOTIFICATION_TOPICS.ADMINEVENT:
                return <EventIcon />
                break;
            case NOTIFICATION_TOPICS.ADMINPOST:
                return <CampaignIcon />
                break
                case NOTIFICATION_TOPICS.MESSAGE:
                return <MessageIcon />
                break
                case NOTIFICATION_TOPICS.NETWORK:
                return <PeopleIcon />
                break
            default:
                return <CircleNotificationsIcon />
                break;
        };
    };

    return (
        <Box sx={{ paddingLeft: 1 }}>
            <Avatar
                sx={{
                    height: 48,
                    width: 48,
                    backgroundColor: (theme) => theme.palette.tertiary.dark
                }}
                alt={topic}
            >
                {topic && IconBasedOnTopic()}
            </Avatar>
        </Box>
    );
}

export default TopicAvatar;