import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlumniApi } from "../../apis/AlumniApi";
import { alumniSelected } from "../../store/slices/alumniSlice";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import SelectDropdown from "../shared/SelectDropdown";

const AlumniProfileDropdown = () => {
  const [alumni, setAlumni] = useState("");
  const [alumniProfiles, setAlumniProfiles] = useState([]);
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userLoggedIn) return;

    const parentId = userLoggedIn.userName; //get ID of user logged in as well as groups user belongs to

    dispatch(toggleLoader({ isLoading: true }));
    AlumniApi.getAlumniProfilesForParent(parentId)
      .then((response) => {
        setAlumniProfiles(response.data);
        setAlumni(response.data[0]);
        dispatch(alumniSelected(response.data[0]));
      })
      .catch((err) => {
        console.error(err);
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
          showAlert({
            message:
              "An error ocurred while fetching Alumni profiles. Please, try again.",
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(toggleLoader({ isLoading: false }));
      });
  }, [userLoggedIn]);

  const handleChange = (event) => {
    const alumni = event.target.value;
    setAlumni(alumni);
    dispatch(alumniSelected(alumni));
  };

  const alumniOptions = useMemo(() => {
    return alumniProfiles.map(al => {
      return {
        description: `${al.firstName} ${al.lastName}`,
        value: al
      };
    });
  }, [alumniProfiles]);

  return (
    <Box sx={{ m: 1, minWidth: 250 }}>
      <SelectDropdown label={"Alumni"} value={alumni} options={alumniOptions} onChange={handleChange} />
    </Box>
  );
};

export default AlumniProfileDropdown;