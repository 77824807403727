import React from 'react'
import ChatListItemSkeleton from './ChatListItemSkeleton'

function ChatListSkeleton() {
    return (
        <>
            <ChatListItemSkeleton />
            <ChatListItemSkeleton />
            <ChatListItemSkeleton />
            <ChatListItemSkeleton />
            <ChatListItemSkeleton />
            <ChatListItemSkeleton />
            <ChatListItemSkeleton />
        </>
    )
}

export default ChatListSkeleton