/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateNotificationForATopic = /* GraphQL */ `
  subscription OnCreateNotificationForATopic($topic: String) {
    onCreateNotificationForATopic(topic: $topic) {
      id
      title
      description
      navLink
      type
      createdAt
      read
      seen
      topic
      notificationForUserId
      updatedAt
    }
  }
`;
export const onCreateNotificationForAUser = /* GraphQL */ `
  subscription OnCreateNotificationForAUser($notificationForUserId: String) {
    onCreateNotificationForAUser(
      notificationForUserId: $notificationForUserId
    ) {
      id
      title
      description
      navLink
      type
      createdAt
      read
      seen
      topic
      notificationForUserId
      updatedAt
    }
  }
`;
export const onCreateChat = /* GraphQL */ `
  subscription OnCreateChat($filter: ModelSubscriptionChatFilterInput) {
    onCreateChat(filter: $filter) {
      id
      messages {
        items {
          chatId
          content {
            text
          }
          sender {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          sentAt
          updatedAt
          messageSenderId
        }
        nextToken
      }
      participants {
        items {
          id
          chatId
          participantId
          chat {
            id
            createdAt
            updatedAt
          }
          participant {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChat = /* GraphQL */ `
  subscription OnUpdateChat($filter: ModelSubscriptionChatFilterInput) {
    onUpdateChat(filter: $filter) {
      id
      messages {
        items {
          chatId
          content {
            text
          }
          sender {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          sentAt
          updatedAt
          messageSenderId
        }
        nextToken
      }
      participants {
        items {
          id
          chatId
          participantId
          chat {
            id
            createdAt
            updatedAt
          }
          participant {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChat = /* GraphQL */ `
  subscription OnDeleteChat($filter: ModelSubscriptionChatFilterInput) {
    onDeleteChat(filter: $filter) {
      id
      messages {
        items {
          chatId
          content {
            text
          }
          sender {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          sentAt
          updatedAt
          messageSenderId
        }
        nextToken
      }
      participants {
        items {
          id
          chatId
          participantId
          chat {
            id
            createdAt
            updatedAt
          }
          participant {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onCreateMessage(filter: $filter) {
      chatId
      content {
        text
        image {
          key
        }
        attachments {
          key
        }
      }
      sender {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status {
        readBy {
          participantId
          at
        }
      }
      sentAt
      updatedAt
      messageSenderId
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onUpdateMessage(filter: $filter) {
      chatId
      content {
        text
        image {
          key
        }
        attachments {
          key
        }
      }
      sender {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status {
        readBy {
          participantId
          at
        }
      }
      sentAt
      updatedAt
      messageSenderId
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($filter: ModelSubscriptionMessageFilterInput) {
    onDeleteMessage(filter: $filter) {
      chatId
      content {
        text
        image {
          key
        }
        attachments {
          key
        }
      }
      sender {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status {
        readBy {
          participantId
          at
        }
      }
      sentAt
      updatedAt
      messageSenderId
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onCreateNotification(filter: $filter) {
      id
      title
      description
      navLink
      type
      createdAt
      read
      seen
      topic
      notificationForUserId
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onUpdateNotification(filter: $filter) {
      id
      title
      description
      navLink
      type
      createdAt
      read
      seen
      topic
      notificationForUserId
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onDeleteNotification(filter: $filter) {
      id
      title
      description
      navLink
      type
      createdAt
      read
      seen
      topic
      notificationForUserId
      updatedAt
    }
  }
`;
export const onCreateChatParticipants = /* GraphQL */ `
  subscription OnCreateChatParticipants(
    $filter: ModelSubscriptionChatParticipantsFilterInput
  ) {
    onCreateChatParticipants(filter: $filter) {
      id
      chatId
      participantId
      chat {
        id
        messages {
          items {
            chatId
            sentAt
            updatedAt
            messageSenderId
          }
          nextToken
        }
        participants {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      participant {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatParticipants = /* GraphQL */ `
  subscription OnUpdateChatParticipants(
    $filter: ModelSubscriptionChatParticipantsFilterInput
  ) {
    onUpdateChatParticipants(filter: $filter) {
      id
      chatId
      participantId
      chat {
        id
        messages {
          items {
            chatId
            sentAt
            updatedAt
            messageSenderId
          }
          nextToken
        }
        participants {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      participant {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatParticipants = /* GraphQL */ `
  subscription OnDeleteChatParticipants(
    $filter: ModelSubscriptionChatParticipantsFilterInput
  ) {
    onDeleteChatParticipants(filter: $filter) {
      id
      chatId
      participantId
      chat {
        id
        messages {
          items {
            chatId
            sentAt
            updatedAt
            messageSenderId
          }
          nextToken
        }
        participants {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      participant {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
