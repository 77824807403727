import { Avatar, Box, Typography } from '@mui/material';

const ProfilePicture = ({ source, userProfile }) => {
    
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
        }}>
            <Avatar
                alt={`${userProfile.firstName} ${userProfile.lastName}`}
                src={source}
                sx={{
                    width: 275,
                    height: 275,
                }}
            />
            <Typography variant="body2" color="white.main">
                {userProfile.firstName} {userProfile.lastName}
            </Typography>
        </Box>
    );
}

export default ProfilePicture;