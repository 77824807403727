import { React, useState } from "react";
import { Grid, Tooltip } from "@mui/material";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import FieldText from "../shared/FieldText";
import SelectControl from "../shared/Select";
import { SCHOOL_LEVEL, TYPE_OF_SCHOOL } from "../../constants/appConstants";

export const AddSchoolForm = ({ arrayData, handleChange }) => {
  const [school, setSchool] = useState(arrayData);
  const [errors, setErrors] = useState({});

  const onChange = (e) => {
    const { name, value } = e.target;
    let updateValue = value;
    let updateError = { ...errors };
    if (name === "commentsRemarks" || name === "activities" || name === "academicAchievements") {
      if (value.length >= 200) {
        updateValue = value.slice(0, 200);
        updateError[name] = "Maximum length exceeded (200 characters)";
      }else {
        updateValue = value;
        delete updateError[name];
      }
    } else if (value.length >= 50) {
      updateValue = value.slice(0, 50);
      updateError[name] = "Maximum length exceeded (50 characters)";
    } else {
      updateValue = value;
      delete updateError[name];
    }
    setErrors(updateError);
    handleChange(e);
    setSchool({ ...school, [name]: updateValue });
  };

  return (
    <>
      <Grid container spacing={2} marginLeft={2} paddingTop={2}>
        <GridItemProfileInfo>
          <FieldText
            name="schoolName"
            label="SCHOOL NAME"
            type="text"
            error={!!errors.schoolName}
            helperText={errors.schoolName}
            defaultValue={school.schoolName || ""}
            onChange={(e) => onChange(e)}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <SelectControl
            menuItems={TYPE_OF_SCHOOL}
            name="typeOfSchool"
            onChange={(e) => onChange(e)}
            value={school.typeOfSchool || ""}
            label="TYPE OF SCHOOL"
          ></SelectControl>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <SelectControl
            menuItems={[
              { name: "N/A", value: "N/A" },
              { name: "Yes", value: "Yes" },
              { name: "No", value: "No" },
            ]}
            name="associatedWithNaes"
            onChange={(e) => onChange(e)}
            value={school.associatedWithNaes || ""}
            label="ASSOCIATED WITH NAES"
          ></SelectControl>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <SelectControl
            menuItems={SCHOOL_LEVEL}
            name="level"
            onChange={(e) => onChange(e)}
            value={school.level || ""}
            label="LEVEL"
          ></SelectControl>
        </GridItemProfileInfo>
      </Grid>
      <Grid container spacing={2} marginLeft={2} paddingTop={2}>
        <GridItemProfileInfo>
          <FieldText
            name="degree"
            label="DEGREE"
            type="text"
            error={!!errors.degree}
            helperText={errors.degree}
            onChange={(e) => onChange(e)}
            defaultValue={school.degree || ""}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="fieldOfStudy"
            label="FIELD OF STUDY"
            type="text"
            error={!!errors.fieldOfStudy}
            helperText={errors.fieldOfStudy}
            onChange={(e) => onChange(e)}
            defaultValue={school.fieldOfStudy || ""}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="startMonthYear"
            label="START DATE"
            type="date"
            onChange={(e) => onChange(e)}
            defaultValue={school.startMonthYear || ""}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="endMonthYear"
            label="END DATE"
            type="date"
            onChange={(e) => onChange(e)}
            defaultValue={school.endMonthYear || ""}
          />
        </GridItemProfileInfo>
      </Grid>
      <Grid container spacing={2} marginLeft={2} paddingTop={2}>
        <GridItemProfileInfo>
          <FieldText
            name="gradeGpa"
            label="GRADE/GPA"
            type="text"
            error={!!errors.gradeGpa}
            helperText={errors.gradeGpa}
            onChange={(e) => onChange(e)}
            defaultValue={school.gradeGpa || ""}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="activities"
            label="ACTIVITIES"
            type="text"
            error={!!errors.activities}
            helperText={errors.activities}
            multiline
            minRows={3}
            onChange={(e) => onChange(e)}
            defaultValue={school.activities || ""}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="commentsRemarks"
            label="COMMENTS/REMARKS"
            type="text"
            error={!!errors.commentsRemarks}
            helperText={errors.commentsRemarks}
            multiline
            minRows={3}
            onChange={(e) => onChange(e)}
            defaultValue={school.commentsRemarks || ""}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <Tooltip title="Add information about awards, honours, scholarships, achievements achieved while at BWS">
            <div>
              <FieldText
                name="academicAchievements"
                label="ACADEMIC ACHIEVEMENTS"
                type="text"
                error={!!errors.academicAchievements}
                helperText={errors.academicAchievements}
                multiline
                minRows={3}
                onChange={(e) => onChange(e)}
                defaultValue={school.academicAchievements || ""}
              />
            </div>
          </Tooltip>
        </GridItemProfileInfo>
      </Grid>
    </>
  );
};
