import React, { useCallback, useMemo } from "react";
import { Editable, withReact, Slate } from "slate-react";
import { createEditor } from "slate";
import { Box, Link } from "@mui/material";
import { GetApp } from "@mui/icons-material";

const RichtTextReadOnly = (props) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);

  const Element = ({ attributes, children, element }) => {
    switch (element.type) {
      case "bulleted-list":
        return <ul {...attributes}>{children}</ul>;
      case "list-item":
        return <li {...attributes}>{children}</li>;
      case "numbered-list":
        return <ol {...attributes}>{children}</ol>;
      case "link":
        return (
          <a href={element.href} rel="noreferrer" target="_blank">
            {children}
          </a>
        );
      default:
        return <p {...attributes}>{children}</p>;
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }
    if (leaf.italic) {
      children = <em>{children}</em>;
    }
    if (leaf.underline) {
      children = <u>{children}</u>;
    }
    return <span {...attributes}>{children}</span>;
  };
  return (
    <Box
      sx={{
        "& p, a, ul, ol, span ": {
          mb: 0,
          mt: 0,
          mr: 0,
          ml: 0,
          fontSize: "1rem",
          lineHeight: "1.2",
        },
        "& ol, ul": {
          paddingLeft: 4,
        },
      }}
    >
      <Slate editor={editor} value={props.message}>
        <Editable
          style={{
            overflow: "auto",
            color: "#000",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            marginBlock: "13px",
          }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          readOnly
        />
        {props.imageUrl && (
          <Box
            sx={{
              marginY: 1,
              display: "flex",
            }}
          >
            <img
              src={props.imageUrl}
              alt="Image shared"
              style={{ cursor: "pointer" }}
            />
          </Box>
        )}
        {props.filesUrl &&
          props.filesUrl.map((file, index) => (
            <Box
              key={index}
              sx={{
                marginTop: 1,
                display: "flex",
              }}
            >
              <Link
                href={file.url}
                target="_blank"
                download
                underline="none"
                color="secondary"
                display="flex"
                alignItems="center"
                paddingBottom={3}
              >
                <GetApp /> {`${file.name}`}
              </Link>
            </Box>
          ))}
      </Slate>
    </Box>
  );
};

export default RichtTextReadOnly;
