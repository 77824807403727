import { React, useState } from "react";
import { TitleWrapper, DialogPopup } from "../../utils/styles";
import {
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Divider,
  DialogActions,
} from "@mui/material";
import { AddTrainingForm } from "./AddTrainingForm";
import { AddJobsForm } from "./AddJobsForm";
import { PROFESSION_POPUP_NUMBER } from "../../constants/appConstants";
import CloseIcon from "@mui/icons-material/Close";
import OutlinedButton from "../shared/OutlinedButton";
import ContainedButton from "../shared/ContainedButton";

//This Component is the popup that is in charge of rendering the 3 popups
export const AddProfessionalInfoPopup = ({
  popupName,
  onClose,
  trainingDetailInfo,
  jobsDetailsInfo,
  addFormData,
  popupNumber,
  formToAdd,
}) => {
  const [formData, setFormData] = useState({ title: "" });
  const [errors, setErrors] = useState({});

  const handleSubmit = () => {
    addFormData(formData, formToAdd);
    setFormData({});
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updateValue = value;
    let updateError = { ...errors };
    if (value.length >= 50) {
      updateValue = value.slice(0, 50);
      updateError[name] = "Maximum length exceeded (50 characters)";
    } else {
      updateValue = value;
      delete updateError[name];
    }
    setErrors(updateError);
    setFormData({ ...formData, [name]: updateValue });
  };

  const renderForm = () => {
    switch (popupNumber) {
      case PROFESSION_POPUP_NUMBER.ADD_TRAINING:
        return (
          <AddTrainingForm
            trainingDetailsInfo={trainingDetailInfo}
            handleChange={handleChange}
          />
        );
      case PROFESSION_POPUP_NUMBER.ADD_JOBS:
        return (
          <AddJobsForm
            jobsDetailsInfo={jobsDetailsInfo}
            handleChange={handleChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <DialogPopup open={true} onClose={onClose}>
      <DialogTitle>
        <TitleWrapper>
          {popupName}
          <IconButton
            aria-label="Close confirmation dialog"
            sx={{
              marginLeft: 2,
            }}
            color="primary"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </TitleWrapper>
      </DialogTitle>
      <DialogContent>
        <Grid container>{renderForm()}</Grid>
      </DialogContent>
      <Divider sx={{ marginY: (theme) => theme.spacing(1) }} />
      <DialogActions>
        <OutlinedButton label={"Back"} onClick={onClose} fullWidth={false} />
        <ContainedButton
          type="submit"
          label={"Add"}
          onClick={handleSubmit}
          fullWidth={false}
          disabled={formData["title"] === ""}
        />
      </DialogActions>
    </DialogPopup>
  );
};
