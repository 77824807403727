import React, { useCallback, useMemo } from "react";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
} from "slate";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { showAlert } from "../../store/slices/sharedSlice";
import { SpecialButtons } from "../posts/SpecialButtons";
import { BUCKET_EVENTS_PATH } from "../../constants/appConstants";

const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const RichTextEditor = ({
  message,
  onChange,
  handleOnSuccessUploadImage,
  handleOnSuccessUploadFile,
}) => {
  const dispatch = useDispatch();
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type),
      split: true,
    });
    let newProperties;
    if (LIST_TYPES.includes(format)) {
      newProperties = {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
      };
    }
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const isBlockActive = (editor, format, blockType = "type") => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n[blockType] === format,
      })
    );
    return !!match;
  };

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const Element = ({ attributes, children, element }) => {
    switch (element.type) {
      case "bulleted-list":
        return <ul {...attributes}>{children}</ul>;
      case "list-item":
        return <li {...attributes}>{children}</li>;
      case "numbered-list":
        return <ol {...attributes}>{children}</ol>;
      case "link":
        return (
          <a href={element.href} rel="noreferrer" target="_blank">
            {children}
          </a>
        );
      default:
        return <p {...attributes}>{children}</p>;
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>;
    }
    if (leaf.italic) {
      children = <em>{children}</em>;
    }
    if (leaf.underline) {
      children = <u>{children}</u>;
    }
    return <span {...attributes}>{children}</span>;
  };

  const MarkButton = ({ format, type }) => {
    const editor = useSlate();
    return (
      <SpecialButtons
        type={type}
        active={isMarkActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      />
    );
  };

  const BlockButton = ({ format, type }) => {
    const editor = useSlate();
    return (
      <SpecialButtons
        type={type}
        active={isBlockActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
      />
    );
  };

  const OtherButton = ({ type, format }) => {
    const editor = useSlate();
    return (
      <SpecialButtons
        type={type}
        active={isBlockActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
        handleAddLink={(url) =>
          handleAddLink(isBlockActive(editor, format), url)
        }
        onSuccessUploadImage={handleOnSuccessUploadImage}
        onSuccessUploadFile={handleOnSuccessUploadFile}
        onErrorUpload={handleOnErrorUpload}
        bucketPath={BUCKET_EVENTS_PATH}
      />
    );
  };

  const handleAddLink = (isActive, url) => {
    if (!isActive) {
      if (url) {
        const link = {
          type: "link",
          href: url,
          children: [{ text: url }],
        };
        Transforms.insertNodes(editor, link);
      }
    } else {
      //For removing the link node
      Transforms.setNodes(editor, { type: "paragraph" });
    }
  };

  const handleOnErrorUpload = (error) => {
    dispatch(
      showAlert({
        message:
          "An error ocurred while uploading your file. Please, try again.",
        severity: "error",
      })
    );
  };

  return (
    <Box
      sx={{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        "& p, a, ul, ol, span ": {
          mb: 0,
          mt: 0,
          mr: 0,
          ml: 0,
          fontSize: "1rem",
          lineHeight: "1.2",
        },
        "& ol, ul": {
          paddingLeft: 4,
        },
      }}
    >
      <Slate editor={editor} value={message} onChange={onChange}>
        <Editable
          placeholder="Description..."
          style={{
            maxHeight: "40vh",
            minHeight: "12vh",
            overflow: "clip",
            border: "1px solid #aaa",
            borderRadius:"5px",
            color: "#000",
            marginTop: "1rem",
            padding: "0.5rem",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
            width:"60vw"
            
          }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
        />
        <Box sx={{ display: "flex", marginBottom: 3, alignItems:"flex-start", width:"60vw" }}>
          <Box sx={{ marginRight: "auto", "& button": { marginRight: 0.3 } }}>
            <MarkButton format="bold" type="bold" />
            <MarkButton format="italic" type="italic" />
            <MarkButton format="underline" type="underline" />
            <BlockButton format="numbered-list" type="number" />
            <BlockButton format="bulleted-list" type="bullet" />
            <OtherButton format="link" type="link" />
            <OtherButton format="image" type="image" />
            <OtherButton format="file" type="file" />
          </Box>
        </Box>
      </Slate>
    </Box>
  );
};
