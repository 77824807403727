import { Box, Button, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import EventCardMapping from "./EventCardMapping";
import { useDispatch, useSelector } from "react-redux";
import { APP_ROLES } from "../../constants/appConstants";
import CreateEventForm from "./CreateEventForm";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { EventApi } from "../../apis/EventApi";
import TextButton from "../shared/TextButton";
import { useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const EventsContainer = () => {
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const [creatingEvent, setCreatingEvent] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [pagination, setPagination] = useState(1);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState({});
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const event = searchParams.get("eventNumber");

  useEffect(() => {
    dispatch(toggleLoader({ isLoading: true }));

    if (event) {
      EventApi.getSingleEvent(event)
      .then((response) => {
        setEventList([response.data]);
      })
      .catch((err) => {
        console.error(err);
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
          showAlert({
            message:
              "An error ocurred while fetching the event. Please, try again." +
              err.message,
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(toggleLoader({ isLoading: false }));
      });
    } else {
      EventApi.getAllEvents(lastEvaluatedKey)
      .then((response) => {        
        eventList.length !==0
          ? setEventList((prev) => [response.data.items, ...prev])
          : setEventList(response.data.items);

        if (response.LastEvaluatedKey) {
          setLastEvaluatedKey(response.data.LastEvaluatedKey);
        } else {
          setLastEvaluatedKey({});
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
          showAlert({
            message:
              "An error ocurred while fetching the events. Please, try again." +
              err.message,
            severity: "error",
          })
        );
      })
      .finally(() => {
        dispatch(toggleLoader({ isLoading: false }));
      });
    }
  }, [pagination, event]); //eslint-disable-line

  const handleBack = () => {
    if (event) {
      searchParams.delete("eventNumber");
      setSearchParams(searchParams);
      setEventList([])
    }
  }

  const handlePagination = () => {
    if (Object.keys(lastEvaluatedKey).length !== 0) {
      setPagination(pagination + 1);
    }
  };

  const handleEventUpdate = (eventToUpdate) => {
    const updateList = eventList.map((ev) =>
    {
      if(ev.eventNumber === eventToUpdate.eventNumber) 
        return {...ev, ...eventToUpdate, message: eventToUpdate.message, test: 'test'};
      else
        return ev;
    });   
    setEventList(updateList);  
    //navigate('my-feeds?tab=events');
  }

  const handleDelete = (eventNumber) => {
    setEventList((prev) => prev.filter((event) => event.eventNumber !== eventNumber));
  }
  
  return (
    <Box>
      {userLoggedIn.profileType === APP_ROLES.SCHOOL_ADMIN && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "1rem",
            }}
          >
            <Typography variant="h6" fontWeight={"bold"}>
              Create new event
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                setCreatingEvent(true);
              }}
            >
              Create
            </Button>
          </Box>
          <Divider/>
        </>
      )}
      <CreateEventForm
        open={creatingEvent}
        onClose={() => {
          setCreatingEvent(false);
        }}        
        setEventList={setEventList}
      />
      {event && (
        <Tooltip title="Back">
          <IconButton sx={{marginTop: 1.5}} onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      )}
      <EventCardMapping eventList={eventList} handleEventEdit={handleEventUpdate} handleDelete={handleDelete} />
      <Box sx={{ textAlign: "center" }}>
        <TextButton
          label={"Load more"}
          onClick={handlePagination}
          disabled={Object.keys(lastEvaluatedKey).length === 0}
        >
          Load more
        </TextButton>
      </Box>
    </Box>
  );
};

export default EventsContainer;
