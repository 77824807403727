import { React, useState } from "react";
import {
  DialogPopup,
  AccountTypeSection,
  TitleWrapper,
  FormContainer,
} from "../../utils/styles";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Divider,
  Box,
} from "@mui/material";
import ChildForm from "./ChildForm";
import { AdminAndSupportersForm } from "./AdminAndSupportersForm";
import { v4 as uuidv4 } from "uuid";
import { APP_ROLES } from "../../constants/appConstants";
import { UserProfileApi } from "../../apis/UserProfileApi";
import { AlumniApi } from "../../apis/AlumniApi";
import { useDispatch } from "react-redux";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import ConfirmDialogBox from "../shared/ConfirmDialogBox";
import { MessagingApi } from "../../apis/MessagingApi";
import CloseIcon from "@mui/icons-material/Close";
import OutlinedButton from "../shared/OutlinedButton";
import ContainedButton from "../shared/ContainedButton";
import { UserPoolApi } from "../../apis/UserPoolApi";
import { NotificationApi } from "../../apis/NotificationApi";

export const CreateProfile = ({ open, onClose }) => {
  const [accountType, setAccountType] = useState(APP_ROLES.PARENTS);
  const [formData, setFormData] = useState({});
  const [children, setChildren] = useState([{}]);
  const dispatch = useDispatch();
  const [confirmAdmin, setConfirmAdmin] = useState(false);
  const [errors, setErrors] = useState({});
  const [childErrors, setChildErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(toggleLoader({ isLoading: true }));
    try {
      if (accountType !== APP_ROLES.PARENTS) {
        let cohort = formData.cohort;
        await UserPoolApi.adminCreateUserCognito(formData, accountType).then(
          async (response) => {            
            if(accountType == APP_ROLES.ALUMNI)
            {
              formData.cohort = cohort;
            }
            formData.accountType = accountType;            
            UserProfileApi.postUsers(formData, response.data.userName);

            ///Register user as Customer in Amazon Pinpoint
            NotificationApi.updateEndpoint(formData.email, response.data.userName, formData.given_name, formData.family_name, accountType);

            //Create same user profile in Participant table
            const participant = {
              id: response.data.userName,
              firstName: formData.given_name,
              lastName: formData.family_name,
              isActive: true,
              isSchoolAdmin: APP_ROLES.SCHOOL_ADMIN === accountType,
            };

            MessagingApi.createParticipant(participant);
          }
        );
      } else {
        await UserPoolApi.adminCreateUserCognito(formData, accountType).then(
          async (response) => {
            ///Register Parent as Customer in Amazon Pinpoint
            NotificationApi.updateEndpoint(formData.email, response.data.userName, formData.given_name, formData.family_name, accountType);

            await UserProfileApi.postUserAlumni(children, formData);

            await AlumniApi.postAlumniParentProfile(
              children,
              response.data.userName
            );

            children.forEach((child) => {
              const participant = {
                id: child.alumniId,
                firstName: child.given_name,
                lastName: child.family_name,
                isActive: true,
                isSchoolAdmin: false,
              };

              //Create same user profile in Participant table
              MessagingApi.createParticipant(participant);

              ///Register child as Customer in Amazon Pinpoint
              NotificationApi.updateEndpoint(child.email, child.alumniId, child.given_name, child.family_name, child.accountType);
            });
          }
        );
      }
    } catch (error) {
      dispatch(
        showAlert({
          message: `${error}`,
          severity: "error",
        })
      );
      dispatch(toggleLoader({ isLoading: false }));
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
      closeForm();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updateValue = value;
    let updateError = { ...errors };
    if (name === "email" && value.length > 320) {
      updateValue = value.slice(0, 320);
      updateError[name] = "Maximum length exceeded (320 characters)";
    }
    if (value.length >= 50) {
      updateValue = value.slice(0, 50);
      updateError[name] = "Maximum length exceeded (50 characters)";
    } else {
      updateValue = value;
      delete updateError[name];
    }
    setErrors(updateError);
    setFormData({ ...formData, [name]: updateValue });
  };

  const handleChildChange = (e, index) => {
    const { name, value } = e.target;
    const newChildren = [...children];
    let updateValue = value;
    let updateError = { ...childErrors };
    if (name === "email" && value.length > 320) {
      updateValue = value.slice(0, 50);
      updateError[name] = "Maximum length exceeded (320 characters)";
    }
    if (value.length >= 50) {
      updateValue = value.slice(0, 50);
      updateError[name] = "Maximum length exceeded (50 characters)";
    } else {
      updateValue = value;
      delete updateError[name];
    }
    setChildErrors(updateError);
    newChildren[index].alumniId = uuidv4();
    newChildren[index][name] = updateValue;
    newChildren[index].accountType = APP_ROLES.ALUMNI;
    setChildren(newChildren);
  };

  const addChild = () => {
    setChildren([...children, {}]);
  };

  const removeChild = (index) => {
    const newChildren = [...children];
    newChildren.splice(index, 1);
    setChildren(newChildren);
  };

  const closeForm = () => {
    setFormData({});
    setChildren([{}]);
    onClose();
    setAccountType(APP_ROLES.PARENTS)
  };

  const cancelCreateAdmin = () => {
    setConfirmAdmin(false);
    setAccountType(APP_ROLES.PARENTS);
  };

  const okCreateAdmin = () => {
    setConfirmAdmin(false);
  };

  const handleAccountTypeChange = (value) => {
    if (value === APP_ROLES.SCHOOL_ADMIN) {
      setConfirmAdmin(true);
    }
    setAccountType(value);
  };

  function renderForm() {
    switch (accountType) {
      case APP_ROLES.PARENTS:
        return (
          <>
            <AdminAndSupportersForm onChange={handleChange} errors={errors} />
            {children.map((child, index) => (
              <ChildForm
                key={`${child}${index}`}
                index={index}
                onChange={(e) => handleChildChange(e, index)}
                onClick={removeChild}
                errors={childErrors}
              />
            ))}
          </>
        );
      case APP_ROLES.ALUMNI:
        return <ChildForm onChange={handleChange} errors={childErrors} index={0}/>;
      case APP_ROLES.SUPPORTERS:
        return (
          <AdminAndSupportersForm onChange={handleChange} errors={errors} />
        );
      case APP_ROLES.SCHOOL_ADMIN:
        return (
          <>
            <ConfirmDialogBox
              open={confirmAdmin}
              title={"You are about to create an ADMIN"}
              message={
                "Be aware that you are about to create a School Admin account with admin access."
              }
              okText={"Ok"}
              cancelText={"Cancel"}
              handleClose={cancelCreateAdmin}
              handleOk={okCreateAdmin}
            />
            <AdminAndSupportersForm onChange={handleChange} errors={errors}  />
          </>
        );
      default:
        break;
    }
  }

  const isFormValid = () => {
    const requiredFields = ["given_name", "family_name", "email", "birthdate"];
    const validFields = requiredFields.some((field) => !formData[field] || formData[field].length > 50);
    const hasErrors = Object.keys(errors).length > 0;
    const hasChildErrors = Object.keys(childErrors).length > 0;
    return !validFields && !hasErrors && !hasChildErrors;
  };

  return (
    <DialogPopup open={open} onClose={closeForm}>
      <DialogTitle>
        <TitleWrapper>
          Create New Profile
          <IconButton
            aria-label="Close confirmation dialog"
            sx={{
              marginLeft: 2,
            }}
            color="primary"
            onClick={closeForm}
          >
            <CloseIcon />
          </IconButton>
        </TitleWrapper>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <AccountTypeSection>
            <InputLabel id="accountTypeLabel">Type of Account</InputLabel>
            <Select
              autoFocus
              labelId="accountTypeLabel"
              id="accountType"
              name="accountType"
              variant="outlined"
              size="medium"
              value={accountType}
              onChange={(e) => handleAccountTypeChange(e.target.value)}
            >
              <MenuItem value={APP_ROLES.SCHOOL_ADMIN}>School admin</MenuItem>
              <MenuItem value={APP_ROLES.PARENTS}>Parent</MenuItem>
              <MenuItem value={APP_ROLES.ALUMNI}>Alumni</MenuItem>
              <MenuItem value={APP_ROLES.SUPPORTERS}>Supporters</MenuItem>
            </Select>
          </AccountTypeSection>
          <FormContainer>{renderForm()}</FormContainer>
          {accountType === APP_ROLES.PARENTS && (
            <Box sx={{ marginTop: 2 }}>
              <OutlinedButton label={"+ Add one more child"} onClick={addChild} fullWidth={false} />
            </Box>
          )}
        </DialogContent>
        <Divider sx={{ marginY: (theme) => theme.spacing(1) }} />
        <DialogActions>
          <OutlinedButton
            type="button"
            label={"Back"}
            onClick={closeForm}
            fullWidth={false}
          />
          <ContainedButton type="submit" label={"Create"} disabled={!isFormValid()} fullWidth={false} />
        </DialogActions>
      </form>
    </DialogPopup>
  );
};
