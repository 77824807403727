import { List, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { MessagingApi } from '../../apis/MessagingApi';
import { storeAdminParticipants } from '../../store/slices/messagingSlice';
import { showAlert } from '../../store/slices/sharedSlice';
import { attributesToFilterChatsFrom } from '../../utils/utils';
import ChatListItemAdmin from './ChatListItemAdmin';
import ChatListSkeleton from './ChatListSkeleton';

function ChatWithAdmins() {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
    const adminParticipants = useSelector((state) => state.messaging.adminParticipants);
    const searchInput = useSelector((state) => state.messaging.searchInputChat);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // There will be, at least, one SchoolAdmin to chat with. 
        // So, if length is greater than zero, then it means we have already retrieve all chats with admins. Skip logic.
        if (adminParticipants.items.length > 0) return;

        setIsLoading(true)

        MessagingApi.listAdminParticipants()
            .then((result) => {
                dispatch(storeAdminParticipants(result.data.listParticipants?.items));
            })
            .catch((err) => {
                dispatch(showAlert({ message: "Could not retrieve all Admins. Please, try again." + err.message, severity: "error" }));
            })
            .finally(() => {
                setIsLoading(false)
            });
    }, []);

    const noAdminsFound = <Typography variant='body1' align='center' paddingY={2}>No Admins have been found.</Typography>;

    const filterChats = () => {
        if (adminParticipants.items.length === 0) return noAdminsFound;

        //TODO: add logic to narrow down results
        const _searchInput = Array.of(...searchInput.split(" ")).join("#").toString().toLocaleUpperCase();

        /// in case of userLoggedIn is belongs to SchoolAdmin user group, remove it from results
        const results = adminParticipants.items.filter(ap => ap.id !== userLoggedIn.userName && attributesToFilterChatsFrom(ap).includes(_searchInput));

        if (results.length === 0) return noAdminsFound;

        return results.map(item => <ChatListItemAdmin key={item.id} adminParticipant={item} />);
    };

    const adminList = useMemo(() => filterChats(), [adminParticipants.items, searchInput]);

    if (isLoading) return (
        <ChatListSkeleton />
    );

    return (
        <List sx={{
            overflow: 'auto',
            maxHeight: "90%"
        }}>
            {adminList}
        </List>
    )
}

export default ChatWithAdmins;