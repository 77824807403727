import { API } from "aws-amplify";
import events_mock from '../mocks/api/events_mock.json';

const EVENT_API_NAME = "event";

export const EventApi = {
  /**
   * List all events
   * @param {string} [] - .
   * @returns {Array} Events are returned as an array of events
   */
  getAllEvents: async (lastEvaluatedKey) => {
    if (process.env.NODE_ENV === "development") return events_mock;
    
      const init = {
        headers: {},
        queryStringParameters: {},
        response: true,
      };

      if (lastEvaluatedKey && Object.keys(lastEvaluatedKey).length !== 0) {
        init.queryStringParameters.lastEvaluatedKey =
          JSON.stringify(lastEvaluatedKey);
      }
 
      return API.get(EVENT_API_NAME, "/event", init);
  },

  getSingleEvent: async (eventNumber) => {
    
      const init = {
        headers: {},
        response: true,
      };

      return API.get(EVENT_API_NAME, `/event/event/${eventNumber}`, init);
  },

  postEvent: async (eventData) => {
    if (process.env.NODE_ENV === "development") return;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
      body: eventData,
    };

    return API.post(EVENT_API_NAME, "/event", init);
  },

  updateEvent: async (eventData) => {
    if (process.env.NODE_ENV === "development") return;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
      body: eventData,
    };

    return API.patch(EVENT_API_NAME, "/event", init);
  },

  deleteEvent: async (eventId, eventNumber) => {
    if (process.env.NODE_ENV === "development") return;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    return API.del(EVENT_API_NAME, `/event/${eventId}/${eventNumber}`, init);
  },
};
