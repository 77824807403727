/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "3fa96467e45546c3a77b60ebf655d034",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "3fa96467e45546c3a77b60ebf655d034",
            "region": "us-east-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "alumni",
            "endpoint": "https://7ccvupg3he.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "alumninetwork",
            "endpoint": "https://x9kjmjdq5l.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "announcement",
            "endpoint": "https://nhng1h6ap4.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "event",
            "endpoint": "https://zd7z9y3co1.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "notification",
            "endpoint": "https://9uk2bt3kvg.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "userpool",
            "endpoint": "https://0f2lzj36t6.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        },
        {
            "name": "userprofile",
            "endpoint": "https://p4ilbkaww4.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://yzmbt5vbcna3pjmkumtqxu75my.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:28714125-66cc-49e1-a76c-ec65cfef7d1a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_nXfzgRCEo",
    "aws_user_pools_web_client_id": "5aeip1aqjl9l9qui39sq1017j5",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "BIRTHDATE",
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "AlumniNetworkDb-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Announcements-main",
            "region": "us-east-1"
        },
        {
            "tableName": "Events-main",
            "region": "us-east-1"
        },
        {
            "tableName": "ParentsAlumniRel-main",
            "region": "us-east-1"
        },
        {
            "tableName": "UserProfilesDb-main",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "bwssisdev076b4b2d0eec44f88091db05238941e9123428-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
