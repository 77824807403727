import React from "react";
import {
  FormContainer,
  InputField,
} from "../../utils/styles";
import { YEARS_AT_BWS } from "../../constants/appConstants";
import { FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

function ChildForm({ onChange, onClick, index, errors }) {
  return (
    <FormContainer>
      <InputField
        id="given_name"
        name="given_name"
        label="First Name"
        type="text"
        variant="outlined"
        size="medium"
        onChange={onChange}
        error={!!errors.given_name}
        helperText={errors.given_name}
        required
      />
      <InputField
        id="family_name"
        name="family_name"
        label="Last Name"
        type="text"
        variant="outlined"
        size="medium"
        onChange={onChange}
        error={!!errors.family_name}
        helperText={errors.family_name}
        required
      />
      <InputField
        id="email"
        name="email"
        label="Email Address"
        type="email"
        variant="outlined"
        size="medium"
        onChange={onChange}
        error={!!errors.email}
        helperText={errors.email}
        required
      />
      <FormControl sx={{width: "15vw", marginRight: "27px"}}>
        <InputLabel id="label_cohort" required={true}>Cohort</InputLabel>
        <Select
          id="cohort"
          name="cohort"
          labelId="label_cohort"
          label="cohort"
          size="medium"
          onChange={onChange}
        >
        {YEARS_AT_BWS?.map((menu, index) => (
            <MenuItem
              key={index}
              value={menu.value}
            >
              {menu.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <InputField
        id="birthdate"
        name="birthdate"
        label="Date of Birth"
        type="Date"
        variant="outlined"
        size="medium"
        onChange={onChange}
        InputLabelProps={{ shrink: true }}
        error={!!errors.birthdate}
        helperText={errors.birthdate}
        required
      />
      {index !== 0 && <IconButton onClick={onClick}><CloseIcon /></IconButton>}
    </FormContainer>
  );
}

export default ChildForm;
