import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideAlert } from '../../store/slices/sharedSlice';

/**
 * An Alert with Snackbar's look-and-feel to give end user more context or information.
 * 
 * @param {string} [vertical] Vertical aligment of the snackbar. Possible values: `top` or `bottom`. Defaults to `bottom`.
 * @param {string} horizontal Horizontal aligment of the snackbar. Possible values: `right`, `center` or `left`. Defaults to `center`.
 * @param {string} severity The alert offers four severity levels that set a distinctive icon and color. Possible values: `error | warning | info | success`. Defaults to `info`.
 * @param {string} message Message to be included in the alert.
 * @returns `AlertSnackbar`
 */
const AlertSnackbar = ({ }) => {
    const dispatch = useDispatch();
    const alertProps = useSelector((state) => state.shared.alertSnackbar);
    const { vertical: vertical, horizontal: horizontal, isOpen: isOpen } = alertProps;
    
    const handleClose = (event, reason) => {
        ///after autoHideDuration value, an event will be fired with reason === 'timeout'
        ///so, it will automatically close the snackbar.
        if (reason === 'clickaway') {
            return;
        }

        dispatch(hideAlert());
    };

    return (
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={isOpen} autoHideDuration={alertProps.hideAfter} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alertProps.severity} sx={{ width: '100%' }}>
                {alertProps.message}
            </Alert>
        </Snackbar>
    );
};

export default AlertSnackbar;