import Box from "@mui/material/Box";
import { useTheme } from "@mui/system";

const AvatarContainer = ({children}) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                backgroundColor: `${theme.palette.secondary.main}`,
                padding: `${theme.spacing('1rem')} ${theme.spacing('3.0rem')}`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "nowrap",
                gap: "1rem"
            }}
        >
            {children}
        </Box>
    );
};

export default AvatarContainer;