import { Box, CardContent } from "@mui/material";
import { useState } from "react";
import CardRaisedRounded from "../shared/CardRaisedRounded";
import SecondaryTabs from "../shared/SecondaryTabs";
import TabPanel from "../shared/TabPanel";
import { EducationInformationTab } from "../educationInformation/EducationInformationTab";
import { ProfessionalInformationTab } from "../professionalInformation/ProfessionalInformationTab";
import PersonalInfoTab from "../personalInformation/PersonalInfoTab";
import { useSelector } from "react-redux";
import { PersonalInfoTabPublic } from "../personalInformation/PersonalInfoTabPublic";
import { useParams } from "react-router-dom";

const ProfileInformationContent = ({}) => {
  const [activeTab, setActiveTab] = useState(0);
  const loggedInUserInfo = useSelector((state) => state.user);
  let { id } = useParams();
  const piTabs = [
    {
      key: "personal-info-tab",
      value: 0, //used to show or hide tab panel
      label: "Personal Information",
    },
    {
      key: "education-info-tab",
      value: 1,
      label: "Education Information",
    },
    {
      key: "professional-info-tab",
      value: 2,
      label: "Professional Information",
    },
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <CardRaisedRounded>
        <CardContent sx={{ paddingY: (theme) => theme.spacing(0) }}>
          <Box sx={{ width: "100%" }}>
            <SecondaryTabs
              tabs={piTabs}
              tabSelected={activeTab}
              handleChange={handleTabChange}
            />

            <TabPanel
              value={activeTab}
              index={0}
              ishidden={piTabs[0].value !== activeTab}
            >
              {loggedInUserInfo.hasAdminAccess || !id ? <PersonalInfoTab/> : <PersonalInfoTabPublic/>}
            </TabPanel>
            <TabPanel
              value={activeTab}
              index={1}
              ishidden={piTabs[1].value !== activeTab}
            >
              <EducationInformationTab />
            </TabPanel>
            <TabPanel
              value={activeTab}
              index={2}
              ishidden={piTabs[2].value !== activeTab}
            >
              <ProfessionalInformationTab />
            </TabPanel>
          </Box>
        </CardContent>
      </CardRaisedRounded>
    </>
  );
};

export default ProfileInformationContent;
