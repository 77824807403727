import { useTheme, View, Image, Heading, useAuthenticator, Button } from '@aws-amplify/ui-react';
import styled from 'styled-components';
import bishop_logo from '../assets/bws_login_logo.png'

const BwsLogo = styled(Image)`
 width:30%;
 height:30%;
`;

const StyledView = styled(View)`
    background-color: var(--amplify-components-authenticator-router-background-color);
    box-shadow: var(--amplify-components-authenticator-router-box-shadow);
    border-color: var(--amplify-components-authenticator-router-border-color);
    border-width: var(--amplify-components-authenticator-router-border-width);
    border-style: var(--amplify-components-authenticator-router-border-style);
`;

///source: https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#headers--footers

///    possible future components configurations:
///        - ResetPassword
///        - ConfirmResetPassword
export const authenticatorComponents = {
    Header() {
        const { tokens } = useTheme();

        return (
            <StyledView textAlign="center" padding={tokens.space.small}>
                <BwsLogo
                    alt="Bishop Walker School logo"
                    height="35%"
                    width="35%"
                    src={bishop_logo}
                />
            </StyledView>
        );
    },

    SignIn: {
        Header() {
            const { tokens } = useTheme();

            return (
                <Heading
                    textAlign="center"
                    padding={`${tokens.space.small} 0 0 0`}
                    level={3}
                    fontSize={18}
                >
                    LOGIN
                </Heading>
            );
        },
        Footer() {
            const { toResetPassword } = useAuthenticator();
            const { tokens } = useTheme();

            return (
                <View textAlign="center" padding={`0 0 ${tokens.space.xxxl} 0`}>
                    <Button
                        fontWeight="normal"
                        onClick={toResetPassword}
                        size="medium"
                        variation="link"
                    >
                        Forgot Password?
                    </Button>
                </View>
            );
        },
    },
};


///     possible future formFields configuration:
///         - forceNewPassword
///         - resetPassword
///         - confirmResetPassword
export const authenticatorFormFields = {
    signIn: {
        username: {
            placeholder: 'Enter your e-mail',
            isRequired: true,
            label: 'Email'
        },
        password: {
            placeholder: 'Enter your password',
            isRequired: true,
            label: 'Password'
        },
    },
};