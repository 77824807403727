import { Box, IconButton, Tooltip } from "@mui/material";
import {
  Send,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  Close,
} from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { createEditor, Editor, Text, Transforms } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import { MessagingApi } from "../../apis/MessagingApi";
import { useDispatch, useSelector } from "react-redux";
import TextButtonIcon from "../shared/TextButtonIcon";
import ShareImageUpload from "../shared/ShareImageUpload";
import ShareFileUpload from "../shared/ShareFileUpload";
import { showAlert } from "../../store/slices/sharedSlice";
import { BUCKET_MESSAGING_FILES_PATH, NOTIFICATION_TOPICS } from "../../constants/appConstants";
import { NotificationApi } from "../../apis/NotificationApi";
import TextButton from "../shared/TextButton";

function Chatbox({ userLoggedIn }) {
  const editor = useMemo(() => withReact(createEditor()), []);
  const [message, setMessage] = useState([{ children: [{ text: "" }] }]);
  const [messageFormatted, setMessageFormatted] = useState({});
  const activeChat = useSelector((state) => state.messaging.activeChat);
  const dispatch = useDispatch();
  const [imageS3Key, setImageS3Key] = useState();
  const [fileS3Key, setFileS3Key] = useState();
  const chatParticipants = useSelector((state) => state.messaging.activeChatParticipants);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    await MessagingApi.sendMessage(messageFormatted);
    setMessage([{ children: [{ text: "" }] }]);
    Transforms.select(editor, Editor.range(editor, []));
    editor.removeNodes(editor, { at: [], voids: true });
    Transforms.insertNodes(editor, [{ children: [{ text: "" }] }]);
    setImageS3Key();
    setFileS3Key();

    NotificationApi.createNotification(
      "New Message!",
      `you have a new message from ${userLoggedIn.firstName} ${userLoggedIn.lastName}`,
      "/my-feed?tab=messages",
      NOTIFICATION_TOPICS.MESSAGE,
      searchPersonId()
    )
      .then((data) => {})
      .catch((err) => console.log(err));
  };

  //Looks for the id of the other person of the chat
  const searchPersonId = () => {
    const otherParticipant = chatParticipants.participants.filter(
      (participant) => participant.participant.id !== userLoggedIn.userName
    );
    return otherParticipant[0].participant.id;
  };

  useEffect(() => {
    setMessageFormatted({
      chatId: activeChat.chatId,
      content: {
        text: JSON.stringify(message),
        image: imageS3Key ? { key: imageS3Key } : null,
        attachments: fileS3Key ? fileS3Key : null,
      },
      messageSenderId: userLoggedIn.userName,
    });
  }, [message, imageS3Key, fileS3Key]);

  const handleOnSuccessUploadImage = ({ key }) => {
    setImageS3Key(key);
    dispatch(
      showAlert({
        message: "Your image was uploaded successfully",
        severity: "success",
      })
    );
  };

  const handleOnSuccessUploadFile = ({ key }) => {
    fileS3Key ? setFileS3Key([...fileS3Key, { key: key }]) : setFileS3Key([{ key: key }]);
    dispatch(
      showAlert({
        message: "Your file was uploaded successfully",
        severity: "success",
      })
    );
  };

  const handleOnErrorUpload = (error) => {
    dispatch(
      showAlert({
        message: "An error ocurred while uploading your profile picture. Please, try again.",
        severity: "error",
      })
    );
  };

  const handleTextFormat = (format) => {
    const [match] = Editor.nodes(editor, {
      match: (node) => node[format] === true,
      universal: true,
    });
    const newFormat = {};
    newFormat[format] = match ? false : true;
    Transforms.setNodes(editor, newFormat, {
      match: (node) => Text.isText(node),
      split: true,
    });
  };

  const Leaf = (props) => {
    return (
      <span
        {...props.attributes}
        style={{
          fontWeight: props.leaf.bold ? "bold" : "normal",
          fontStyle: props.leaf.italic ? "italic" : "normal",
          textDecoration: props.leaf.underline ? "underline" : "none",
        }}
      >
        {props.children}
      </span>
    );
  };

  return (
    <>
      <Box sx={{ p: 1 }}>
        <Box
          sx={{
            alignItems: "center",
            p: 2,
            mb: 2,
            borderBottom: "2px solid #590006",
          }}
        >
          <Slate editor={editor} value={message} onChange={(newMessage) => setMessage(newMessage)}>
            <Editable placeholder="Type your message..." renderLeaf={(props) => <Leaf {...props} />} />
          </Slate>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => handleTextFormat("bold")}>
            <Tooltip title="Bold">
              <FormatBold />
            </Tooltip>
          </IconButton>
          <Tooltip title="Italic">
            <IconButton onClick={() => handleTextFormat("italic")}>
              <FormatItalic />
            </IconButton>
          </Tooltip>
          <Tooltip title="Underline">
            <IconButton onClick={() => handleTextFormat("underline")}>
              <FormatUnderlined />
            </IconButton>
          </Tooltip>
          <ShareImageUpload
            onSuccessUpload={handleOnSuccessUploadImage}
            onErrorUpload={handleOnErrorUpload}
            bucketPath={BUCKET_MESSAGING_FILES_PATH}
          />
          <ShareFileUpload
            onSuccessUpload={handleOnSuccessUploadFile}
            onErrorUpload={handleOnErrorUpload}
            bucketPath={BUCKET_MESSAGING_FILES_PATH}
          />
          <Box sx={{ marginLeft: "auto" }}>
            <TextButtonIcon onClick={handleSendMessage} color="secondary" label="Send" icon={<Send />} />
          </Box>
        </Box>
        {imageS3Key && (
            <TextButton
              label={`Sharing "${imageS3Key.split("_")[0].slice(10)}"`}
              endIcon={<Close />}
              onClick={() => setImageS3Key(null)}
            />
          )}
          {fileS3Key &&
            fileS3Key.map((file, index) => (
              <TextButton
                key={index}
                label={`Sharing "${file?.key.split("_")[0].slice(10)}"`}
                endIcon={<Close />}
                onClick={() => setFileS3Key([])}
              />
            ))} 
      </Box>
    </>
  );
}

export default Chatbox;
