import React from "react";
import { Grid } from "@mui/material";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import TypographyPersonalInfoField from "../shared/TypographyPersonalInfoField";
import TypographyPersonalInfoFieldTitle from "../shared/TypographyPersonalInfoFieldTitle";

export const CertificationDetails = ({ arrayData }) => {
  return (
    <>
      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            CERTIFICATION NAME
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.certificationName}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            ISSUED BY
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.issuedBy}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            ISSUED DATE
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.issuedDate}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>

        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            EXPIRY DATE
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.expiryDate}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>
    </>
  );
};
