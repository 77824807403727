import { Box } from "@mui/material";
import PropTypes from "prop-types";

const TabPanel = (props) => {
  const { children, value, index, ishidden, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={ishidden}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {!ishidden && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanel;
