import React from "react";
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router-dom";
import GuardedRoute from "./GuardedRoute";
import { useSelector } from "react-redux";
import LandingPage from "../components/landingPage/LandingPage";
import UserProfileDetails from "../components/search/schoolAdmin/UserProfileDetails";
import { APP_ROUTES } from "../constants/appConstants";

const RouteConfig = ({ signOut }) => {
  const loggedInUser = useSelector((state) => state.user);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/:activeTab?"
          element={<LandingPage signOut={signOut} />}
        />
        {/* add authorization logic for 'authorized' attribute */}
        <Route
          path={`${APP_ROUTES.PROFILE_DETAILS}/:id`}
          element={
            <GuardedRoute
              authorized={loggedInUser.authorized || true}
              Component={<UserProfileDetails signOut={signOut} />}
            />
          }
        />
      </>
    ), {
    basename: "", //here, we'll able to set the root path based on the environment we are deploying our web app
  });

  return (
    <RouterProvider router={router} />
  );
};

export default RouteConfig;
