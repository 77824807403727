export const appErrorMessages = {
  UnAuthorizedMsg: "You are not authorized to access this screen.",
};

export const APP_ROLES = {
  SCHOOL_ADMIN_SUPER_USER: "SchoolAdminSuperUser",
  SCHOOL_ADMIN: "SchoolAdmin",
  PARENTS: "Parents",
  SUPPORTERS: "Supporters",
  ALUMNI: "Alumni",
};

export const APP_GENDERS = {
  MALE: "Male",
  FEMALE: "Female",
};

export const APP_ROUTES = {
  MY_FEED: "/my-feed",
  PROFILE_INFORMATION: "/profile-information",
  SEARCH_PROFILE: "/search-profile",
  MY_NETWORK: "/my-network",
  PROFILE_DETAILS: "/profile",
};

export const ProfilePopupNumber = {
  addSchool: 1,
  addCollege: 2,
  AddCertification: 3,
};

export const PROFESSION_POPUP_NUMBER = {
  ADD_TRAINING: 1,
  ADD_JOBS: 2,
};

export const CONNECTION_STATUS = {
  CONNECTED: "Connected",
  REQUESTSENT: "Request Sent",
  REQUESTPENDING: "Request Pending",
  REJECTED: "Rejected",
  DISCONNECTED: "Disconnected",
  NOTCONNECTED: "NOTCONNECTED",
};

export const TYPE_OF_SCHOOL = [
  { name: "N/A", value: "N/A" },
  { name: "Episcopal", value: "Episcopal" },
  { name: "Catholic", value: "Catholic" },
  {
    name: "Non-religious affiliated",
    value: "Non-religious affiliated",
  },
  { name: "Boarding", value: "Boarding" },
];

export const SCHOOL_LEVEL = [
  { name: "N/A", value: "N/A" },
  { name: "Elementary", value: "Elementary" },
  { name: "Middle", value: "Middle" },
  { name: "High school", value: "High school" },
  { name: "Higher degrees", value: "Higher degrees" },
];

export const GENDER = [
  { name: "N/A", value: "N/A" },
  { name: "Male", value: "Male" },
  { name: "Female", value: "Female" },
];

const yearsArray = [];

for (let year = 1999; year <= new Date().getFullYear(); year++) {
  const yearObject = {
    name: year.toString(),
    value: year,
  };
  yearsArray.push(yearObject);
}

export const YEARS_AT_BWS = yearsArray;

export const EVENT_TYPE = {
  ONLINE: "Online",
  INPERSON: "In-Person"
}

export const BUCKET_PROFILE_PICTURES_PATH = "profile-pictures/";

export const BUCKET_MESSAGING_FILES_PATH = "messaging/";

export const BUCKET_ANNOUNCEMENTS_PATH = "announcements/";

export const BUCKET_EVENTS_PATH = "events/";

export const NOTIFICATION_TOPICS = {
  ADMINPOST: "AdminPost",
  ADMINEVENT: "AdminEvent",
  NETWORK: "Network",
  MESSAGE: "Message",
};

export const PIVOT_USER_DETAILS = {
  INTERNSHIPS: "internships",
  JOBS: "jobs",
  SCHOOLS: "schools",
  COLLEGES: "colleges",
  CERTIFICATIONS: "certifications",
};

export const PIVOT_SPECIAL_CHAR = "|";