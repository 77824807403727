import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userProfiles: [],
    userProfilesFiltered: [],
    pageLastKey: [
        { page: 0, lastKey: null, }
    ],
    page: 1,
    pageCount: 1,
    lastEvaluatedKey: undefined,
};

const SearchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        pushPageLastKey: (state, action) => {
            return {
                ...state,
                pageLastKey: [...state.pageLastKey, action.payload]
            }
        },
        clearUseProfiles: (state, action) => {
            return {
                ...state,
                userProfiles: initialState.userProfiles,
                userProfilesFiltered: initialState.userProfilesFiltered,
            }
        },
        clearPageLastKey: (state, action) => {
            return {
                ...state,
                pageLastKey: initialState.pageLastKey
            }
        },
        setPage: (state, action) => {
            return {
                ...state,
                page: action.payload
            }
        },
        setPageCount: (state, action) => {
            return {
                ...state,
                pageCount: action.payload
            }
        },
        pushUserProfiles: (state, action) => {
            return {
                ...state,
                userProfiles: [...state.userProfiles, ...action.payload]
            }
        },
        setUserProfilesFiltered: (state, action) => {
            return {
                ...state,
                userProfilesFiltered: action.payload
            }
        },
        setLastEvaluatedKey: (state, action) => {
            return {
                ...state,
                lastEvaluatedKey: action.payload
            }
        },
        resetState: (state, action) => {
            return {
                ...initialState
            }
        },
    }
});

export const { clearUseProfiles, clearPageLastKey, pushPageLastKey, pushUserProfiles, setLastEvaluatedKey, setUserProfilesFiltered, setPage, setPageCount, resetState } = SearchSlice.actions;

export default SearchSlice.reducer;