import { API } from "aws-amplify";
import connectionsMock from "../mocks/api/connections_mock.json";

const ALUMNI_NETWORK_API_NAME = "alumninetwork";

export const AlumniNetworkApi = {
  /**
   * List all Connections in network for a given user.
   * @param {string} [userId] - ID of the user.
   * @returns A list with -Connections -
   */
  getConnections: async (userId, connId = null, networkStatus = "") => {
    // if (process.env.NODE_ENV === "development") return connectionsMock;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    let path = `/connections/${userId}`;

    if (connId) {
      path += `?connectionProfileId=${connId}`;
    }

    if (networkStatus) {
      path += connId ? `&` : `?` + `networkStatus=${networkStatus}`;
    }

    return API.get(ALUMNI_NETWORK_API_NAME, path, init);
  },

  addConnections: async (formData) => {
    //if (process.env.NODE_ENV === "development") return;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
      body: formData,
    };
    return API.put(ALUMNI_NETWORK_API_NAME, `/connections/`, init);
  },

  patchConnection: async (userId, connId, userData) => {
    const init = {
      headers: {},
      response: true,
      body: { ...userData, userProfileId: userId, connectionProfileId: connId },
    };

    return API.patch(ALUMNI_NETWORK_API_NAME, `/connections/`, init);
  },
};
