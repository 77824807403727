import { Chip, Container, Divider, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import TextFieldIcon from '../../shared/TextFieldIcon';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { PIVOT_SPECIAL_CHAR } from '../../../constants/appConstants';


function CategoryEducation({ initial, updateFiltersApplied }) {
    const [searchInput, setSearchInput] = useState("");
    const [educations, setEducations] = useState(initial);

    const handleOnKeyDown = (event) => {
        ///prevent adding empty spaces
        if (event.key === "Enter" && searchInput.trim() !== "") {
            ///prevent adding the same string but with different capitalization (camelCase, PascalCase, etc)
            const optionCapitalized = searchInput.trim().replace(PIVOT_SPECIAL_CHAR, "").toLocaleUpperCase();
            ///add searchInput to educations
            const educationAdded = new Set([...educations, optionCapitalized]);
            setEducations(educationAdded);

            ///clear searchInput
            setSearchInput("");
        }
    };

    const handleOnChangeSearchInput = (event) => {
        setSearchInput(event.target.value);
    };

    const handleOnDelete = (chipToDelete) => {
        const newSet = new Set(educations.values());
        const deleted = newSet.delete(chipToDelete);
        if (deleted) setEducations(newSet);
    };

    const handleOnClickClearSelection = () => {
        const emptySet = new Set();
        setEducations(emptySet);
    };

    useEffect(() => {
        ///update parent's filters
        updateFiltersApplied(educations);
    }, [educations]);

    const chips = useMemo(() => {
        let components = [];
        educations.forEach((education) => components.push(<Chip sx={{ margin: 0.5 }} key={education} label={education} onDelete={() => handleOnDelete(education)} />))

        return components;
    }, [educations]);

    return (
        <Container maxWidth="sm">
            <Stack marginTop={2} direction="row" spacing={2} justifyContent="space-between" alignItems="baseline">
                <TextFieldIcon
                    variant="filled"
                    label="Search by education"
                    icon={<SearchIcon />}
                    helperText="Hitting enter will add the education to the list below"
                    value={searchInput}
                    onChange={handleOnChangeSearchInput}
                    onKeyDown={handleOnKeyDown}
                />

                <Tooltip title="Clear selection" placement='top'>
                    <IconButton onClick={handleOnClickClearSelection}>
                        <FilterAltOffIcon />
                    </IconButton>
                </Tooltip>
            </Stack>

            <Divider
                sx={{
                    marginY: (theme) => theme.spacing(2),
                }}
            />

            <Typography variant='overline'>
                Selected educations
            </Typography>

            <Paper elevation={0} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', listStyle: 'none', p: 0.5, m: 0, }} >
                {chips}
            </Paper>

        </Container>
    )
}

export default CategoryEducation;