import { API, Analytics, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";
import * as queries from "../graphql/queries";
import { NOTIFICATION_TOPICS } from "../constants/appConstants";
import { unseenNotificationsForUser } from "../mocks/api/notifications_api_mock";

const NOTIFICATION_API_NAME = "notification";

export const NotificationApi = {

  /**
   * Sends an e-mail notification to user of his/her profile picture being deleted by `SchoolAdmin`.
   * @param {string} toAddress Verified e-mail address. E-mail will be sent to this address.
   * @param {string} reason Reason provided by `SchoolAdmin` of why the profile picture was deleted
   * @param {string} userName Combination of first and last name of user being notified.
   * @returns Promise<any>
   */
  notifyUserProfilePictureDeleted: (toAddress, reason, userName) => {
    //if (process.env.NODE_ENV === "development") return;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
      body: {
        toAddress: toAddress,
        reason: reason,
        userName: userName
      },
    };

    return API.post(NOTIFICATION_API_NAME, `/send-email/picture-deleted`, init);
  },

  /**
   * Sends a generic e-mail notification using a Pinpoint email template.
   * @param {string} toAddress Verified e-mail address. E-mail will be sent to this address.
   * @param {string} subject Email subject.
   * @param {string} subtitle An apprpiate subtitle related to notification message.
   * @param {string} userName Combination of first and last name of user being notified.
   * @param {string} body An email body with an appropiate message to be notified.
   * @returns Promise<any>
   */
  sendEmail: (toAddress, subject, subtitle, userName, body) => {
    //if (process.env.NODE_ENV === "development") return;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
      body: {
        toAddress: toAddress,
        emailBody: body,
        userName: userName,
        subject: subject,
        subtitle: subtitle
      },
    };

    return API.post(NOTIFICATION_API_NAME, `/send-email/general-email`, init);
  },
  createNotification: async (
    title,
    description,
    navLink,
    topic,
    notificationForUserId = ""
  ) => {
    const notification = {
      title,
      description,
      navLink,
      read: false,
      seen: false,
      topic: topic,
      notificationForUserId,
      type: "Notification",
    };
    return API.graphql(
      graphqlOperation(mutations.createNotification, { input: notification })
    );
  },

  subscribeUpdateNotifications: () => {
    return API.graphql(graphqlOperation(subscriptions.onUpdateNotification));
  },

  subscribeCreateNotificationForATopic: (topic) => {
    return API.graphql(
      graphqlOperation(subscriptions.onCreateNotificationForATopic, {
        topic: topic,
      })
    );
  },

  subscribeCreateNotificationForAUser: (notificationForUserId) => {
    return API.graphql(
      graphqlOperation(subscriptions.onCreateNotificationForAUser, {
        notificationFor: notificationForUserId,
      })
    );
  },

  fetchAllUnseenNotifications: async (userId) => {
    if (process.env.NODE_ENV === "development") return;

    const variables = {
      limit: 51,
      sortDirection: "DESC",
      filter: {
        or: [
          { topic: { eq: NOTIFICATION_TOPICS.ADMINPOST } },
          { topic: { eq: NOTIFICATION_TOPICS.ADMINEVENT } },
          { notificationForUserId: { eq: userId } },
        ],
        and: [
          {
            seen: { eq: "false" },
          },
        ],
      },
    };
    return API.graphql(graphqlOperation(queries.listNotifications, variables));
  },

  fetchNotifications: async (userId, nextToken) => {
    // if (process.env.NODE_ENV === "development") return unseenNotificationsForUser;

    const listCount = /* GraphQL */ `
          query NotificationsByDate(
            $type: String!
            $createdAt: ModelStringKeyConditionInput
            $sortDirection: ModelSortDirection
            $filter: ModelNotificationFilterInput
            $limit: Int
            $nextToken: String
          ) {
            notificationsByDate(
              type: $type
              createdAt: $createdAt
              sortDirection: $sortDirection
              filter: $filter
              limit: $limit
              nextToken: $nextToken
            ) {
              items {
                id
                title
                description
                navLink
                type
                createdAt
                read
                seen
                topic
                notificationForUserId
                updatedAt
              }
              nextToken
            }
          }
        `;

    const variables = {
      limit: 4,
      sortDirection: "DESC",
      type: "Notification",
      nextToken: nextToken,
      filter: {
        or: [
          { topic: { eq: NOTIFICATION_TOPICS.ADMINPOST } },
          { topic: { eq: NOTIFICATION_TOPICS.ADMINEVENT } },
          { notificationForUserId: { eq: userId } },
        ],
      },
    };
    return API.graphql(graphqlOperation(listCount, variables));
  },

  updateNotificaion: async (id, seen, read) => {
    const notification = {
      id,
      seen,
      read,
    };
    return API.graphql(
      graphqlOperation(mutations.updateNotification, { input: notification })
    );
  },

  /**
   * After signing up a new user, it is required to update Pinpoint customer pool by registering a new endpoint associated to recently created user.
   * @param {string} address Amazon Endpoint address. In our case, it is User e-mail address which will be used to target meessages or notifications
   * @param {string} userProfileId Unique user identifier
   * @param {string} firstName First name or given name
   * @param {string} lastName Last name or family name
   * @param {string} accountType Role or user group user belongs to
   * @see For more details, visit {@link https://docs.amplify.aws/lib/analytics/update-endpoint/q/platform/js/ | Amplify's Analytics docs}
   * @returns void
   */
  updateEndpoint: async (address, userProfileId, firstName, lastName, accountType) => {
    // NOTE: All fields are OPTIONAL
    await Analytics.updateEndpoint({
      address: address, // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
      demographic: {
        appVersion: '1.0.0', // The version of the application associated with the endpoint.
        platform: 'web', // The platform of the endpoint device, such as iOS or Android.
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone // The timezone of the endpoint. Specified as a tz database value, such as Americas/Los_Angeles.
      },
      channelType: "EMAIL",
      /** Indicates whether a user has opted out of receiving messages with one of the following values:
       * ALL - User has opted out of all messages.
       * NONE - Users has not opted out and receives all messages.
       */
      optOut: 'NONE',
      userId: userProfileId,
      userAttributes: {
        accountType: [accountType],
        firstName: [firstName],
        lastName: [lastName]
      }
    });
  },

  recordNewEvent: async (name) => {
    await Analytics.record({ name: name });
  },
};