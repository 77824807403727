import React, { useEffect, useState } from "react";
import { CardContent, Grid, Tooltip, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CardRaisedRounded from "../shared/CardRaisedRounded";
import UserProfileAvatar from "../shared/UserProfileAvatar";
import { useDispatch, useSelector } from "react-redux";

const UserProfileCard = ({
  profile,
  handleCardOnClick,
  selectProfile,
  allSelected,
}) => {
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    setUsers(profile);
  }, []);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    let tempUser = users.map((user) =>
      user.name === name ? { ...user, isChecked: checked } : user
    );
    setUsers(tempUser);
  };

  return (
    <>
      <CardRaisedRounded
        sx={(theme) => ({
          marginY: theme.spacing("20px"),
          marginX: theme.spacing(2),
          boxShadow: 3,
        })}
      >
        <CardContent>
          <Grid container spacing={2} sx={{ color: "black" }}>
            <Grid item xs={6} md={1} lg={1} xl={1} alignSelf={"center"}>
              <Checkbox
                style={{ alignSelf: "start", float: "left" }}
                onChange={selectProfile}
                value={JSON.stringify({
                  userProfileId: profile.userProfileId,
                  firstName: profile.firstName,
                  lastName: profile.lastName,
                  cohort: profile.cohort,
                  city: profile.city,
                  yearsAttendedBWS: profile.yearsAttendedBWS,
                })}
                checked={allSelected.includes(
                  JSON.stringify({
                    userProfileId: profile.userProfileId,
                    firstName: profile.firstName,
                    lastName: profile.lastName,
                    cohort: profile.cohort,
                    city: profile.city,
                    yearsAttendedBWS: profile.yearsAttendedBWS,
                  })
                )}
              ></Checkbox>

              {/* profile picture avatar with ID below */}
            </Grid>

            <Grid
              item
              xs={6}
              md={1}
              lg={1}
              xl={1}
              alignSelf={"center"}
            >
              {/* profile picture avatar with ID below */}
              <Tooltip
                title="Click here to view more details"
                placement="bottom"
                enterDelay={200}
              >
                <span>
                  <UserProfileAvatar
                    userProfile={profile}
                    handleAvatarOnClick={handleCardOnClick}
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={2} lg={2} xl={2}>
              {/* user profile name */}
              <Typography textTransform={"uppercase"} variant="caption">
                NAME
              </Typography>
              <Typography textTransform={"capitalize"} variant="body1">
                {profile?.firstName} {profile?.lastName}
              </Typography>
            </Grid>

            {profile.parentEmail ? (
              <Grid item xs={12} md={6} lg={2} xl={2}>
                {/* parent/guarding email */}
                <Typography textTransform={"uppercase"} variant="caption">
                  PARENT/GUARDIAN EMAIL
                </Typography>
                <Typography variant="body1" noWrap>
                  {profile?.parentEmail || "-"}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} md={6} lg={2} xl={2}>
                {/* parent/guarding email */}
                <Typography textTransform={"uppercase"} variant="caption">
                  EMAIL
                </Typography>
                <Typography variant="body1" noWrap>
                  {profile?.email || "-"}
                </Typography>
              </Grid>
            )}

            <Grid item xs={6} md={2} lg={2} xl={2}>
              {/* user profile phone no */}
              <Typography textTransform={"uppercase"} variant="caption">
                PHONE NUMBER
              </Typography>
              <Typography variant="body1">{profile?.phone || "-"}</Typography>
            </Grid>

            <Grid item xs={6} md={2} lg={2} xl={2}>
              {/* YEARS ATTENDED IN BWS */}
              <Typography textTransform={"uppercase"} variant="caption">
                YEARS ATTENDED IN BWS
              </Typography>
              <Typography variant="body1">
                {profile?.attendedYears || "-"}
              </Typography>
            </Grid>

            <Grid item xs={6} md={2} lg={2} xl={2}>
              {/* BWS graduation year */}
              <Typography textTransform={"uppercase"} variant="caption">
                BWS GRADUATION YEAR
              </Typography>
              <Typography variant="body1">{profile?.cohort || "-"}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardRaisedRounded>
    </>
  );
};

export default UserProfileCard;
