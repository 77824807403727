import { createSlice } from "@reduxjs/toolkit";
import { ProfilePictureApi } from "../../apis/ProfilePictureApi";
import { APP_ROLES } from "../../constants/appConstants";

export const UserSlice = createSlice({
  name: "user",
  initialState: {
    userLoggedIn: {
      userName: "",
      profileType: "",
      firstName: "",
      lastName: "",
      email: "",
    },
    hasAccessToAlumniDropdown: false,
    hasAdminAccess: false,
    profilePicture: {
      userProfileId: undefined,
      dataFromBlob: undefined
    }
  },
  reducers: {
    loadLoggedInUser: (state, action) => {
      return {
        ...state,
        userLoggedIn: action.payload,
        hasAdminAccess: (action.payload.profileType === APP_ROLES.SCHOOL_ADMIN || action.payload.profileType === APP_ROLES.SCHOOL_ADMIN_SUPER_USER),
      };
    },
    hasAccessToAlumniDropdown: (state, action) => {
      const allowUserGroups = [APP_ROLES.PARENTS];
      const usersGroup = Array.of(action.payload);
      const hasAccess = usersGroup.every(i => allowUserGroups.includes(i));

      return { ...state, hasAccessToAlumniDropdown: hasAccess };
    },
    storeProfilePicture: (state, action) => {
      return {
        ...state,
        profilePicture: {
          ...state.profilePicture,
          ...action.payload
        }
      };
    }
  },
});

export const { loadLoggedInUser, hasAccessToAlumniDropdown, storeProfilePicture } = UserSlice.actions;

export default UserSlice.reducer;

export const fetchProfilePicture = (userProfileId) => async (dispatch) => {
  await ProfilePictureApi.downloadProfilePicture(userProfileId)
    .then((result) => {
      const imgData = URL.createObjectURL(result.Body);

      dispatch(storeProfilePicture({ userProfileId: userProfileId, dataFromBlob: imgData }));
    })
    .catch((err) => {
      console.error(err);
      ///no profile photo set. dispatch NULL to avoid inncessary future API calls
      dispatch(storeProfilePicture({ userProfileId: userProfileId, dataFromBlob: null }));
    });
};
