import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react'

function ActiveChatHeaderSkeleton() {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%' }}>
                <Skeleton width="20%" variant="rounded">
                    <Typography variant='h6' />
                </Skeleton>
            </Box>
            <Box sx={{ width: '100%' }}>
                <Skeleton width="15%" variant='rounded'>
                    <Typography variant='h6' />
                </Skeleton>
            </Box>
        </Box>
    )
}

export default ActiveChatHeaderSkeleton;