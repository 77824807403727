import styled from "styled-components";
import { Dialog, Tab, TextField, Typography, Divider, Button, DialogActions } from "@mui/material";

export const DialogPopup = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 0px;
    max-width: none;
  }
  .MuiDialogTitle-root {
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
  }
  .MuiDialogActions-root {
    padding: 13px 24px;
  }
  .MuiButtonBase-root {
    text-transform: none;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
  }
  .css-11qr2p8-MuiButtonBase-root-MuiButton-root {
    //Ok button
    background-color: #0f356f;
    padding: 0 30px;
  }
  .css-11qr2p8-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #0f356f;
  }

  .css-1k23hlb-MuiButtonBase-root-MuiButton-root {
    //Back button
    color: #0f356f;
    border: 1px solid #0f356f;
    padding: 0 30px;
  }
  .css-1k23hlb-MuiButtonBase-root-MuiButton-root:hover {
    border: 1px solid #0f356f;
  }

  /* &.dialog-medium {
    .MuiPaper-root {
      width: 70vw;
      height: 650px;
    }
  }
  &.dialog-big {
    .MuiPaper-root {
      width: 80vw;
      height: 600px;
    }
  } */
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  color: #0f356f;
  cursor: pointer;
`;

export const RemoveChildButton = styled.button`
  position: absolute;
  top: -35px;
  right: 0;
  border: none;
  background: none;
  color: #1976d2;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #0f356f;
`;

export const InputField = styled(TextField)`
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
  width: 15vw;
  &:not(:last-child) {
    margin-inline-end: 27px;
  }
`;

export const FormContainer = styled.div`
  margin-top: 2rem;
  position: relative;
`;

export const AddChildOption = styled(Typography)`
  color: #1976d2;
  width: 170px;
  text-decoration: none;
  cursor: pointer;
`;

export const TypographyStyled = styled(Typography)`
  padding-top: 13px;
  color: #1976d2;
  text-decoration: none;
  cursor: pointer;
`;

export const SelectedStyledTab = styled(({ ...props }) => (
  <Tab {...props} classes={{ selected: "selected" }} />
))`
  &.selected {
    background-color: #0f356f !important;
    color: #fff !important;
  }
`;

export const GridDiv = styled.div`
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10vw, 1fr));
  gap: 2vw 5vw;

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(auto-fit, minmax(13vw, 1fr));
  }   
`;

export const DividerLine = styled(Divider)`
  &.first-divider {
    padding-bottom: 15px;
  }
  &.second-divider {
    padding-bottom: 20px;
    border-style: dashed;
  }
`;

export const ActionButton = styled(Button)`
  &.ok-button,
  &.back-button {
    padding: 0 30px;
    height: 40px;
    text-transform: none;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
  }
  &.ok-button {
    margin-left: 18px;
    background-color: #0f356f;
  }
  &.back-button {
    color: #0f356f;
    border: 1px solid #0f356f;
  }
  &.ok-button:hover {
    background-color: #0f356f;
  }
  &.back-button:hover {
    border: 1px solid #0f356f;
    background-color: white;
  }
`;

export const ActionsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FormContainerAddInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13vw, 1fr));
  gap: 2vw 5vw;
  margin-top: 8px;

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const DialogActionsStyled = styled(DialogActions)`
  border-top: 1px solid #ccc;
`;

export const AccountTypeSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 220px;
  .MuiInputLabel-root {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .MuiSelect-root {
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
  }
`;

export const FixedFooter = styled.div`   
  bottom: 0;
  left: 0;
  margin-bottom: 16px;
  position: fixed;
  background-color: #ffffff;
  text-align: right; 
  width: 100%;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.12); /* Shadow properties */
  z-index: 999; 
  padding: 4px;
`;

export const ColoredCircle = styled.div`   
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -2px;
  border-radius: 50%;  
  border-width: 0.2px;
  border-color: black;
  height: 10px;
  width: 10px;
`;