import { Badge, IconButton } from "@mui/material";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useEffect, useState } from "react";
import { NotificationApi } from "../../apis/NotificationApi";
import { useSelector } from "react-redux";
import { APP_ROLES, NOTIFICATION_TOPICS } from "../../constants/appConstants";

const NotificationBellICon = ({ handleOpenNotification }) => {
  const [notificationCount, setNotificationCount] = useState(0);
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const alumniSelected = useSelector((state) => state.alumni.alumniSelected);

  useEffect(() => {
    const subUpdateNotifications =
      NotificationApi.subscribeUpdateNotifications().subscribe({
        next: (eventData) => {
          getNotificationCount();
        },
        error: (error) => {
          console.error("Subscription error 'onUpdateNotification':", error);
        },
      });

    const subCreateNotificationsForATopic =
      NotificationApi.subscribeCreateNotificationForATopic(
        NOTIFICATION_TOPICS.ADMINPOST
      ).subscribe({
        next: (eventData) => {
          getNotificationCount();
        },
        error: (error) => {
          console.error(
            "Subscription error 'onCreateNotificationForATopic':",
            error
          );
        },
      });

    let userId = "";
    if (userLoggedIn.profileType == APP_ROLES.PARENTS) {
      userId = alumniSelected.alumniId;
    } else {
      userId = userLoggedIn.userName;
    }

    const subCreateNotificationsForAUser =
      NotificationApi.subscribeCreateNotificationForAUser(
        userId
      ).subscribe({
        next: (eventData) => {
          getNotificationCount();
        },
        error: (error) => {
          console.error(
            "Subscription error 'onCreateNotificationForAUser':",
            error
          );
        },
      });

    return () => {
      subUpdateNotifications.unsubscribe();
      subCreateNotificationsForATopic.unsubscribe();
      subCreateNotificationsForAUser.unsubscribe();
    };
  }, [userLoggedIn, alumniSelected]);

  const getNotificationCount = () => {
    let userId = "";
    if (userLoggedIn.profileType == APP_ROLES.PARENTS) {
      userId = alumniSelected.alumniId;
    } else {
      userId = userLoggedIn.userName;
    }

    //Notification count only for unseen notifications
    NotificationApi.fetchAllUnseenNotifications(userId)
      .then((result) => {
        result && setNotificationCount(result.data?.listNotifications.items.length);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getNotificationCount();
  }, [userLoggedIn, alumniSelected]);

  return (
    <>
      <Badge color="primary" badgeContent={notificationCount} max={50}>
        <IconButton onClick={handleOpenNotification}>
          <NotificationsNoneIcon />
        </IconButton>
      </Badge>
    </>
  );
};

export default NotificationBellICon;
