import React from "react";
import { Grid } from "@mui/material";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import TypographyPersonalInfoField from "../shared/TypographyPersonalInfoField";
import TypographyPersonalInfoFieldTitle from "../shared/TypographyPersonalInfoFieldTitle";

const JobsDetails = ({ jobsDetailsInfo }) => {
  return (
    <>
      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            JOB TITLE
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {jobsDetailsInfo.title}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            COMPANY NAME
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {jobsDetailsInfo.companyName}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            START DATE
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {jobsDetailsInfo.startDate}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            END DATE
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {jobsDetailsInfo.endDate}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>
      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            ROLES AND RESPONSIBILITIES
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {jobsDetailsInfo.rolesAndResponsibility}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>
    </>
  );
};

export default JobsDetails;
