import { ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { MessagingApi } from '../../apis/MessagingApi';
import { selectChat } from '../../store/slices/messagingSlice';
import UserProfileAvatar from '../shared/UserProfileAvatar';
import { fetchUserProfilePicture, toggleLoader } from '../../store/slices/sharedSlice';

const ChatListItemAdmin = ({ adminParticipant }) => {
    const dispatch = useDispatch();
    const chats = useSelector((state) => state.messaging.chats);
    const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
    const activeChatId = useSelector((state) => state.messaging.activeChat.chatId);
    const [chat, setChat] = useState(null);
    const participantsProfilePictures = useSelector((state) => state.shared.profilePictures.items);

    useEffect(() => {
        const allParticipantsFromChats = [...new Set(chats.items.flatMap(item => item.participants.items.flatMap((p) => p.participantId)))];

        const chatWithAdminFound = allParticipantsFromChats.find(c => c === adminParticipant.id);

        if (chatWithAdminFound) {
            const chatWithAdmin = chats.items.find(item => item.participants.items.some(p => p.participantId === adminParticipant.id))
            setChat(chatWithAdmin);
        }
    }, []);

    useEffect(() => {
        const found = participantsProfilePictures.find(e => e.key === adminParticipant.id);

        if (!found) {
            dispatch(fetchUserProfilePicture(adminParticipant));
        }
    }, [])

    const startNewChat = async () => {
        let chatId = '';

        // if a Chat was already found for the Admin, then no new chat needs to be created.
        if (chat === null) {
            dispatch(toggleLoader({isLoading: true}));
            const participantsId = [userLoggedIn.userName, adminParticipant.id];
            const newChat = await MessagingApi.startNewChat(participantsId);

            ///update locally state to avoid creating a new chat if end user clicks again in same Admin
            setChat(newChat);

            chatId = newChat.id;
            dispatch(toggleLoader({isLoading: false}));
        }
        else {
            // simply set it as active
            chatId = chat.id;
        };

        dispatch(selectChat({ chatId: chatId }));
    }

    return (
        <ListItemButton divider={true} onClick={startNewChat} selected={chat?.id === activeChatId}>
            <ListItemAvatar>
                <UserProfileAvatar source={participantsProfilePictures.find(e => e.key === adminParticipant.id)?.value.profilePictureKey} size={44} userProfile={adminParticipant} />
            </ListItemAvatar>
            <ListItemText primary={`${adminParticipant.firstName} ${adminParticipant.lastName}`} />
        </ListItemButton>
    )
}

export default ChatListItemAdmin;