import React, { useState } from "react";
import { CreateProfile } from "../createProfile/CreateProfile";
import { useSelector } from "react-redux";
import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import logo from "../../assets/main_logo.png";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AlumniProfileDropdown from "../alumniProfileSelection/AlumniProfileDropdown";
import AppNotifications from "../notification/AppNotifications";
import NotificationBellICon from "./NotificationBellICon";
import { Link } from "react-router-dom";

const ApplicationHeaderBar = ({ signOut }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [creatingProfile, setCreatingProfile] = useState(false);
  const loggedInUserInfo = useSelector((state) => state.user);

  const showAlumniDropdown = useSelector(
    (state) => state.user.hasAccessToAlumniDropdown
  );

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [renderPopup, setRenderPopup] = useState(0);
  const handleOpenNotification = () => {
    setRenderPopup(1);
  };

  const onClose = () => {
    setRenderPopup(0);
  };

  return (
    <>
      <AppBar
        style={{
          backgroundColor: "#ffffff",
          color: "black",
          position: "sticky",
        }}
      >
        <Toolbar>
          <Link to="/">
            <Box component="img" sx={{ height: 60 }} alt="Logo" src={logo} />
          </Link>
          <Box
            sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center" }}
          >
            {loggedInUserInfo.hasAdminAccess && (
              <Box sx={{ marginTop: 1 }}>
                <Button
                  variant="contained"
                  onClick={() => setCreatingProfile(true)}
                  startIcon={<AddCircleIcon />}
                >
                  Create New User
                </Button>
              </Box>
            )}
            <Box>{showAlumniDropdown && <AlumniProfileDropdown />}</Box>
            <Box sx={{ marginTop: 1, marginX: 4 }}>
              <NotificationBellICon
                handleOpenNotification={handleOpenNotification}
              />
            </Box>
            <IconButton sx={{ marginTop: 1 }} onClick={handleOpenUserMenu}>
              <Avatar alt="" />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={signOut}>
                <Typography textAlign="center">Sign out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {loggedInUserInfo.hasAdminAccess && (
        <CreateProfile
          open={creatingProfile}
          onClose={() => setCreatingProfile(false)}
        />
      )}
      {renderPopup === 1 && <AppNotifications onClose={onClose} />}
    </>
  );
};

export default ApplicationHeaderBar;
