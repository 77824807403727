import { StorageManager } from '@aws-amplify/ui-react-storage';
import { Button, Dialog, DialogTitle } from "@mui/material";
import { useState } from "react";
import { BUCKET_PROFILE_PICTURES_PATH } from '../../constants/appConstants';

const ProfilePictureUpload = ({ userId, onSuccessUpload, onErrorUpload }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const processFile = ({ file }) => {
        return { file, key: `${userId}` };
    };

    const handleOnSuccess = ({ key }) => {
        setOpen(false);
        onSuccessUpload(key);
    };

    return (
        <>
            <Button
                variant="text"
                color="white"
                size="small"
                onClick={handleClickOpen}
            >
                Upload picture
            </Button>

            <Dialog
                onClose={handleClose}
                open={open}
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>Upload picture</DialogTitle>

                <StorageManager
                    acceptedFileTypes={['image/*']}
                    accessLevel="public"
                    isResumable={false}
                    maxFileCount={1}
                    maxSize={3000000} //equals to 3MB. to be defined
                    onUploadSuccess={handleOnSuccess}
                    onUploadError={onErrorUpload}
                    path={BUCKET_PROFILE_PICTURES_PATH}
                    processFile={processFile}
                    shouldAutoProceed={true}
                    showThumbnails={true}
                />
            </Dialog>
        </>
    )
};

export default ProfilePictureUpload;