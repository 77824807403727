import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from "@mui/material";
import { useEffect } from "react";
import { React, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { ProfessionalInformationApi } from "../../apis/ProfessionalInformationApi";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  TypographyStyled,
  DividerLine,
} from "../../utils/styles";
import EditIcon from "@mui/icons-material/Edit";

import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { AddTrainingForm } from "./AddTrainingForm";
import { AddProfessionalInfoPopup } from "./AddProfessionalInfoPopup";
import TrainingDetails from "./TrainingDetails";
import JobsDetails from "./JobsDetails";
import { AddJobsForm } from "./AddJobsForm";
import { APP_ROLES, PROFESSION_POPUP_NUMBER } from "../../constants/appConstants";
import ConfirmDialogBox from "../shared/ConfirmDialogBox";
import { Divider, Grid } from "@mui/material";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import TypographyPersonalInfoGroupHeading from "../shared/TypographyPersonalInfoGroupHeading";
import GridItemOneColumnProfileInfo from "../shared/GridItemOneColumnProfileInfo";
import TypographyPersonalInfoField from "../shared/TypographyPersonalInfoField";
import OutlinedButton from "../shared/OutlinedButton";
import ContainedButton from "../shared/ContainedButton";
import { useParams } from "react-router-dom";

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding-right: 0.2rem;
  margin-top: 0.2rem;
`;

export const ProfessionalInformationTab = () => {
  const [isEditing, setIsEditing] = useState(false);

  const [professionalInformation, setProfessionalInformation] = useState({
    isWorking: "yes",
    internshipsTrainings: [],
    jobs: [],
  });
  const [editingInfo, setEditingInfo] = useState([]);
  const [renderPopup, setRenderPopup] = useState(0);
  const dispatch = useDispatch();
  const loggedInUserInfo = useSelector((state) => state.user);
  const userLoggedIn = loggedInUserInfo.userLoggedIn;
  const alumniSelected = useSelector((state) => state.alumni.alumniSelected);
  let { id } = useParams();

  useEffect(() => {
    const userId = id ? id : getUserProfileId();

    dispatch(toggleLoader({ isLoading: true }));
    userId &&
      ProfessionalInformationApi.getProfessionalData(userId)
        .then((response) => {
          if (Object.keys(response.data[0]).length !== 0) {
            //If user don't have data it will start the state with mocked data
            setProfessionalInformation(response.data[0]);
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(toggleLoader({ isLoading: false }));
          dispatch(
            showAlert({
              message: "An error ocurred while fetching. Please, try again.",
              severity: "error",
            })
          );
        })
        .finally(() => {
          dispatch(toggleLoader({ isLoading: false }));
        });
  }, [userLoggedIn]);

  // It deep clone the info that retrieves from the DB because the user will need to edit a cloned array
  useEffect(() => {
    setEditingInfo(JSON.parse(JSON.stringify(professionalInformation)));
  }, [professionalInformation]);

  const getUserProfileId = () => {
    //if user logged in is type of Parent, then we need to fetch Alumni's profile picture. otherwise, user's logged in profile picture.
    return (userLoggedIn.profileType === APP_ROLES.PARENTS) ? alumniSelected.alumniId : userLoggedIn.userName;
  };

  const handleEditClick = () => {
    setIsEditing(true);
    //console.log(editingInfo);
  };

  const handleSubmit = () => {
    setProfessionalInformation(editingInfo); // Once the user hit the "Done" button it replace the original Array with the edited one
    //post the data in dynamoDB
    const userId = id ? id : getUserProfileId();
    ProfessionalInformationApi.postProfessionalData(editingInfo, userId);
    setIsEditing(false);
  };

  const handleChange = (e, index, formToEdit) => {
    const updatedData = { ...editingInfo };
    updatedData[formToEdit][index][e.target.name] = e.target.value;
    setEditingInfo(updatedData);
  };

  const addFormData = (formData, formToAdd) => {
    let updatedData = { ...editingInfo };
    updatedData[formToAdd]
      ? updatedData[formToAdd].push(formData)
      : (updatedData = { ...editingInfo, [formToAdd]: [formData] });
    setEditingInfo(updatedData);
  };

  const handleWorkingChange = (event) => {
    setProfessionalInformation({
      ...professionalInformation,
      isWorking: event.target.value,
    });
  };

  const [arrayToDelete, setArrayToDelete] = useState("");
  const [indexToDelete, setIndexToDelete] = useState();

  const [openConfirmDelete, setOpenConfirmDelete] = useState({
    arrName: "",
    isOpen: false,
  });

  const handleDelete = (arr, index) => {
    setOpenConfirmDelete({ arrName: arr, isOpen: true });
    setArrayToDelete(arr);
    setIndexToDelete(index);
  };

  const okDelete = () => {
    const clonedArray = { ...editingInfo };
    clonedArray[arrayToDelete].splice(indexToDelete, 1);
    setEditingInfo(clonedArray);
    setOpenConfirmDelete({ arrName: arrayToDelete, isOpen: false });
  };

  const cancelDelete = () => {
    setOpenConfirmDelete({ arrName: arrayToDelete, isOpen: false });
  };

  const [openConfirmBack, setOpenConfirmBack] = useState(false);
  const okBack = () => {
    setIsEditing(false);
    setOpenConfirmBack(false);
  };
  const cancelBack = () => {
    setIsEditing(true);
    setOpenConfirmBack(false);
  };

  function renderConditional() {
    if (isEditing === false) {
      return (
        //Here it displays the data from the "original" array
        <>
          <Grid container marginLeft={3} spacing={2} paddingTop={3}>
            <GridItemProfileInfo>
              <TypographyPersonalInfoGroupHeading>
                Are you working?
              </TypographyPersonalInfoGroupHeading>
              <TypographyPersonalInfoField>
                {professionalInformation.isWorking}
              </TypographyPersonalInfoField>
            </GridItemProfileInfo>
          </Grid>

          <Divider
            sx={{
              color: "black",
              paddingTop: 2,
              borderStyle: "dashed",
              width: "95%",
              margin: "auto",
            }}
          />
          <Grid container marginLeft={3} spacing={2} paddingTop={2}>
            <GridItemOneColumnProfileInfo>
              <TypographyPersonalInfoGroupHeading>
                INTERNSHIP/ TRAINING/ PROFESSIONAL APPRENTICESHIP
              </TypographyPersonalInfoGroupHeading>
            </GridItemOneColumnProfileInfo>
          </Grid>
          {professionalInformation.internshipsTrainings
            ? professionalInformation.internshipsTrainings.map(
                (trng, index) => (
                  <Fragment key={index}>
                    <TrainingDetails trainingDetailsInfo={trng} key={index} />
                    <Divider
                      sx={{
                        color: "black",
                        paddingTop: 2,
                        borderStyle: "dashed",
                        width: "95%",
                        margin: "auto",
                      }}
                    />
                  </Fragment>
                )
              )
            : null}

          <Grid container marginLeft={3} spacing={2} paddingTop={2}>
            <GridItemProfileInfo>
              <TypographyPersonalInfoGroupHeading>
                JOBS
              </TypographyPersonalInfoGroupHeading>
            </GridItemProfileInfo>
          </Grid>
          {professionalInformation.jobs
            ? professionalInformation.jobs.map((jobDetail, index) => (
                <Fragment key={index}>
                  <JobsDetails jobsDetailsInfo={jobDetail} />
                  <Divider
                    sx={{
                      color: "black",
                      paddingTop: 2,
                      borderStyle: "dashed",
                      width: "95%",
                      margin: "auto",
                    }}
                  />
                </Fragment>
              ))
            : null}
        </>
      );
    } else {
      return (
        //Here displays the data from the "duplicate" and editable array
        <>
          <Grid container marginLeft={3} spacing={2} paddingTop={3}>
            <GridItemOneColumnProfileInfo>
              <TypographyPersonalInfoGroupHeading>
                Are you working?
              </TypographyPersonalInfoGroupHeading>

              <RadioGroup
                row
                aria-labelledby="is-working-group-label"
                name="is-working-group"
                value={professionalInformation.isWorking}
                onChange={handleWorkingChange}
              >
                <FormControlLabel
                  value="Yes"
                  control={<Radio size="small" />}
                  label="Yes"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "0.75rem",
                    },
                  }}
                />
                <FormControlLabel
                  value="No"
                  control={<Radio size="small" />}
                  label="No"
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "0.75rem",
                    },
                  }}
                />
              </RadioGroup>
            </GridItemOneColumnProfileInfo>
          </Grid>

          <Divider
            sx={{
              color: "black",
              paddingTop: 2,
              borderStyle: "dashed",
              width: "95%",
              margin: "auto",
            }}
          />
          <Grid container marginLeft={3} spacing={2} paddingTop={2}>
            <GridItemOneColumnProfileInfo>
              <TypographyPersonalInfoGroupHeading>
                INTERNSHIP/ TRAINING/ PROFESSIONAL APPRENTICESHIP
              </TypographyPersonalInfoGroupHeading>
            </GridItemOneColumnProfileInfo>
          </Grid>

          {editingInfo.internshipsTrainings
            ? editingInfo.internshipsTrainings.map((trng, index, array) => (
                <Fragment key={`${array.length}-${index}`}>
                  <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                    <IconButton
                      onClick={() =>
                        handleDelete("internshipsTrainings", index)
                      }
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <AddTrainingForm
                    trainingDetailsInfo={trng}
                    handleChange={(e) =>
                      handleChange(e, index, "internshipsTrainings")
                    }
                  />
                  {index < array.length - 1 && (
                    <Divider
                      sx={{
                        color: "black",
                        paddingTop: 2,
                        borderStyle: "dashed",
                        width: "95%",
                        margin: "auto",
                      }}
                    />
                  )}
                </Fragment>
              ))
            : null}

          <Grid container marginLeft={3} spacing={2} paddingTop={2}>
            <GridItemOneColumnProfileInfo>
              <TypographyStyled
                onClick={() =>
                  setRenderPopup(PROFESSION_POPUP_NUMBER.ADD_TRAINING)
                }
              >
                + ADD INTERNSHIP/ TRAINING/ PROFESSIONAL APPRENTICESHIP
              </TypographyStyled>
            </GridItemOneColumnProfileInfo>
          </Grid>

          {renderPopup === PROFESSION_POPUP_NUMBER.ADD_TRAINING && (
            <AddProfessionalInfoPopup
              open={true}
              onClose={() => setRenderPopup(0)}
              trainingDetailInfo={{}}
              addFormData={addFormData}
              formToAdd={"internshipsTrainings"}
              popupName={
                "ADD INTERNSHIP/ TRAINING/ PROFESSIONAL APPRENTICESHIP"
              }
              popupNumber={PROFESSION_POPUP_NUMBER.ADD_TRAINING}
            />
          )}

          <ConfirmDialogBox
            open={
              openConfirmDelete.arrName === "internshipsTrainings" &&
              openConfirmDelete.isOpen
            }
            title={"Confirm Training Deletion"}
            message={"Click Ok to delete Training"}
            okText={"Ok"}
            cancelText={"Cancel"}
            handleOk={okDelete}
            handleClose={cancelDelete}
          />

          <Divider
            sx={{
              color: "black",
              paddingTop: 2,
              borderStyle: "dashed",
              width: "95%",
              margin: "auto",
            }}
          />
          <Grid container marginLeft={3} spacing={2} paddingTop={2}>
            <GridItemOneColumnProfileInfo>
              <TypographyPersonalInfoGroupHeading>
                JOBS
              </TypographyPersonalInfoGroupHeading>
            </GridItemOneColumnProfileInfo>
          </Grid>

          {editingInfo.jobs
            ? editingInfo.jobs.map((jobDetail, index, array) => (
                <Fragment key={`${array.length}-${index}`}>
                  <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                    <IconButton onClick={() => handleDelete("jobs", jobDetail)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>

                  <AddJobsForm
                    jobsDetailsInfo={jobDetail}
                    handleChange={(e) => handleChange(e, index, "jobs")}
                  />
                  {index < array.length - 1 && (
                    <DividerLine className="second-divider" />
                  )}
                </Fragment>
              ))
            : null}

          <Grid container marginLeft={3} spacing={2} paddingTop={2}>
            <GridItemOneColumnProfileInfo>
              <TypographyStyled
                onClick={() => setRenderPopup(PROFESSION_POPUP_NUMBER.ADD_JOBS)}
              >
                + Add JOBS
              </TypographyStyled>
            </GridItemOneColumnProfileInfo>
          </Grid>
          {renderPopup === PROFESSION_POPUP_NUMBER.ADD_JOBS && (
            <AddProfessionalInfoPopup
              open={true}
              onClose={() => setRenderPopup(0)}
              jobsDetailsInfo={{}}
              addFormData={addFormData}
              formToAdd={"jobs"}
              popupName={"ADD JOBS"}
              popupNumber={PROFESSION_POPUP_NUMBER.ADD_JOBS}
            />
          )}

          <ConfirmDialogBox
            open={
              openConfirmDelete.arrName === "jobs" && openConfirmDelete.isOpen
            }
            title={"Confirm Job Deletion"}
            message={"Click Ok to delete Job"}
            okText={"Ok"}
            cancelText={"Cancel"}
            handleOk={okDelete}
            handleClose={cancelDelete}
          />

          <ButtonsContainer>
            <OutlinedButton
              label="Back"
              onClick={() => setOpenConfirmBack(true)}
            />
            <ContainedButton
              type="submit"
              label="Done"
              onClick={handleSubmit}
            />
          </ButtonsContainer>

          <ConfirmDialogBox
            open={openConfirmBack}
            title={"Confirm Cancelation"}
            message={
              "You will lose any unsaved data. Click on cancel and save your changes. "
            }
            okText={"Ok"}
            cancelText={"Cancel"}
            handleOk={okBack}
            handleClose={cancelBack}
          />
        </>
      );
    }
  }

  return (
    <>
      <Title>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.125rem",
            paddingTop: 2,
            marginLeft: 5,
            width: "85%",
          }}
          variant="h6"
        >
          Professional Information
        </Typography>
        {(loggedInUserInfo.hasAdminAccess || !id) && !isEditing && (
          <EditIcon
            sx={{
              flex: "none",
              paddingBottom: 0.5,
              height: 18,
              cursor: "pointer",
            }}
            onClick={handleEditClick}
          />
        )}
      </Title>
      <DividerLine sx={{ width: "95%", margin: "auto" }} />
      <Box>{renderConditional()}</Box>
    </>
  );
};
