import { useEffect, useMemo, useRef } from "react";
import { PIVOT_SPECIAL_CHAR, PIVOT_USER_DETAILS } from "../constants/appConstants";

export const withClearOption = (options) => {
  return [
    {
      description: <em>Clear</em>,
      value: "",
    },
    ...options,
  ];
};

function debounce(callback, wait) {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      callback(...args);
    }, wait);
  };
}

/**
 * Custom hook to debounce a callback function for a given period of time.
 * @param {Function} callback Function to be debounced or delayed.
 * @param {number} wait Period of time, in `ms`, in wich the `callback` function will be delayed.
 * @author Nadia Makarevich at https://www.developerway.com/posts/debouncing-in-react
 */
export const useDebounce = (callback, wait = 0) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, wait);
  }, []);

  return debouncedCallback;
};

export const validatePhoneNumber = (phoneNumber) => {
  if (!phoneNumber || phoneNumber.trim() === "") return true;
  const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/; // Regex pattern to validate a US phone number
  return phoneRegex.test(phoneNumber);
};

export const validateZipCode = (zipcode) => {
  if (!zipcode || zipcode.trim() === "") return true;
  const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/; // Regex pattern to validate a ZIP code
  return zipRegex.test(zipcode);
};

/**
 * Given an email address, checks if it's a valid one or not.
 * @param {string} email E-mail address to validate.
 * @returns `True` if `email` passes the RegEx test
 * @author `RegExp` provided by ChatGPT.
 */
export const validateEmailAddress = (email) => {
  if (!email || email.trim() === "") return true;
  const emailRegex = /^[\w\.-]+@[\w-]+(\.[\w-]+)*\.[a-zA-Z]{2,}$/
  return emailRegex.test(email);
}

export const attributesToFilterChatsFrom = (item) => {
  return Array.of(item.firstName || "", item.lastName || "")
    .join("#")
    .toString()
    .toLocaleUpperCase();
};

///used at Search and Network tab
export const attributesToFilterUsersFrom = (item) => {
  return Array.of(item.firstName || '', item.lastName || '', item.parentEmail || '', item.email || '')
    .join('#')
    .toString()
    .toLocaleUpperCase();
};

export const saveISO8601Time = () => {
  return new Date().toISOString();
};

export const convertISOToCustomFormat = (iso8601Date) => {
  const date = new Date(iso8601Date);
  const options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatTime = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  const currentDate = new Date();
  currentDate.setHours(hours);
  currentDate.setMinutes(minutes);
  const options = {
    hour: "2-digit",
    minute: "2-digit",
  };

  return currentDate.toLocaleTimeString([], options);
};

/**
 * Data source is pivoted by its keys. Every time `EducationInformation` or `ProfessionalInformation` are updated with new, deleted or renamed attributes, this util needs to be updated as well.
 * @param {string} _type Case scenario to pivot data by specific keys.
 * @param {Object} data Data source to be pivot by keys.
 * @returns Object pivoted by keys
 * @see Inspired by solution presented at https://github.com/awslabs/dynamodb-document-js-sdk/issues/43 in order to perform a `Scan`operation in `searchLambda` function
 */
export const pivotUserDetails = (_type, data) => {
  if (!data) return;

  switch (_type) {
    case PIVOT_USER_DETAILS.INTERNSHIPS:
      const internshipKeys = ["title", "companyName", "startDate", "endDate", "rolesAndResponsibility"];
      return pivotByKeys(internshipKeys);

    case PIVOT_USER_DETAILS.JOBS:
      const jobsKeys = ["title", "companyName", "startDate", "endDate", "rolesAndResponsibility"];
      return pivotByKeys(jobsKeys);

    case PIVOT_USER_DETAILS.SCHOOLS:
      const schoolKeys = ["schoolName", "typeOfSchool", "associatedWithNaes", "level", "degree", "fieldOfStudy", "startMonthYear", "endMonthYear", "gradeGpa", "activities", "commentsRemarks", "academicAchievements"];
      return pivotByKeys(schoolKeys);

    case PIVOT_USER_DETAILS.COLLEGES:
      const collegeKeys = ["collegeName", "typeOfSchool", "level", "degree", "fieldOfStudy", "startMonthYear", "endMonthYear", "gradeGpa", "activities", "commentsRemarks"];
      return pivotByKeys(collegeKeys);

    case PIVOT_USER_DETAILS.CERTIFICATIONS:
      const certificationsKeys = ["certificationName", "issuedBy", "issuedDate", "expiryDate"];
      return pivotByKeys(certificationsKeys);

    default:
      break;
  }

  function pivotByKeys(attributeKeys) {
    const pivoted = attributeKeys.reduce((acc, key) => {
      data.forEach(element => {
        const val = String(element[key] ?? "").replace(PIVOT_SPECIAL_CHAR, "").toLocaleUpperCase();
        acc[key] = acc[key]?.concat([val]).join(PIVOT_SPECIAL_CHAR) ?? [val];
      });

      return acc;
    }, {});

    return pivoted;
  }
};