import React from "react";
import { Divider, Grid } from "@mui/material";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import TypographyPersonalInfoFieldTitle from "../shared/TypographyPersonalInfoFieldTitle";
import TypographyPersonalInfoField from "../shared/TypographyPersonalInfoField";
import GridItemOneColumnProfileInfo from "../shared/GridItemOneColumnProfileInfo";
import TypographyPersonalInfoGroupHeading from "../shared/TypographyPersonalInfoGroupHeading";
import { DisplaySocialMediaLink } from "./PersonalInformation";

export const PersonalInformationPublic = ({ user, socialMedia, displaySocialMedia }) => {
  return (
    <>
      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>NAME</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {`${user.firstName || ""} ${user.lastName || ""}`}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>YEARS ATTENDED IN BWS</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{user.yearsAttendedBWS}</TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>BWS GRADUATION YEAR</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{user.cohort}</TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>CITY</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{user.city}</TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>STATE</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{user.userState}</TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>
      {displaySocialMedia && (
        <>
          <Divider
            sx={{
              color: "black",
              paddingTop: 2,
              borderStyle: "dashed",
              width: "95%",
              margin: "auto",
            }}
          />
          <Grid container marginLeft={3} spacing={2} paddingTop={2}>
            <GridItemOneColumnProfileInfo>
              <TypographyPersonalInfoGroupHeading>SOCIAL NETWORK HANDLES</TypographyPersonalInfoGroupHeading>
            </GridItemOneColumnProfileInfo>
          </Grid>
          <Grid container marginLeft={3} spacing={2} paddingTop={1}>
            <DisplaySocialMediaLink linkUrl={socialMedia.linkedin} type={"LINKEDIN"} />
            <DisplaySocialMediaLink linkUrl={socialMedia.facebook} type={"FACEBOOK"} />
            <DisplaySocialMediaLink linkUrl={socialMedia.instagram} type={"INSTAGRAM"} />
            <DisplaySocialMediaLink linkUrl={socialMedia.twitter} type={"TWITTER"} />
          </Grid>
        </>
      )}
    </>
  );
};
