import { createSlice } from "@reduxjs/toolkit";

export const personSearchedSlice = createSlice({
    name: "personSearched",
    initialState: {
        personSearched: {
            firstName: "",
            lastName: "",
            isActive: true,
            email: "",
            parentEmail: "",
        }
    },

    reducers: {
        savePersonSearched: (state, action) => {
            return {
                ...state,
                personSearched: {
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    isActive: action.payload.isActive,
                    email: action.payload.email,
                    parentEmail: action.payload.parentEmail,
                }
            }
        },
        resetPersonSearched: (state, action) => {
            return {
                personSearched: {
                    firstName: "",
                    lastName: "",
                    isActive: true,
                    email: "",
                    parentEmail: "",
                }
            }
        }
    },
});

export const { savePersonSearched, resetPersonSearched } = personSearchedSlice.actions;

export default personSearchedSlice.reducer;
