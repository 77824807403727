import React from "react";
import { Divider, Grid, Link, Typography } from "@mui/material";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import TypographyPersonalInfo from "../shared/TypographyPersonalInfo";
import TypographyPersonalInfoFieldTitle from "../shared/TypographyPersonalInfoFieldTitle";
import TypographyPersonalInfoField from "../shared/TypographyPersonalInfoField";
import GridItemOneColumnProfileInfo from "../shared/GridItemOneColumnProfileInfo";
import TypographyPersonalInfoGroupHeading from "../shared/TypographyPersonalInfoGroupHeading";

export const DisplaySocialMediaLink = ({ linkUrl, type }) => {
  const addSchema = (url) => {
    if (url.substr(0, 8) === "https://" || url.substr(0, 7) === "http://") {
      return url;
    } else {
      return (url = "https://" + url);
    }
  };
  return (
    <GridItemProfileInfo>
      <TypographyPersonalInfoFieldTitle>
        {type}
      </TypographyPersonalInfoFieldTitle>
      {linkUrl && (
        <Link
          variant="subtitle2"
          href={addSchema(linkUrl)}
          target="_blank"
          sx={{ color: "black", fontSize: "0.75rem" }}
          underline="none"
        >
          {linkUrl}
        </Link>
      )}
      {!linkUrl && (
        <TypographyPersonalInfoField>- -</TypographyPersonalInfoField>
      )}
    </GridItemProfileInfo>
  );
};

function PersonalInformation({ user, socialMedia }) {
  return (
    <>
      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            NAME
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {`${user.firstName || ""} ${user.lastName || ""}`}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            PARENT/GUARDIAN EMAIL
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {user.parentEmail}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            DATE OF BIRTH
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {user.birthdate}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            PHONE NO.
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {user.phone}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            PARENT/GUARDIAN INFO(NAMES)
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {user.parentNames}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            GENDER
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {user.gender}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            RACE
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{user.race}</TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>
      <Divider
        sx={{
          color: "black",
          paddingTop: 2,
          borderStyle: "dashed",
          width: "95%",
          margin: "auto",
        }}
      />
      <Grid container marginLeft={3} spacing={2} paddingTop={2}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            YEARS ATTENDED IN BWS
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {user.yearsAttendedBWS}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            BWS GRADUATION YEAR
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {user.cohort}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            ADDRESS
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {user.address}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            CITY
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{user.city}</TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            STATE
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {user.userState}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            ZIP CODE
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {user.zipCode}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            ANNUAL INCOME (RANGE)
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {user.annualIncome}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>
      <Divider
        sx={{
          color: "black",
          paddingTop: 2,
          borderStyle: "dashed",
          width: "95%",
          margin: "auto",
        }}
      />
      <Grid container marginLeft={3} spacing={2} paddingTop={2}>
        <GridItemOneColumnProfileInfo>
          <TypographyPersonalInfoGroupHeading>
            SOCIAL NETWORK HANDLES
          </TypographyPersonalInfoGroupHeading>
        </GridItemOneColumnProfileInfo>
      </Grid>

      <Grid container marginLeft={3} spacing={2} paddingTop={1}>
        <DisplaySocialMediaLink
          linkUrl={socialMedia.linkedin}
          type={"LINKEDIN"}
        />
        <DisplaySocialMediaLink
          linkUrl={socialMedia.facebook}
          type={"FACEBOOK"}
        />
        <DisplaySocialMediaLink
          linkUrl={socialMedia.instagram}
          type={"INSTAGRAM"}
        />
        <DisplaySocialMediaLink
          linkUrl={socialMedia.twitter}
          type={"TWITTER"}
        />
      </Grid>
    </>
  );
}

export default PersonalInformation;
