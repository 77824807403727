import { Storage } from 'aws-amplify';
import { BUCKET_PROFILE_PICTURES_PATH } from '../constants/appConstants';

export const ProfilePictureApi = {
    /**
     * Downloads the profile picture, if found, of a particular `User`.
     * @param {string} userId ID of the user to retrieve profile picture.
     * @returns If found, returns the object data in Body. Otherwise, a 404 error.
     */
    downloadProfilePicture: async (userId) => {
        if (process.env.NODE_ENV === 'development') return { Body: new Blob(["fake image result"], { type: "image/jpeg" }) };

        const config = {
            level: "public",
            expires: 60, //in seconds
            download: true,
            contentType: "image/jpeg",
            validateObjectExistence: true, //with this flag, will return the file if exists or raise a 404 error if it does not.
        };

        const key = `${BUCKET_PROFILE_PICTURES_PATH}${userId}`;
        const result = await Storage.get(key, config);

        return result;
    },

    /**
     * Gets the profile picture, if found, of a particular `User`.
     * @param {string} userId ID of the user to retrieve profile picture.
     * @returns If found, returns a presigned URL of the file in the S3 bucket. Otherwise, a `404` error.
     */
    getUserProfilePicture: async (userId) => {
        if (process.env.NODE_ENV === 'development') return "fake-pre-signed-url";

        const config = {
            level: "public",
            expires: 86400, //expires in 24hs
            contentType: "image/jpeg",
            validateObjectExistence: true,
        };

        const key = `${BUCKET_PROFILE_PICTURES_PATH}${userId}`;
        const result = await Storage.get(key, config);

        return result;
    },

    /**
     * Deletes the profile picture for a given `User`.
     * @param {string} userId ID of the user to retrieve profile picture.
     * @returns Promise<DeleteObjectCommandOutput>
     */
    deleteUserProfilePicture: async (userId) => {
        if (process.env.NODE_ENV === 'development') return "fake-pre-signed-url";

        const config = {
            level: "public",
        };

        const key = `${BUCKET_PROFILE_PICTURES_PATH}${userId}`;
        const result = await Storage.remove(key, config);

        return result;
    },
};