import React from 'react'
import ActiveChatBodySkeleton from './ActiveChatBodySkeleton';
import ActiveChatHeaderSkeleton from './ActiveChatHeaderSkeleton';

function ActiveChatSkeleton() {
    return (
        <>
            <ActiveChatHeaderSkeleton />
            <ActiveChatBodySkeleton />
        </>
    )
}

export default ActiveChatSkeleton;