import { configureStore } from "@reduxjs/toolkit";
import user from "./slices/userSlice";
import alumni from "./slices/alumniSlice";
import shared from "./slices/sharedSlice";
import messaging from "./slices/messagingSlice";
import personSearched from "./slices/personSearchedSlice";
import search from "./slices/searchSlice";

export default configureStore({
  reducer: { 
    user,
    alumni,
    shared,
    messaging,
    personSearched,
    search,
  },
});
