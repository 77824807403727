import { List, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { MessagingApi } from '../../apis/MessagingApi';
import { storeChatUserBelongsTo, storeChatUserBelongsToNextToken } from '../../store/slices/messagingSlice';
import { showAlert } from '../../store/slices/sharedSlice';
import ChatListItem from './ChatListItem';
import ChatListItemLoadMore from './ChatListItemLoadMore';
import ChatListSkeleton from './ChatListSkeleton';

const RecentChats = ({ participantId }) => {
    const dispatch = useDispatch();
    const chatsUserBelongsTo = useSelector((state) => state.messaging.chatsUserBelongsTo);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (chatsUserBelongsTo.items === undefined) {
            setIsLoading(true)

            MessagingApi.listUserChats(participantId)
                .then((result) => {
                    dispatch(storeChatUserBelongsTo(result.data.chatParticipantsByParticipantId?.items || []));
                    dispatch(storeChatUserBelongsToNextToken(result.data.chatParticipantsByParticipantId?.nextToken || null));
                })
                .catch((err) => {
                    dispatch(showAlert({ message: "Could not retrieve all your chats. Please, try again.", severity: "error" }));
                })
                .finally(() => {
                    setIsLoading(false)
                });
        }

        const listenForNewChats$ = MessagingApi.listenForNewChats(participantId)?.subscribe({
            next: (value) => {
                dispatch(storeChatUserBelongsTo(value.data.onCreateChatParticipants?.items));
            },
            error: (err) => {
                dispatch(showAlert({ message: "Could not stablish a connection with the server. Please, try again.", severity: "error" }));
            }
        })


        return () => {
            listenForNewChats$?.unsubscribe();
        }
    }, []);

    const noChatsFound = <Typography variant='body1' align='center' paddingY={2}>No chats have been found.</Typography>;

    const filterChats = () => {
        if (chatsUserBelongsTo.items === undefined || chatsUserBelongsTo.items.length === 0) return noChatsFound;

        //TODO: add logic to narrow down results

        const chatItems = chatsUserBelongsTo.items.map(item => <ChatListItem key={item.id} chatParticipantItem={item} />)
            .concat(<ChatListItemLoadMore key={"load-more-chats-key"} participantId={participantId} />);
        return chatItems;
    };

    const recentChats = useMemo(() => filterChats(), [chatsUserBelongsTo.items]);

    if (isLoading) return (
        <ChatListSkeleton />
    );

    return (
        <List sx={{
            overflow: 'auto',
            maxHeight: "90%"
        }}
        >
            {recentChats}
        </List>
    )
}

export default RecentChats;