import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ProfileInformationContent from "../../profileInformation/ProfileInformationContent";
import CardRaisedRounded from "../../shared/CardRaisedRounded";
import TwoColumnsLayout from "../../shared/TwoColumnsLayout";
import AvatarContainer from "../../slotUser/AvatarContainer";
import ProfilePicture from "../../slotUser/ProfilePicture";
import ProfilePictureNotFound from "../../slotUser/ProfilePictureNotFound";
import ActionsOnUser from "./ActionsOnUser";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ProfilePictureApi } from "../../../apis/ProfilePictureApi";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPersonSearched } from "../../../store/slices/personSearchedSlice";
import ApplicationHeaderBar from "../../landingPage/AppHeaderBar";

const UserProfileDetails = ({ signOut }) => {
  let { id } = useParams();
  const [profilePicture, setProfilePicture] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUserInfo = useSelector((state) => state.user);
  const personSearched = useSelector((state) => state.personSearched.personSearched);

  const navigateBack = () => {
    navigate(-1);
    dispatch(resetPersonSearched());
  };

  useEffect(() => {
    ProfilePictureApi.getUserProfilePicture(id)
      .then((response) => setProfilePicture(response))
      .catch((err) => setProfilePicture(null));
  }, [id]);

  return (
    <>
      <ApplicationHeaderBar signOut={signOut} />
      <AppBar sx={{ backgroundColor: "#ffffff", color: "black", top: "64px", position: "sticky" }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="Navigate back button"
            onClick={navigateBack}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="body1" component="div" sx={{ textTransform: "uppercase" }}>
            Profile Information
          </Typography>
        </Toolbar>
      </AppBar>
      <TwoColumnsLayout>
        <CardRaisedRounded>
          <AvatarContainer>
            {profilePicture !== null ? (
              <ProfilePicture source={profilePicture} userProfile={personSearched} />
            ) : (
              <ProfilePictureNotFound />
            )}
          </AvatarContainer>
          {loggedInUserInfo.hasAdminAccess ? (
            <ActionsOnUser userId={id} userProfile={personSearched} hasProfilePicture={profilePicture !== null} />
          ) : null}
        </CardRaisedRounded>
        <>
          <ProfileInformationContent />
        </>
      </TwoColumnsLayout>
    </>
  );
};

export default UserProfileDetails;
