import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";

const TwoColumnsLayout = ({ children }) => {
  const [firstColumnChild, secondColumnChild] = children;
  const theme = useTheme();

  return (
    <Grid
      container
      spacing={2}
      mt="0"
      padding={theme.spacing(2)}
      bgcolor="#F1F1F1"
    >
      <Grid item xs={12} sm={12} md={3} lg={3}>
        {firstColumnChild}
      </Grid>

      <Grid item xs md lg>
        {secondColumnChild}
      </Grid>
    </Grid>
  );
};

export default TwoColumnsLayout;
