import { API } from "aws-amplify";
import professionalInformationMock from "../mocks/api/professionalInformationMock.json";
import { UserProfileApi } from "./UserProfileApi";
import { PIVOT_USER_DETAILS } from "../constants/appConstants";
import { pivotUserDetails } from "../utils/utils";

const USER_PROFILE_API_NAME = "userprofile";

export const ProfessionalInformationApi = {
  /**
   * List all School details for a given user (alumni or supporter).
   * @param {string} [userId] - ID of the user.
   * @returns A list with -TO DO-
   */
  getProfessionalData: async (userId) => {
    if (process.env.NODE_ENV === "development") return professionalInformationMock;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    return API.get(
      USER_PROFILE_API_NAME,
      `/user-profile/${userId}?ReqFor=ProfessionInfo`,
      init
    );
  },

  postProfessionalData: async (formData, userProfileId) => {
    const pivotedInternships = pivotUserDetails(PIVOT_USER_DETAILS.INTERNSHIPS, formData.internshipsTrainings) ?? null;
    const pivotedJobs = pivotUserDetails(PIVOT_USER_DETAILS.JOBS, formData.jobs) ?? null;
    const data = { ...formData, userProfileId, pivotedInternshipsTrainings: pivotedInternships, pivotedJobs: pivotedJobs };
    return UserProfileApi.patchUserProfile(data);
  },
};
