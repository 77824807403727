import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const TextFieldIcon = ({ icon, ...props }) => {
    return (
        <TextField
            fullWidth
            variant="outlined"
            size="small"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        {icon}
                    </InputAdornment>
                ),
            }}
            {...props}
        />
    );
};

export default TextFieldIcon;