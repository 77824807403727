import { Divider, Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchApi } from "../../../apis/SearchApi";
import { APP_ROLES, APP_ROUTES, CONNECTION_STATUS, NOTIFICATION_TOPICS, } from "../../../constants/appConstants";
import FiltersContainer from "../FiltersContainer";
import UserProfileCard from "../UserProfileCard";
import { showAlert, toggleLoader } from "../../../store/slices/sharedSlice";
import { useNavigate } from "react-router-dom";
import PaginationContainer from "../PaginationContainer";
import { AlumniNetworkApi } from "../../../apis/AlumniNetworkApi";
import { attributesToFilterUsersFrom, useDebounce } from "../../../utils/utils";
import { pushPageLastKey, pushUserProfiles, resetState, setLastEvaluatedKey, setPage, setPageCount, setUserProfilesFiltered } from "../../../store/slices/searchSlice";
import ContainedButton from "../../shared/ContainedButton";
import { FixedFooter } from "../../../utils/styles";
import { MessagingApi } from "../../../apis/MessagingApi";
import { NotificationApi } from "../../../apis/NotificationApi";

const SchoolAdminSearchPanel = ({ }) => {
    const dispatch = useDispatch();
    const [filters, setFilters] = useState();
    const [searchTerm, setSearchTerm] = useState(null);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [selectedAlumni, setSelectedAlumni] = useState([]);

    const page = useSelector((state) => state.search.page);
    const pageCount = useSelector((state) => state.search.pageCount);
    const pageLastKey = useSelector((state) => state.search.pageLastKey);
    const userProfiles = useSelector((state) => state.search.userProfiles);
    const userProfilesFiltered = useSelector((state) => state.search.userProfilesFiltered);
    const lastEvaluatedKey = useSelector((state) => state.search.lastEvaluatedKey);

    const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
    const navigate = useNavigate();

    const getSearchResults = () => {
        dispatch(toggleLoader({ isLoading: true }));
        SearchApi.getUserProfiles(
            userLoggedIn.userName,
            APP_ROLES.SCHOOL_ADMIN,
            filters,
            lastEvaluatedKey
        )
            .then((response) => {
                dispatch(pushUserProfiles(response.data.Items));
                dispatch(setUserProfilesFiltered([...userProfilesFiltered, ...response.data.Items]));

                dispatch(setLastEvaluatedKey(response.data.LastEvaluatedKey?.userProfileId ?? null));
            })
            .catch((err) => {
                console.error(err);
                dispatch(
                    showAlert({
                        message: "An error ocurred while retrieving list of User Profiles.",
                        severity: "error",
                    })
                );
            })
            .finally(() => {
                dispatch(toggleLoader({ isLoading: false }));
            });
    };

    useEffect(() => {
        if (userProfiles.length === 0) getSearchResults();

        return () => {
            //perform clean-up
        }
    }, []);

    useEffect(() => {
        if (!filters) return;

        getSearchResults();
    }, [filters]);

    useEffect(() => {
        const newPageCount = Math.ceil(
            userProfilesFiltered.length / resultsPerPage
        );

        if(lastEvaluatedKey){
            dispatch(pushPageLastKey({ page: newPageCount, lastKey: lastEvaluatedKey }));
            /// update pageCount based on LastEvaluatedKey. since we are performing a Scan operation, we don't know beforehand
            /// the total amount of results. so, if LastEvaluatedKey is not null, then it means there is more items to be retrieved.
            /// increment pageCount by one unit
            dispatch(setPageCount(newPageCount+1));
        } else{
            dispatch(setPageCount(newPageCount));
        }

    }, [userProfilesFiltered, lastEvaluatedKey]);

    useEffect(() => {
        //if new page is greater than the lastest one in `_pageLastKey`, a new API call is needed
        const _pairs = JSON.parse(JSON.stringify(pageLastKey));
        const last = _pairs.slice(-1)[0];
        if (last.page === 0) return;
        if (page > last.page) getSearchResults();
    }, [page]);

    const filterResultsBySearchInput = (searchInput) => {
        const searchTerm = Array.of(...searchInput.split(" ")).join("#").toString().toLocaleUpperCase();

        const searchResults = userProfiles.filter(up => attributesToFilterUsersFrom(up).includes(searchTerm));

        const newPageCount = Math.ceil(
            searchResults.length / resultsPerPage
        );
        dispatch(setPageCount(newPageCount));

        dispatch(setUserProfilesFiltered(searchResults));
    };

    const debouncedFilterBySearchInput = useDebounce(
        () => {
            filterResultsBySearchInput(searchTerm)
        }, 250);

    const handleSearchOnChange = (ev) => {
        const value = ev.target.value;

        setSearchTerm(value);

        debouncedFilterBySearchInput(value);
    };

    const handleUserProfileCardOnClick = (_userProfile) => {
        navigate(`${APP_ROUTES.PROFILE_DETAILS}/${_userProfile.userProfileId}`);
    };

    const handlePageChange = (event, newPage) => {
        dispatch(setPage(newPage));
    };

    const selectProfile = (event) => {
        const { value } = event.target;
        const index = selectedAlumni.indexOf(value);
        const updatedSelectedItems = [...selectedAlumni];

        if (index === -1) {
            updatedSelectedItems.push(value);
        } else {
            updatedSelectedItems.splice(index, 1);
        }

        setSelectedAlumni(updatedSelectedItems);
    };

    const handleConnect = async () => {
        let selectedNetwork = []; //Array to store objects to with Alumni-Connection from JSONString objects

        for (let i = 0; i < selectedAlumni.length; i++) {
            for (let j = 0; j < selectedAlumni.length; j++) {
                if (i != j) {
                    let conn = {
                        user: JSON.parse(selectedAlumni[i]),
                        connection: JSON.parse(selectedAlumni[j]),
                        connStatus: CONNECTION_STATUS.CONNECTED,
                    };
                    selectedNetwork.push(conn);
                }
            }
        }

        let uniqueListOfPairs = []
        /// create unique pari list of IDs in other to start indivudal chat between them
        for (let i = 0; i < selectedAlumni.length; i++) {
            for (let j = i + 1; j < selectedAlumni.length; j++) {
                const pair = [JSON.parse(selectedAlumni[i]).userProfileId, JSON.parse(selectedAlumni[j]).userProfileId];
                uniqueListOfPairs.push(pair);
            }
        }

        dispatch(
            showAlert({
                message: "Connections being added.",
                severity: "info",
            })
        );
        makeApiCalls(selectedNetwork, CONNECTION_STATUS.CONNECTED, uniqueListOfPairs);
    };

    //Method to create AlumniNetwork (Alumni - Connection and Connection - Alumni)
    async function makeApiCalls(selectedNetwork, connStatus, uniqueListOfPairs) {
        dispatch(toggleLoader({ isLoading: true }));
        try {
            for (let i = 0; i < selectedNetwork.length; i++) {
                let network = selectedNetwork[i];
                AlumniNetworkApi.addConnections(
                    {
                        userProfileId: network.user.userProfileId,
                        connectionProfileId: network.connection.userProfileId,
                        connectionInfo: {
                            alumniInfo: { ...network.user },
                            connInfo: { ...network.connection },                            
                        },
                        connectStatus: network.connStatus,
                    },
                    network.user.userProfileId
                )
                    .then((data) => {
                        NotificationApi.createNotification(
                            "New Connection Added",
                            `You are connected with ${network.connection.firstName} ${network.connection.lastName}.`,
                            "/my-network?subtab=connections&conntab=connected",
                            NOTIFICATION_TOPICS.NETWORK,
                            network.user.userProfileId
                          )
                            .then((data) => {})
                            .catch((err) => console.log(err));

                        ///search for that unique combination
                        const found = uniqueListOfPairs.find(p => p[0] === network.user.userProfileId && p[1] === network.connection.userProfileId);
                        if (found) {
                            ///create chat between these two participants
                            MessagingApi.startNewChat(found);
                        }
                    })
                    .catch((err) => {
                        console.log("ErrorAtAddConnections", err);
                        dispatch(
                            showAlert({
                                message: "Error occured while adding connections.",
                                severity: "error",
                            })
                        );
                    });
            }
        } catch (error) {
            dispatch(
                showAlert({
                    message: "Error occured while adding connections.",
                    severity: "error",
                })
            );
        }
        dispatch(toggleLoader({ isLoading: false }));
        dispatch(
            showAlert({
                message: "Connections added successfully.",
                severity: "success",
            })
        );
    }

    const handleSelectAll = (checked) => {
        let allSelected = [];
        if (checked) {
            userProfilesFiltered.map((user) => {
                allSelected.push(
                    JSON.stringify({
                        userProfileId: user.userProfileId,
                        firstName: user.firstName,
                        lastName: user.lastName,
                    })
                );
            });
        } else {
            allSelected = [];
        }

        setSelectedAlumni(allSelected);
    };

    const handleClickApply = (_filters) => {
        dispatch(resetState());
        setFilters(_filters);
    };

    const visibleResults = useMemo(() => userProfilesFiltered.slice((page - 1) * resultsPerPage, page * resultsPerPage), [userProfilesFiltered, page, resultsPerPage]);

    return (
        <>
            <FiltersContainer
                viewAs={APP_ROLES.SCHOOL_ADMIN}
                handleSearchOnChange={handleSearchOnChange}
                handleClickApply={handleClickApply}
            />
            <Divider sx={{ marginX: (theme) => theme.spacing(2) }} />
            <PaginationContainer
                count={pageCount}
                page={page}
                handlePageChange={handlePageChange}                
                handleSelectAll={handleSelectAll}
                hideSelectAll={false}
            />
            {visibleResults.map((up) => (
                <UserProfileCard
                    key={up.userProfileId}
                    profile={up}
                    handleCardOnClick={(ev) => handleUserProfileCardOnClick(up)}
                    selectProfile={selectProfile}
                    allSelected={selectedAlumni}
                    makeApiCalls={makeApiCalls}
                />
            ))}

            <FixedFooter>
                <Grid container>
                    <Grid item xs={12}>
                        <ContainedButton
                            disabled={selectedAlumni.length < 2}
                            label="Connect"
                            onClick={(ev) => handleConnect()}
                            color="primary"
                        />
                    </Grid>
                </Grid>     
            </FixedFooter>
           
        </>
    );
};

export default SchoolAdminSearchPanel;
