import InsertLinkIcon from "@mui/icons-material/InsertLink";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  ToggleButton,
  Tooltip,
  Divider,
} from "@mui/material";
import { useState } from "react";
import OutlinedButton from "../shared/OutlinedButton";
import ContainedButton from "../shared/ContainedButton";

const ShareLink = ({ handleAddLink, onMouseDown, active }) => {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");

  const handleOk = (event) => {
    if (url && url !== "") {
      handleAddLink(url);
      onMouseDown(event)
    }
    handleClose();
  }

  const handleClickOpen = (event) => {
    if (!active) {
      setOpen(true);
    } else {
      //This is for removing the link node
      handleAddLink();
      onMouseDown(event)
    }
  };

  const handleClose = () => {
    setOpen(false);
    setUrl("")
  };

  const handleChange = (event) => {
    setUrl(event.target.value)
  }

  return (
    <>
      <ToggleButton
        onClick={handleClickOpen}
        selected={active}
        value="none"
        sx={{ border: "none", padding: 1 }}
        color="secondary"
      >
        <Tooltip title="Attach a Link">
          <InsertLinkIcon />
        </Tooltip>
      </ToggleButton>

      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>Insert the Link</DialogTitle>
        <Divider />
        <DialogContent>
          <TextField fullWidth multiline value={url} onChange={handleChange} />
        </DialogContent>
        <Divider sx={{ marginY: (theme) => theme.spacing(1) }} />
        <DialogActions>
          <OutlinedButton label={"Cancel"} onClick={handleClose} fullWidth={false} />
          <ContainedButton label={"Add"} onClick={handleOk} fullWidth={false} autoFocus />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShareLink;
