import { useDispatch, useSelector } from "react-redux";
import ProfileInformation from "../profileInformation/ProfileInformation";
import Box from "@mui/material/Box";
import { SelectedStyledTab } from "../../utils/styles";
import { useEffect, useState } from "react";
import { AppBar, Tabs, Toolbar } from "@mui/material";
import TabPanel from "../shared/TabPanel";
import { APP_ROLES } from "../../constants/appConstants";
import MyFeed from "../feed/MyFeed";
import MyNetwork from "../network/MyNetwork";
import SearchProfile from "../search/SearchProfile";
import { useNavigate } from "react-router-dom";
import { resetState } from "../../store/slices/searchSlice";

const NavigationTabs = ({ activeTab = "my-feed" }) => {
  const [tabs, setTabs] = useState([]);
  const [value, setValue] = useState(1);
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    const tab = TAB_ITEMS.find(t => t.value === newValue);
    navigate(`/${tab.key}`);

    ///since My Network and Search Profile tabs share the same Redux's slice, clear current state on tab change
    dispatch(resetState());
  };

  ///in visibleTo property, we define which profiles should have access to.
  const TAB_ITEMS = [
    {
      key: "my-feed",
      title: "MY FEED",
      value: 1,
      index: 1,
      order: 1,
      visibleTo: [APP_ROLES.ALUMNI, APP_ROLES.PARENTS, APP_ROLES.SCHOOL_ADMIN, APP_ROLES.SUPPORTERS],
      content: <MyFeed />,
    },
    {
      key: "profile-information",
      title: "PROFILE INFORMATION",
      order: 2,
      value: 2,
      index: 2,
      visibleTo: [
        APP_ROLES.ALUMNI,
        APP_ROLES.PARENTS,
        APP_ROLES.SUPPORTERS,
      ],
      content: <ProfileInformation />,
    },
    {
      key: "my-network",
      title: "MY NETWORK",
      order: 3,
      value: 3,
      index: 3,
      visibleTo: [APP_ROLES.ALUMNI, APP_ROLES.SUPPORTERS, APP_ROLES.PARENTS],
      content: <MyNetwork />,
    },
    {
      key: "search-profile",
      title: "SEARCH PROFILE",
      order: 3,
      value: 4,
      index: 4,
      visibleTo: [APP_ROLES.SCHOOL_ADMIN],
      content: <SearchProfile />,
    },
  ];

  useEffect(() => {
    if (!userLoggedIn) return;

    //get tabs for the profile type of current user
    const tabs = [
      ...TAB_ITEMS.filter((t) =>
        t.visibleTo.includes(userLoggedIn.profileType)
      ),
    ];
    const tabsSorted = tabs.sort((a, b) => a.order - b.order);

    //update tab state
    setTabs(tabsSorted);
  }, [userLoggedIn]);

  useEffect(() => {
    //from tabs avaialble to end user, find tab by key and set its value
    const activeTabValue = TAB_ITEMS.find(t => t.key.toLowerCase() === activeTab.toLowerCase()).value;

    setValue(activeTabValue);
  }, [activeTab]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <AppBar style={{ backgroundColor: "#ffffff", color: "black", top: "64px", position: "sticky", }} >
        <Toolbar>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="user top nav bar"
            sx={{ backgroundColor: "#fff" }}
          >
            {tabs.map((t) => (
              <SelectedStyledTab
                selected
                label={t.title}
                key={t.key}
                value={t.value}
                sx={{ height: "64px" }}  //height hardcoded to match height from AppBar component
              />
            ))}
          </Tabs>
        </Toolbar>
      </AppBar>

      {tabs.map((t) => (
        <TabPanel
          key={t.key}
          value={t.value}
          index={t.index}
          ishidden={t.value !== value}
        >
          {t.content}
        </TabPanel>
      ))}
    </Box>
  );
};

export default NavigationTabs;


