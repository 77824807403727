import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AlumniNetworkApi } from "../../apis/AlumniNetworkApi";
import NetworkUserProfileCard from "./NetworkUserProfileCard";
import { APP_ROLES, APP_ROUTES } from "../../constants/appConstants";
import { useNavigate } from "react-router-dom";
import { UserProfileApi } from "../../apis/UserProfileApi";
import { Box, Typography } from "@mui/material";

const RequestedNetwork = ({ anyPendingRequest }) => {
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const alumniSelected = useSelector((state) => state.alumni.alumniSelected);
  const [requestedUsers, setRequestedUsers] = useState([]);
  const navigate = useNavigate();
  const handleUserProfileCardOnClick = (_userProfileId) => {
    navigate(`${APP_ROUTES.PROFILE_DETAILS}/${_userProfileId}`);
  };
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    let userId = "";
    if (userLoggedIn.profileType == APP_ROLES.PARENTS) {
      userId = alumniSelected.alumniId;
    } else {
      userId = userLoggedIn.userName;
    }
    UserProfileApi.getUserById(userId, "UserInfoForNetwork")
      .then((userInfo) =>
        userInfo && userInfo.data.length > 0
          ? setUserInfo(userInfo.data[0])
          : setUserInfo(null)
      )
      .catch((err) => console.log("Error while fetching userInfo", err));
  }, [userLoggedIn, alumniSelected]);

  useEffect(() => {
    let userId = "";
    if (userLoggedIn.profileType == APP_ROLES.PARENTS) {
      userId = alumniSelected.alumniId;
    } else {
      userId = userLoggedIn.userName;
    }
    AlumniNetworkApi.getConnections(
      userId,
      null,
      "RequestPending"
    )
      .then((resp) => {
        anyPendingRequest(resp.data.length > 0);
        setRequestedUsers(resp.data);
      })
      .catch((err) => console.log(err));
  }, [userLoggedIn, alumniSelected]);
  return (
    <>
      {requestedUsers.length === 0 && <Box m={1} pt={1}><Typography variant="body2">You don't have any connection request.</Typography></Box>}
      {requestedUsers.map((up) => (
        <NetworkUserProfileCard
          key={up.connectionProfileId}
          profile={up.connectionInfo.connInfo}
          handleCardOnClick={(ev) => handleUserProfileCardOnClick(up.connectionInfo.connInfo.userProfileId)}
          makeApiCalls={null}
          currentUserInfo={userInfo}
          showApproveDeny={true}
        />
      ))}
    </>
  );
};

export default RequestedNetwork;
