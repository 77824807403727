import { Typography } from "@mui/material";

const TypographyPersonalInfoField = ({ children }) => {
  let data;
  if (children) {
    if (isNaN(children)) data = children.trim() || "--";
    else data = children;
  } else {
    data = "--";
  }
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{ color: "black", fontSize: "0.75rem" }}
      >
        {data}
      </Typography>
    </>
  );
};

export default TypographyPersonalInfoField;
