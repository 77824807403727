import { appErrorMessages } from "../../constants/appConstants";
import { Navigate } from "react-router-dom";

const UnAuthorizedUser = (props) => {
  const { loggedInUser } = props;
  const errorStyle = {
    fontSize: "3rem",
    textAlign: "center",
    display: "block",
    paddingTop: "2rem",
  };

  return (
    <>
      <span style={errorStyle}>{appErrorMessages.UnAuthorizedMsg}</span>
      {loggedInUser && loggedInUser.authorized && (
        <Navigate to={`${props.location.state.path}`} />
      )}
    </>
  );
};

export default UnAuthorizedUser;
