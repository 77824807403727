import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
const SelectControl = ({ label, menuItems, ...props }) => {
  const minWidth = "12rem";
  const fontsize = "0.75rem";

  return (
    <>
      <FormControl variant="outlined" sx={{ width: minWidth }}>
        <InputLabel
          style={{ fontSize: fontsize, textTransform: "uppercase" }}
          id={`id_${label}`}
        >
          {label}
        </InputLabel>
        <Select
          size="small"
          labelId={`id_${label}`}
          label={`lbl_${label}`}
          sx={{
            "& .MuiSelect-select": {
              fontSize: fontsize,
              color: "black",
            },
          }}
          {...props}
        >
          {menuItems.map((menu, index) => (
            <MenuItem
              key={index}
              sx={{ fontSize: fontsize, color: "black" }}
              value={menu.value}
            >
              {menu.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default SelectControl;
