import React, { useState } from "react";
import { DialogPopup, TitleWrapper } from "../../utils/styles";
import { DialogActions, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { NOTIFICATION_TOPICS } from "../../constants/appConstants";
import { RichTextEditor } from "./RichTextEditor";
import { saveISO8601Time } from "../../utils/utils";
import { EventApi } from "../../apis/EventApi";
import EventFormFields from "./EventFormFields";
import TextButton from "../shared/TextButton";
import { NotificationApi } from "../../apis/NotificationApi";
import OutlinedButton from "../shared/OutlinedButton";
import ContainedButton from "../shared/ContainedButton";

function CreateEventForm({ open, onClose, setEventList }) {
  const [formData, setFormData] = useState({
    eventTitle: "",
    eventType: "",
    eventTime: "",
    eventDate: "",
    address: "",
  });
  const [message, setMessage] = useState([{ children: [{ text: "" }] }]);
  const [imageS3Key, setImageS3Key] = useState();
  const [fileS3Key, setFileS3Key] = useState();
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updateValue = value;
    let updateError = { ...errors };
    if (value.length >= 50) {
      updateValue = value.slice(0, 50);
      updateError[name] = "Maximum length exceeded (50 characters)";
    } else {
      updateValue = value;
      delete updateError[name];
    }
    setErrors(updateError);
    setFormData({ ...formData, [name]: updateValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(toggleLoader({ isLoading: true }));
    try {
      const eventData = {
        eventTitle: formData.eventTitle,
        eventDate: formData.eventDate,
        eventTime: formData.eventTime,
        eventType: formData.eventType,
        address: formData.address,
        createdBy: `${userLoggedIn.firstName} ${userLoggedIn.lastName}`,
        createdAt: saveISO8601Time(),
        message: JSON.stringify(message),
        imageS3Key: imageS3Key ? imageS3Key : null,
        fileS3Key: fileS3Key ? fileS3Key : null,
        editedAt: "",
      };
      await EventApi.postEvent(eventData).then((response) => {
        setEventList((prev) => [eventData, ...prev]);

        NotificationApi.createNotification(
          "New Event!",
          `A new event was recently made by ${userLoggedIn.firstName} ${userLoggedIn.lastName}`,
          `${window.location.pathname}?eventNumber=${response.data.body.eventNumber}`,
          NOTIFICATION_TOPICS.ADMINEVENT
        )
          .then((data) => {})
          .catch((err) => console.log(err));

          ///Record a new event to trigger the Pinpoint Journey which notifies all users about this new event
          NotificationApi.recordNewEvent("schoolAdminPostedNewEvent");
      });
      dispatch(
        showAlert({
          message: "Event created successfully!",
          severity: "success",
        })
      );
      dispatch(toggleLoader({ isLoading: false }));
      closeForm();
    } catch (error) {
      dispatch(
        showAlert({
          message:
            "An error ocurred while submitting your form data." + error.message,
          severity: "error",
        })
      );
      dispatch(toggleLoader({ isLoading: false }));
    }
  };

  const closeForm = () => {
    setFormData({});
    setMessage([{ children: [{ text: "" }] }]);
    setImageS3Key(null);
    setFileS3Key(null);
    onClose();
  };

  const handleOnSuccessUploadImage = ({ key }) => {
    setImageS3Key(key);
    dispatch(
      showAlert({
        message: "Your image was uploaded successfully",
        severity: "success",
      })
    );
  };

  const handleOnSuccessUploadFile = ({ key }) => {
    fileS3Key ? setFileS3Key([...fileS3Key, key]) : setFileS3Key([key]);
    dispatch(
      showAlert({
        message: "Your file was uploaded successfully",
        severity: "success",
      })
    );
  };

  const isFormValid = () => {
    const requiredFields = [
      "eventTitle",
      "eventDate",
      "eventTime",
      "eventType",
      "address",
    ];
    const validFields = requiredFields.some(
      (field) => !formData[field] || formData[field].length > 50
    );
    const hasErrors = Object.keys(errors).length > 0;
    return !validFields && !hasErrors;
  };

  return (
    <DialogPopup open={open} onClose={closeForm}>
      <DialogTitle>
        <TitleWrapper>
          Create Event
          <IconButton onClick={closeForm}>
            <Close />
          </IconButton>
        </TitleWrapper>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <EventFormFields
          handleChange={handleChange}
          errors={errors}
          formData={formData}
        />
        <RichTextEditor
          message={message}
          onChange={(e) => setMessage(e)}
          handleOnSuccessUploadImage={handleOnSuccessUploadImage}
          handleOnSuccessUploadFile={handleOnSuccessUploadFile}
        />
        {imageS3Key && (
          <TextButton
            label={`Sharing "${imageS3Key.split("_")[0].slice(7)}"`}
            endIcon={<Close />}
            onClick={() => setImageS3Key(null)}
          />
        )}
        {fileS3Key &&
          fileS3Key.map((file, index) => (
            <TextButton
              key={index}
              label={`Sharing "${file.split("_")[0].slice(7)}"`}
              endIcon={<Close />}
              onClick={() => setFileS3Key([])}
            />
          ))}
        <DialogActions>
          <OutlinedButton
            type="reset"
            label={"Back"}
            onClick={closeForm}
            fullWidth={false}
          />
          <ContainedButton
            type="submit"
            label={"Create"}
            onClick={handleSubmit}
            disabled={!isFormValid()}
            fullWidth={false}
          />
        </DialogActions>
      </form>
    </DialogPopup>
  );
}

export default CreateEventForm;
