import profilePic from "../../assets/profileSample.jpg";
import { Avatar } from '@mui/material';

const ProfilePictureNotFound = () => {

    return (
        <Avatar
            alt="Avatar profile picture"
            src={profilePic}
            sx={{
                width: 275,
                height: 275,
            }}
        />
    );
}

export default ProfilePictureNotFound;