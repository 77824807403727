import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItemButton, ListItemText, ListSubheader, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import ContainedButton from '../../shared/ContainedButton';
import OutlinedButton from '../../shared/OutlinedButton';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { TitleWrapper } from '../../../utils/styles';
import CategoryWentToCollege from './CategoryWentToCollege';
import CategoryName from './CategoryName';
import CategoryProfession from './CategoryProfession';
import CategoryEducation from './CategoryEducation';
import CategoryActivity from './CategoryActivity';
import CategoryEmail from './CategoryEmail';

const FILTERS = {
    COLLEGE: "college",
    NAME: "name",
    PROFESSION: "profession",
    EDUCATION: "education",
    ACTIVITY: "activity",
    EMAIL: "email",
}

export const SUBCATEGORIES = {
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
}

export const initialStateFilters = {
    wentToCollege: { value: null },
    firstName: { value: new Set() },
    lastName: { value: new Set() },
    profession: { value: new Set() },
    education: { value: new Set() },
    activity: { value: new Set() },
    email: { value: new Set() },
}

function MoreFiltersDialog({ isOpen, handleOnClose, handleOnAdd }) {
    const [filtersApplied, setFiltersApplied] = useState(initialStateFilters);
    const [activeCategory, setActiveCategory] = useState(null);

    useEffect(() => {

        return () => {
            setActiveCategory(null)
        }
    }, [isOpen]);

    const categories = useMemo(() => [
        {
            name: FILTERS.COLLEGE,
            label: "Went to college?",
        },
        {
            name: FILTERS.NAME,
            label: "Name",
        },
        {
            name: FILTERS.EMAIL,
            label: "Email",
        },
        {
            name: FILTERS.PROFESSION,
            label: "Profession",
        },
        {
            name: FILTERS.EDUCATION,
            label: "Education",
        },
        {
            name: FILTERS.ACTIVITY,
            label: "Activity",
        },
    ], []);

    const noActiveFilter = <Typography variant='h6' align='center' paddingY={2}>Select a category to add filters.</Typography>;

    const handleOnClickCancel = () => {
        handleOnClose();
        ///lifting state up
        handleOnAdd(filtersApplied);
    };

    const handleOnClickAdd = () => {
        handleOnClose();
        ///lifting state up
        handleOnAdd(filtersApplied);
    };

    const handleOnClickCategory = (category) => {
        setActiveCategory(category.name)
    };

    const handleOnClickReset = () => {
        setFiltersApplied(initialStateFilters)
        setActiveCategory(null);
    };

    const updateWentToCollege = (selectedOption) => {
        setFiltersApplied((prevState) => {
            const newState = {
                ...prevState,
                wentToCollege: { value: selectedOption }
            };
            return newState;
        });
    };

    const updateName = (subcategory, names) => {
        switch (subcategory) {
            case SUBCATEGORIES.FIRST_NAME:
                setFiltersApplied((prevState) => {
                    const newState = {
                        ...prevState,
                        firstName: { value: new Set([...names]) }
                    };
                    return newState;
                })
                break;
            case SUBCATEGORIES.LAST_NAME:
                setFiltersApplied((prevState) => {
                    const newState = {
                        ...prevState,
                        lastName: { value: new Set([...names]) }
                    };
                    return newState;
                })
                break;

            default:
                break;
        }
    };

    const updateEmail = (emails) => {
        setFiltersApplied((prevState) => {
            const newState = {
                ...prevState,
                email: { value: new Set([...emails]) }
            };
            return newState;
        })
    };

    const updateProfession = (professions) => {
        setFiltersApplied((prevState) => {
            const newState = {
                ...prevState,
                profession: { value: new Set([...professions]) }
            };
            return newState;
        })
    };

    const updateEducation = (educations) => {
        setFiltersApplied((prevState) => {
            const newState = {
                ...prevState,
                education: { value: new Set([...educations]) }
            };
            return newState;
        })
    };

    const updateActivity = (activities) => {
        setFiltersApplied((prevState) => {
            const newState = {
                ...prevState,
                activity: { value: new Set([...activities]) }
            };
            return newState;
        })
    };

    const activeFilterComponent = useMemo(() => {
        switch (activeCategory) {
            case FILTERS.COLLEGE:
                {
                    const initialValue = filtersApplied.wentToCollege.value;
                    return <CategoryWentToCollege initial={initialValue} updateFiltersApplied={updateWentToCollege} />
                }
            case FILTERS.NAME:
                {
                    const initialValueFirstName = filtersApplied.firstName.value;
                    const initialValueLastName = filtersApplied.lastName.value;
                    return <CategoryName initialFirstName={initialValueFirstName} initialLastName={initialValueLastName} updateFiltersApplied={updateName} />
                }
            case FILTERS.EMAIL:
                {
                    const initialValue = filtersApplied.email.value;
                    return <CategoryEmail initial={initialValue} updateFiltersApplied={updateEmail} />
                }
            case FILTERS.PROFESSION:
                {
                    const initialValue = filtersApplied.profession.value;
                    return <CategoryProfession initial={initialValue} updateFiltersApplied={updateProfession} />
                }
            case FILTERS.EDUCATION:
                {
                    const initialValue = filtersApplied.education.value;
                    return <CategoryEducation initial={initialValue} updateFiltersApplied={updateEducation} />
                }
            case FILTERS.ACTIVITY:
                {
                    const initialValue = filtersApplied.activity.value;
                    return <CategoryActivity initial={initialValue} updateFiltersApplied={updateActivity} />
                }
            default:
                return noActiveFilter;
        }
    }, [activeCategory]);

    return (
        <Dialog open={isOpen} maxWidth={"lg"} fullWidth PaperProps={{ sx: { minHeight: "90vh" } }} onClose={handleOnClickCancel}>
            <DialogTitle
                sx={{
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)",
                    marginBottom: (theme) => theme.spacing(3)
                }}
            >
                <TitleWrapper>
                    Add More Filters
                    <IconButton sx={{ marginLeft: 2 }} color="primary" onClick={handleOnClickCancel}>
                        <CloseIcon />
                    </IconButton>
                </TitleWrapper>
            </DialogTitle>
            <DialogContent>
                <Paper elevation={3} sx={{ height: 1 }}>
                    <Grid container direction="row" justifyContent="center" alignItems="stretch">
                        <Grid item xs={3}
                            sx={{
                                backgroundColor: "#F7F7F7",
                            }}>
                            <List>
                                <ListSubheader disableSticky>
                                    <Stack direction={"row"} spacing={2} justifyContent="space-between" alignItems="center">
                                        <Typography variant='subtitle1' color={(theme) => theme.palette.text} bgcolor={"#F7F7F7"}>
                                            Filter by
                                        </Typography>
                                        <Tooltip title="Reset filters" placement='top'>
                                            <IconButton aria-label="Reset filters" onClick={handleOnClickReset}>
                                                <FilterAltOffIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </ListSubheader>

                                {categories.map((cat) => (
                                    <ListItemButton key={cat.name} selected={cat.name === activeCategory} divider onClick={(ev) => handleOnClickCategory(cat)}>
                                        <Typography variant='subtitle2' color={(theme) => theme.palette.text}>
                                            {cat.label}
                                        </Typography>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Grid>
                        <Grid item xs={9}>
                            <Paper sx={{ height: 1 }} variant='outlined' square>
                                {activeFilterComponent}
                            </Paper>
                        </Grid>
                    </Grid>
                </Paper>
            </DialogContent>
            <Divider sx={{ marginY: (theme) => theme.spacing(1) }} />
            <DialogActions>
                {/* <OutlinedButton label={"Back"} onClick={handleOnClickCancel} fullWidth={false} /> */}
                <ContainedButton label={"Add"} onClick={handleOnClickAdd} fullWidth={false} />
            </DialogActions>
        </Dialog>
    );
}

export default MoreFiltersDialog;