/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      messages {
        items {
          chatId
          content {
            text
          }
          sender {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          sentAt
          updatedAt
          messageSenderId
        }
        nextToken
      }
      participants {
        items {
          id
          chatId
          participantId
          chat {
            id
            createdAt
            updatedAt
          }
          participant {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      messages {
        items {
          chatId
          content {
            text
          }
          sender {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          sentAt
          updatedAt
          messageSenderId
        }
        nextToken
      }
      participants {
        items {
          id
          chatId
          participantId
          chat {
            id
            createdAt
            updatedAt
          }
          participant {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      messages {
        items {
          chatId
          content {
            text
          }
          sender {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          sentAt
          updatedAt
          messageSenderId
        }
        nextToken
      }
      participants {
        items {
          id
          chatId
          participantId
          chat {
            id
            createdAt
            updatedAt
          }
          participant {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      chatId
      content {
        text
        image {
          key
        }
        attachments {
          key
        }
      }
      sender {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status {
        readBy {
          participantId
          at
        }
      }
      sentAt
      updatedAt
      messageSenderId
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      chatId
      content {
        text
        image {
          key
        }
        attachments {
          key
        }
      }
      sender {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status {
        readBy {
          participantId
          at
        }
      }
      sentAt
      updatedAt
      messageSenderId
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      chatId
      content {
        text
        image {
          key
        }
        attachments {
          key
        }
      }
      sender {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      status {
        readBy {
          participantId
          at
        }
      }
      sentAt
      updatedAt
      messageSenderId
    }
  }
`;
export const createParticipant = /* GraphQL */ `
  mutation CreateParticipant(
    $input: CreateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    createParticipant(input: $input, condition: $condition) {
      id
      firstName
      lastName
      profilePictureKey
      isSchoolAdmin
      isActive
      chats {
        items {
          id
          chatId
          participantId
          chat {
            id
            createdAt
            updatedAt
          }
          participant {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateParticipant = /* GraphQL */ `
  mutation UpdateParticipant(
    $input: UpdateParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    updateParticipant(input: $input, condition: $condition) {
      id
      firstName
      lastName
      profilePictureKey
      isSchoolAdmin
      isActive
      chats {
        items {
          id
          chatId
          participantId
          chat {
            id
            createdAt
            updatedAt
          }
          participant {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteParticipant = /* GraphQL */ `
  mutation DeleteParticipant(
    $input: DeleteParticipantInput!
    $condition: ModelParticipantConditionInput
  ) {
    deleteParticipant(input: $input, condition: $condition) {
      id
      firstName
      lastName
      profilePictureKey
      isSchoolAdmin
      isActive
      chats {
        items {
          id
          chatId
          participantId
          chat {
            id
            createdAt
            updatedAt
          }
          participant {
            id
            firstName
            lastName
            profilePictureKey
            isSchoolAdmin
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      title
      description
      navLink
      type
      createdAt
      read
      seen
      topic
      notificationForUserId
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      title
      description
      navLink
      type
      createdAt
      read
      seen
      topic
      notificationForUserId
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      title
      description
      navLink
      type
      createdAt
      read
      seen
      topic
      notificationForUserId
      updatedAt
    }
  }
`;
export const createChatParticipants = /* GraphQL */ `
  mutation CreateChatParticipants(
    $input: CreateChatParticipantsInput!
    $condition: ModelChatParticipantsConditionInput
  ) {
    createChatParticipants(input: $input, condition: $condition) {
      id
      chatId
      participantId
      chat {
        id
        messages {
          items {
            chatId
            sentAt
            updatedAt
            messageSenderId
          }
          nextToken
        }
        participants {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      participant {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChatParticipants = /* GraphQL */ `
  mutation UpdateChatParticipants(
    $input: UpdateChatParticipantsInput!
    $condition: ModelChatParticipantsConditionInput
  ) {
    updateChatParticipants(input: $input, condition: $condition) {
      id
      chatId
      participantId
      chat {
        id
        messages {
          items {
            chatId
            sentAt
            updatedAt
            messageSenderId
          }
          nextToken
        }
        participants {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      participant {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatParticipants = /* GraphQL */ `
  mutation DeleteChatParticipants(
    $input: DeleteChatParticipantsInput!
    $condition: ModelChatParticipantsConditionInput
  ) {
    deleteChatParticipants(input: $input, condition: $condition) {
      id
      chatId
      participantId
      chat {
        id
        messages {
          items {
            chatId
            sentAt
            updatedAt
            messageSenderId
          }
          nextToken
        }
        participants {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      participant {
        id
        firstName
        lastName
        profilePictureKey
        isSchoolAdmin
        isActive
        chats {
          items {
            id
            chatId
            participantId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
