import Button from "@mui/material/Button";

const OutlinedButton = ({ label, ...props }) => {

    return (
        <Button
        sx={{
            height: "40px",
            textTransform: "none"
        }}
        variant="outlined"
        {...props}
        >
            {label}
        </Button>
    );
};

export default OutlinedButton;