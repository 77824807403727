import { React, useState } from "react";
import { Grid } from "@mui/material";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import FieldText from "../shared/FieldText";
import SelectControl from "../shared/Select";
import { SCHOOL_LEVEL, TYPE_OF_SCHOOL } from "../../constants/appConstants";

export const AddCollegeForm = ({ arrayData, handleChange }) => {
  const [college, setCollege] = useState(arrayData);
  const [errors, setErrors] = useState({});

  const onChange = (e) => {
    const { name, value } = e.target;
    let updateValue = value;
    let updateError = { ...errors };
    if(name === "commentsRemarks" || name === "activities"){
      if(value.length >= 200){
        updateValue = value.slice(0, 200);
        updateError[name] = "Maximum length exceeded (200 characters)";
      }else {
        updateValue = value;
        delete updateError[name];
      }
    }
    else if (value.length >= 50) {
      updateValue = value.slice(0, 50);
      updateError[name] = "Maximum length exceeded (50 characters)";
    } else {
      updateValue = value;
      delete updateError[name];
    }
    setErrors(updateError);
    handleChange(e);
    setCollege({ ...college, [name]: updateValue });
  };

  return (
    <>
      <Grid container spacing={2} marginLeft={2} paddingTop={2}>
        <GridItemProfileInfo>
          <FieldText
            name="collegeName"
            label="COLLEGE/UNIVERSITY NAME"
            type="text"
            error={!college.collegeName || !!errors.collegeName}
            helperText={errors.collegeName}
            defaultValue={college.collegeName || ""}
            onChange={(e) => onChange(e)}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <SelectControl
            menuItems={TYPE_OF_SCHOOL}
            name="typeOfSchool"
            onChange={(e) => onChange(e)}
            value={college.typeOfSchool}
            label="TYPE OF SCHOOL"
          ></SelectControl>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <SelectControl
            menuItems={SCHOOL_LEVEL}
            name="level"
            onChange={(e) => onChange(e)}
            value={college.level}
            label="LEVEL"
          ></SelectControl>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="degree"
            label="DEGREE"
            type="text"
            error={!!errors.degree}
            helperText={errors.degree}
            onChange={(e) => onChange(e)}
            defaultValue={college.degree || ""}
          />
        </GridItemProfileInfo>
      </Grid>
      <Grid container spacing={2} marginLeft={2} paddingTop={2}>
        <GridItemProfileInfo>
          <FieldText
            name="fieldOfStudy"
            label="FIELD OF STUDY"
            type="text"
            error={!!errors.fieldOfStudy}
            helperText={errors.fieldOfStudy}
            onChange={(e) => onChange(e)}
            defaultValue={college.fieldOfStudy || ""}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="startMonthYear"
            label="START DATE"
            type="date"
            onChange={(e) => onChange(e)}
            defaultValue={college.startMonthYear || ""}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="endMonthYear"
            label="END DATE"
            type="date"
            onChange={(e) => onChange(e)}
            defaultValue={college.endMonthYear || ""}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="gradeGpa"
            label="GRADE/GPA"
            type="text"
            onChange={(e) => onChange(e)}
            defaultValue={college.gradeGpa || ""}
          />
        </GridItemProfileInfo>
      </Grid>
      <Grid container spacing={2} marginLeft={2} paddingTop={2}>
        <GridItemProfileInfo>
          <FieldText
            name="activities"
            label="ACTIVITIES"
            type="text"
            error={!!errors.activities}
            helperText={errors.activities}
            multiline
            minRows={3}
            onChange={(e) => onChange(e)}
            defaultValue={college.activities || ""}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="commentsRemarks"
            label="COMMENTS/REMARKS"
            type="text"
            error={!!errors.commentsRemarks}
            helperText={errors.commentsRemarks}
            multiline
            minRows={3}
            onChange={(e) => onChange(e)}
            defaultValue={college.commentsRemarks || ""}
          />
        </GridItemProfileInfo>
      </Grid>
    </>
  );
};
