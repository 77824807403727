import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, TextField } from '@mui/material';
import OutlinedButton from '../../shared/OutlinedButton';
import ContainedButton from '../../shared/ContainedButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { TitleWrapper } from '../../../utils/styles';

function ActionToggleIsActive({ isOpen, actionDetail, handleOnClose }) {
    const [reason, setReason] = useState("");

    useEffect(() => {
        //clear any previously value
        setReason("");
    }, [isOpen])

    const handleOnCancel = () => {
        handleOnClose(undefined);
    };

    const handleOnConfirm = () => {
        handleOnClose(reason);
    };

    return (
        <Dialog open={isOpen} maxWidth={"md"} fullWidth onClose={handleOnCancel}>
            <DialogTitle
                sx={{
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)",
                    marginBottom: (theme) => theme.spacing(3)
                }}
            >
                <TitleWrapper>
                    Confirm {actionDetail}
                    <IconButton
                        aria-label="Close confirmation dialog"
                        sx={{
                            marginLeft: 2
                        }}
                        color="primary"
                        onClick={handleOnCancel}
                    >
                        <CloseIcon />
                    </IconButton>
                </TitleWrapper>
            </DialogTitle>
            <DialogContent>
                <DialogContentText color={"text"} sx={{ marginBottom: (theme) => theme.spacing(2) }}>
                    Please, enter the reason to {actionDetail}
                </DialogContentText>
                <TextField id="reason" type="text" name='reason' label="Reason" value={reason} onChange={(event) => setReason(event.target.value)}
                    margin="normal" variant="outlined" fullWidth autoFocus multiline maxRows={5} required
                    error={reason === "" || reason.trimStart() === ""}
                    helperText={"This field is required. Blank spaces are not allowed."} />
            </DialogContent>
            <Divider sx={{ marginY: (theme) => theme.spacing(1) }} />
            <DialogActions>
                <OutlinedButton label={"Cancel"} onClick={handleOnCancel} fullWidth={false} />
                <ContainedButton label={"Confirm"} onClick={handleOnConfirm} fullWidth={false} disabled={reason === "" || reason.trimStart() === ""} />
            </DialogActions>
        </Dialog>
    );
};

export default ActionToggleIsActive;