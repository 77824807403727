import { API } from "aws-amplify";
import educationInformationMock from "../mocks/api/educationInformationMock.json";
import { UserProfileApi } from "./UserProfileApi";
import { pivotUserDetails } from "../utils/utils";
import { PIVOT_USER_DETAILS } from "../constants/appConstants";

const USER_PROFILE_API_NAME = "userprofile";

export const EducationInformationData = {
  /**
   * List all School details for a given user (alumni or supporter).
   * @param {string} [userId] - ID of the user.
   * @returns A List with the attributes of the user
   */
  getEducationInformation: async (userId) => {
    if (process.env.NODE_ENV === "development") return educationInformationMock;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    return API.get(
      USER_PROFILE_API_NAME,
      `/user-profile/${userId}?ReqFor=EducationInfo`,
      init
    );
  },

  postEducationInformation: async (formData, userProfileId) => {
    if (process.env.NODE_ENV === "development") return;
    
    const pivotedSchool = pivotUserDetails(PIVOT_USER_DETAILS.SCHOOLS, formData.schoolDetails) ?? null;
    const pivotedCollege = pivotUserDetails(PIVOT_USER_DETAILS.COLLEGES, formData.collegeDetails) ?? null;
    const pivotedCertification = pivotUserDetails(PIVOT_USER_DETAILS.CERTIFICATIONS, formData.certificationDetails) ?? null;
    
    const data = { ...formData, userProfileId, pivotedShoolDetails: pivotedSchool, pivotedCollegeDetails: pivotedCollege, pivotedCertificationDetails: pivotedCertification };

    return UserProfileApi.patchUserProfile(data);
  },
};
