import React, { useState } from "react";
import { DialogPopup, TitleWrapper } from "../../utils/styles";
import { Button, DialogActions, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { RichTextEditor } from "./RichTextEditor";
import { convertISOToCustomFormat } from "../../utils/utils";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { EventApi } from "../../apis/EventApi";
import EventFormFields from "./EventFormFields";
import TextButton from "../shared/TextButton";
import { saveISO8601Time } from "../../utils/utils";

const EditEventForm = ({ open, onClose, eventToUpdate, handleEventEdit }) => {
  const [formData, setFormData] = useState(eventToUpdate);
  const [message, setMessage] = useState([{ children: [{ text: "" }] }]);
  const [imageS3Key, setImageS3Key] = useState();
  const [fileS3Key, setFileS3Key] = useState();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updateValue = value;
    let updateError = { ...errors };
    if (value.length >= 50) {
      updateValue = value.slice(0, 50);
      updateError[name] = "Maximum length exceeded (50 characters)";
    } else {
      updateValue = value;
      delete updateError[name];
    }
    setErrors(updateError);
    setFormData({ ...formData, [name]: updateValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(toggleLoader({ isLoading: true }));
    try {
      const updateEvent = {
        eventTitle: formData.eventTitle,
        eventDate: formData.eventDate,
        eventTime: formData.eventTime,
        eventType: formData.eventType,
        address: formData.address,
        eventId: eventToUpdate.eventId,
        eventNumber: eventToUpdate.eventNumber,
        message: JSON.stringify(message),
        imageS3Key: imageS3Key ? imageS3Key : null,
        fileS3Key: fileS3Key ? fileS3Key : null,
        editedAt: saveISO8601Time(),
        createdAt: eventToUpdate.createdAt,
      };

      await EventApi.updateEvent(updateEvent);
      handleEventEdit(updateEvent); //Update in local store

      dispatch(
        showAlert({
          message: "Event edited successfully!",
          severity: "success",
        })
      );
      dispatch(toggleLoader({ isLoading: false }));
      closeForm();
    } catch (error) {
      dispatch(
        showAlert({
          message:
            "An error ocurred while submitting your form data." + error.message,
          severity: "error",
        })
      );
      dispatch(toggleLoader({ isLoading: false }));
    }
  };

  const closeForm = () => {
    // setFormData({});
    // setMessage([{ children: [{ text: "" }] }]);
    // setImageS3Key(null);
    // setFileS3Key(null);
    onClose();
  };

  const handleOnSuccessUploadImage = ({ key }) => {
    setImageS3Key(key);
    dispatch(
      showAlert({
        message: "Your image was uploaded successfully",
        severity: "success",
      })
    );
  };

  const handleOnSuccessUploadFile = ({ key }) => {
    fileS3Key ? setFileS3Key([...fileS3Key, key]) : setFileS3Key([key]);
    dispatch(
      showAlert({
        message: "Your file was uploaded successfully",
        severity: "success",
      })
    );
  };

  const isFormValid = () => {
    const requiredFields = [
      "eventTitle",
      "eventDate",
      "eventTime",
      "eventType",
      "address",
    ];
    const validFields = requiredFields.some(
      (field) => !formData[field] || formData[field].length > 50
    );

    const hasErrors = Object.keys(errors).length > 0;

    return !validFields && !hasErrors;
  };

  return (
    <DialogPopup open={open} onClose={closeForm}>
      <DialogTitle>
        <TitleWrapper>
          Edit Event
          <IconButton onClick={closeForm}>
            <Close />
          </IconButton>
        </TitleWrapper>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <EventFormFields
          event={eventToUpdate}
          handleChange={handleChange}
          errors={errors}
          formData={formData}
        />
        <RichTextEditor
          message={JSON.parse(eventToUpdate.message)}
          onChange={(e) => setMessage(e)}
          handleOnSuccessUploadImage={handleOnSuccessUploadImage}
          handleOnSuccessUploadFile={handleOnSuccessUploadFile}
        />
        {imageS3Key && (
          <TextButton
            label={`Sharing "${imageS3Key.split("_")[0].slice(7)}"`}
            endIcon={<Close />}
            onClick={() => setImageS3Key(null)}
          />
        )}
        {fileS3Key &&
          fileS3Key.map((file, index) => (
            <TextButton
              key={index}
              label={`Sharing "${file.split("_")[0].slice(7)}"`}
              endIcon={<Close />}
              onClick={() => setFileS3Key([])}
            />
          ))}
        <DialogActions>
          <Button
            type="reset"
            variant="outlined"
            color="secondary"
            onClick={closeForm}
          >
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!isFormValid()}
            onClick={handleSubmit}
          >
            Edit
          </Button>
        </DialogActions>
      </form>
    </DialogPopup>
  );
};

export default EditEventForm;
