import React from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { MessagingApi } from '../../apis/MessagingApi';
import { storeChatUserBelongsTo, storeChatUserBelongsToNextToken } from '../../store/slices/messagingSlice';
import { showAlert } from '../../store/slices/sharedSlice';

function ChatListItemLoadMore({ participantId }) {
    const dispatch = useDispatch();
    const chatsUserBelongsTo = useSelector((state) => state.messaging.chatsUserBelongsTo);

    const handleOnClick = () => {
        MessagingApi.listMoreUserChats(participantId, chatsUserBelongsTo.nextToken)
            .then((result) => {
                dispatch(storeChatUserBelongsTo(result.data.chatParticipantsByParticipantId?.items || []));
                dispatch(storeChatUserBelongsToNextToken(result.data.chatParticipantsByParticipantId?.nextToken || null));
            })
            .catch((err) => {
                dispatch(showAlert({ message: "Could not retrieve more chats. Please, try again.", severity: "error" }));
            })
            .finally(() => {
            });
    };

    if (chatsUserBelongsTo.nextToken === null) return (
        <></>
    );

    return (
        <ListItemButton divider={true} onClick={handleOnClick}>
            <ListItemAvatar>
                <Avatar>
                    <AddCircleOutlineIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Load more chats" />
        </ListItemButton>
    );
}

export default ChatListItemLoadMore;