import { React } from "react";
import { InputField } from "../../utils/styles";

export const AdminAndSupportersForm = (props) => {
  return (
    <>
      <InputField
        id="given_name"
        name="given_name"
        label="First Name"
        type="text"
        variant="outlined"
        size="medium"
        onChange={props.onChange}
        error={!!props.errors.given_name}
        helperText={props.errors.given_name}
        required
      />
      <InputField
        id="family_name"
        name="family_name"
        label="Last Name"
        type="text"
        variant="outlined"
        size="medium"
        onChange={props.onChange}
        error={!!props.errors.family_name}
        helperText={props.errors.family_name}
        required
      />
      <InputField
        id="email"
        name="email"
        label="Email Address"
        type="email"
        variant="outlined"
        size="medium"
        onChange={props.onChange}
        error={!!props.errors.email}
        helperText={props.errors.email}
        required
      />
      <InputField
        id="birthdate"
        name="birthdate"
        label="Date of Birth"
        type="Date"
        variant="outlined"
        size="medium"
        onChange={props.onChange}
        InputLabelProps={{ shrink: true }}
        error={!!props.errors.birthdate}
        helperText={props.errors.birthdate}
        required
      />
    </>
  );
};
