import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import MessageHistoryLoader from "./MessageHistoryLoader";

function MessageHistory({ messages, nextToken, handleLoadMoreMessages }) {

  const loadMoreMessages =
    nextToken !== null ? (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip title="Load more messages">
          <IconButton
            aria-label="Load more messages"
            onClick={handleLoadMoreMessages}
          >
            <AddCircleOutlineIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Box>
    ) : (
      <></>
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column-reverse",
        height: "40vh",
        overflow: "auto",
      }}
    >
      {messages?.map((message, index) => (
        <MessageHistoryLoader key={message.sentAt} message={message} />
      ))}
      {loadMoreMessages}
    </div>
  );
}

export default MessageHistory;
