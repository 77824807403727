import { Search } from "@mui/icons-material";
import {
  Grid,
  InputAdornment,
  OutlinedInput,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectChat, updateSearchInput } from "../../store/slices/messagingSlice";
import ActiveChat from "./ActiveChat";
import ChatWithAdmins from "./ChatWithAdmins";
import RecentChats from "./RecentChats";

function MessageTabContainer() {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);

  const handleTabChange = (_, value) => {
    setTab(value);
  };

  const onSearchInputChat = (_input) => {
    setSearchInput(_input);
    dispatch(updateSearchInput(_input.trim()));
  };

  useEffect(() => {

    return () => {
      //clear active chat on "close"
      dispatch(selectChat({ chatId: null }));
    }
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "#CBCBCB" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              flexBasis: "20%",
              BorderRight: 1,
              borderColor: "#CBCBCB",
              backgroundColor: "#F7F7F7",
              mt: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{ ml: 2, fontWeight: "bold" }}
            >
              Chat
            </Typography>
            <OutlinedInput
              value={searchInput}
              onChange={(ev) => onSearchInputChat(ev.target.value)}
              variant="filled"
              size="small"
              placeholder="Search"
              sx={{
                width: "60%",
                marginLeft: 2,
                marginBottom: 1,
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={12} sm={8} sx={{ width: "80%", paddingLeft: 2 }}>
            <Typography
              variant="h6"
              sx={{ paddingTop: "3rem", fontWeight: "bold" }}
            >
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "#CBCBCB" }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              width: "20%",
              borderRight: 1,
              borderColor: "#CBCBCB",
              backgroundColor: "#F7F7F7",
              paddingTop: 2,
              height: "60vh"
            }}
          >
            <Tabs
              value={tab}
              onChange={handleTabChange}
              indicatorColor="secondary"
              variant="fullWidth"
              sx={{ "& .MuiTabs-indicator": { backgroundColor: "#590006" } }}
            >
              <Tab
                label="Recent"
                sx={{
                  textTransform: "none",
                  "&.Mui-selected": { fontWeight: "bold", color: "black" },
                }}
              />
              <Tab
                label="Admin"
                sx={{
                  textTransform: "none",
                  "&.Mui-selected": { fontWeight: "bold", color: "black" },
                }}
              />
            </Tabs>
            {tab === 0 && <RecentChats participantId={userLoggedIn.userName} />}
            {tab === 1 && <ChatWithAdmins />}
          </Grid>
          <Grid item xs={12} sm={8} sx={{ width: "80%", paddingLeft: 2 }}>
            <ActiveChat />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default MessageTabContainer;
