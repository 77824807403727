import React from "react";
import EventCard from "./EventCard";

const EventCardMapping = ({ eventList, handleEventEdit, handleDelete }) => {
  return (
    <>
      {eventList &&
        eventList?.map((event) => (
          <EventCard
            key={event.eventNumber}
            eventToUpdate={event} 
            handleEventEdit={handleEventEdit}  
            handleDelete={handleDelete}         
          />
        ))}
    </>
  );
};

export default EventCardMapping;
