import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";

const SecondaryTabs = ({ tabs, tabSelected, handleChange }) => {

  return (
    <Box sx={{ width: "100%", p: 0, borderBottom: 1, borderColor: 'divider', }}>
      <Tabs
        variant="scrollable"
        value={tabSelected}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="Secondary tabs"
      >
        {tabs.map(t => (
          (t.badgeCount)
            ? (
              <Tab
                sx={{ textTransform: "capitalize" }}
                key={t.key}
                label={
                  <Badge color="error" badgeContent={t.badgeCount} max={99}>
                    {t.label} &nbsp;&nbsp;
                  </Badge>
                }
              />
            )
            : (
              <Tab sx={{ textTransform: "capitalize" }} key={t.key} label={t.label} />
            )

        ))}
      </Tabs>
    </Box>
  );
}

export default SecondaryTabs;