import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { MessagingApi } from "../../apis/MessagingApi";
import { showAlert } from "../../store/slices/sharedSlice";
import ActiveChatBodySkeleton from "./ActiveChatBodySkeleton";
import MessageHistory from "./MessageHistory";
import { Storage } from "aws-amplify";

function ActiveChatBody({ chatId }) {
  const dispatch = useDispatch();
  const [recentMessages, setRecentMessages] = useState([]);
  const [messagesNextToken, setMessagesNextToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const messagesRef = useRef([]); //setting up a `ref` since in `listenForNewMessages` subscription, we cannot access to lastest `recentMessages`' state.

  const getAttachmentName = (attachment) => {
    //e.g: folder1/announcements/_image.png_1684787553262.png
    const fileNameWithUniqueId = attachment.split('/').pop(); //_image.png_1684787553262.png
    const delimiterIndex = fileNameWithUniqueId.lastIndexOf('_');
    const originalName = fileNameWithUniqueId.slice(0, delimiterIndex); //_image.png
    return originalName;
  }

  const parseItemToMessage = async (item) => {
    let imageURL = "";
    let filesURL = [];
    if (item.content.image) {
      imageURL = await Storage.get(item.content.image.key);
    }
    if (item.content.attachments) {
      const attachmentPromises = item.content.attachments.map(async (e) => {
        const urlFromFile = Storage.get(e.key);
        return { name: getAttachmentName(e.key), url: await urlFromFile };
      });
      filesURL = await Promise.all(attachmentPromises);
    }
    return {
      text: JSON.parse(item.content.text),
      image: imageURL,
      attachments: filesURL,
      sender: item.messageSenderId,
      profilePicture: item.sender.profilePictureKey,
      sentAt: item.sentAt,
    };
  };

  useEffect(() => {
    //start listen for new messages
    const listenForNewMessages$ = MessagingApi.listenForNewMessages(
      chatId
    )?.subscribe({
      next: async ({ provider, value }) => {
        //append new messages to Chat.
        const item = value.data?.onCreateMessage;
        const newMessage = await parseItemToMessage(item);
        ///add new message to the beginning of the list so that it appears as last in `MessageHistory` component.
        const newRecentMessages = [newMessage, ...messagesRef.current];
        messagesRef.current = newRecentMessages;
        setRecentMessages(newRecentMessages);
      },
      error: (err) => {
        dispatch(
          showAlert({
            message:
              "Could not stablish a connection with the server. Please, try again.",
            severity: "error",
          })
        );
      },
    });

    return () => {
      //close existing subscription
      listenForNewMessages$?.unsubscribe();
    };
  }, [chatId]);

  const handleLoadMoreMessages = () => {
    setIsLoading(true);

    MessagingApi.listMoreRecentMessagesByChat(chatId, messagesNextToken)
      .then(async (response) => {
        const messages = response.data?.listMessages.items.map((c) =>
          parseItemToMessage(c)
        );
        const messageData = await Promise.all(messages);

        ///concat more messages to the end of the list so that it appears as first in `MessageHistory` component.
        const addedMoreMessages = [...recentMessages, ...messageData];
        setRecentMessages(addedMoreMessages);
        messagesRef.current = addedMoreMessages;

        setMessagesNextToken(response.data?.listMessages.nextToken);
      })
      .catch((error) => {
        dispatch(
          showAlert({
            message:
              "An error ocurred while fetching more messages. Please, try again." + error.message,
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (chatId === null) return;

    setIsLoading(true);
    MessagingApi.listRecentMessagesByChat(chatId)
      .then(async (response) => {
        const messages = response.data?.listMessages.items.map((c) =>
          parseItemToMessage(c)
        );
        const messageData = await Promise.all(messages);
        setRecentMessages(messageData);
        messagesRef.current = messageData;

        setMessagesNextToken(response.data?.listMessages.nextToken);
      })
      .catch((error) => {
        dispatch(
          showAlert({
            message:
              "An error ocurred while fetching most recent messages. Please, try again." + error.message,
            severity: "error",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [chatId]);

  if (isLoading) return <ActiveChatBodySkeleton />;

  return (
    <MessageHistory
      messages={recentMessages}
      nextToken={messagesNextToken}
      handleLoadMoreMessages={handleLoadMoreMessages}
    />
  );
}

export default ActiveChatBody;
