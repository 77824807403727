import { createSlice } from "@reduxjs/toolkit";
import { ProfilePictureApi } from "../../apis/ProfilePictureApi";

export const SharedSlice = createSlice({
    name: "shared",
    initialState: {
        loader: {
            isLoading: false,
            variant: "indeterminate",
            color: "inherit",
        },
        alertSnackbar: {
            isOpen: false,
            message: "",
            vertical: "top",
            horizontal: "center",
            severity: "info",
            hideAfter: 6000
        },
        profilePictures: {
            items: []
        },
    },

    reducers: {
        toggleLoader: (state, action) => {
            return {
                ...state,
                loader: {
                    ...state.loader,
                    ...action.payload,
                }
            };
        },
        showAlert: (state, action) => {
            return {
                ...state,
                alertSnackbar: {
                    ...state.alertSnackbar,
                    isOpen: true,
                    ...action.payload
                }
            };
        },
        hideAlert: (state, action) => {
            return {
                ...state,
                alertSnackbar: {
                    ...state.alertSnackbar,
                    isOpen: false,
                    ...action.payload
                }
            };
        },
        saveProfilePictures: (state, action) => {
            return {
                ...state,
                profilePictures: {
                    items: [...state.profilePictures.items, action.payload]
                }
            }
        },
    },
});

export const { toggleLoader, showAlert, hideAlert, saveProfilePictures } = SharedSlice.actions;

export default SharedSlice.reducer;

export const fetchUserProfilePicture = (user) => (dispatch) => {
    ProfilePictureApi.getUserProfilePicture(user.id)
        .then((result) => {
            const payload = {
                key: user.id,
                value: {
                    id: user.id,
                    profilePictureKey: result,
                    firstName: user.firstName,
                    lastName: user.lastName,
                }
            };
            dispatch(saveProfilePictures(payload));
        })
        .catch((error) => {
            const payload = {
                key: user.id,
                value: {
                    id: user.id,
                    profilePictureKey: null,
                    firstName: user.firstName,
                    lastName: user.lastName,
                }
            };
            dispatch(saveProfilePictures(payload));
        });
};