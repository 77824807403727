import React from "react";
import { Grid } from "@mui/material";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import TypographyPersonalInfoField from "../shared/TypographyPersonalInfoField";
import TypographyPersonalInfoFieldTitle from "../shared/TypographyPersonalInfoFieldTitle";
export const CollegeDetails = ({ arrayData }) => {
  return (
    <>
      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            COLLEGE/UNIVERSITY NAME
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.collegeName}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            TYPE OF SCHOOL
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.typeOfSchool}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            LEVEL
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.level}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            DEGREE
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.degree}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>
      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            FIELD OF STUDY
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.fieldOfStudy}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            START MONTH, YEAR
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.startMonthYear}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            END MONTH, YEAR
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.endMonthYear}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            GRADE/GPA
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.gradeGpa}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>
      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            ACTIVITIES
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.activities}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            COMMENTS/REMARKS
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {arrayData.commentsRemarks}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>
    </>
  );
};
