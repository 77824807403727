import { Box, CardContent } from "@mui/material";
import CardRaisedRounded from "../shared/CardRaisedRounded";
import OneColumnLayout from "../shared/OneColumnLayout";
import SchoolAdminSearchPanel from "./schoolAdmin/SchoolAdminSearchPanel";

const SearchProfile = ({ hideOnNetworkTab = false }) => {
  return (
    <OneColumnLayout>
      <CardRaisedRounded>
        <CardContent sx={{}}>
          <Box sx={{ width: "100%" }}>
            <SchoolAdminSearchPanel hideOnNetworkTab={hideOnNetworkTab} />
          </Box>
        </CardContent>
      </CardRaisedRounded>
    </OneColumnLayout>
  );
};

export default SearchProfile;
