import { React } from "react";
import { ToggleButton, Tooltip } from "@mui/material";
import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatUnderlined,
} from "@mui/icons-material";
import ShareImageUpload from "../shared/ShareImageUpload";
import ShareFileUpload from "../shared/ShareFileUpload";
import ShareLink from "./ShareLink";
import { BUCKET_ANNOUNCEMENTS_PATH } from "../../constants/appConstants";

export const SpecialButtons = ({
  type,
  active,
  onMouseDown,
  handleAddLink,
  onSuccessUploadImage,
  onSuccessUploadFile,
  onErrorUpload,
  bucketPath
}) => {
  const renderIcon = () => {
    switch (type) {
      case "bold":
        return (
          <ToggleButton
            value="none"
            sx={{ border: "none", padding: 1 }}
            color="secondary"
            selected={active}
            onClick={onMouseDown}
          >
            <Tooltip title="Bold">
              <FormatBold />
            </Tooltip>
          </ToggleButton>
        );
      case "italic":
        return (
          <ToggleButton
            value="none"
            sx={{ border: "none", padding: 1 }}
            color="secondary"
            selected={active}
            onClick={onMouseDown}
          >
            <Tooltip title="Italic">
              <FormatItalic />
            </Tooltip>
          </ToggleButton>
        );
      case "underline":
        return (
          <ToggleButton
            value="none"
            sx={{ border: "none", padding: 1 }}
            color="secondary"
            selected={active}
            onClick={onMouseDown}
          >
            <Tooltip title="Underline">
              <FormatUnderlined />
            </Tooltip>
          </ToggleButton>
        );
      case "number":
        return (
          <ToggleButton
            value="none"
            sx={{ border: "none", padding: 1 }}
            color="secondary"
            selected={active}
            onClick={onMouseDown}
          >
            <Tooltip title="Numbered">
              <FormatListNumbered />
            </Tooltip>
          </ToggleButton>
        );
      case "bullet":
        return (
          <ToggleButton
            value="none"
            sx={{ border: "none", padding: 1 }}
            color="secondary"
            selected={active}
            onClick={onMouseDown}
          >
            <Tooltip title="Bulleted">
              <FormatListBulleted />
            </Tooltip>
          </ToggleButton>
        );
      case "link":
        return (
          <ShareLink
            onMouseDown={(event) => onMouseDown(event)}
            handleAddLink={(url) => handleAddLink(url)}
            active={active}
          />
        );
      case "image":
        return <ShareImageUpload onSuccessUpload={onSuccessUploadImage} onErrorUpload={onErrorUpload} bucketPath={bucketPath}/>;
      case "file":
        return <ShareFileUpload onSuccessUpload={onSuccessUploadFile} onErrorUpload={onErrorUpload} bucketPath={bucketPath}/>;
      default:
        break;
    }
  };

  return <>{renderIcon()}</>;
};
