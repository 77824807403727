import React from "react";
import { useParams } from "react-router-dom";
import NavigationTabs from "./NavigationTabs";
import ApplicationHeaderBar from "./AppHeaderBar";

const LandingPage = ({ signOut }) => {
  let { activeTab } = useParams();

  return (
    <>
      <ApplicationHeaderBar signOut={signOut} />
      <NavigationTabs activeTab={activeTab}/>
    </>
  );
};

export default LandingPage;
