import { Box, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import CardRaisedRounded from "../shared/CardRaisedRounded";
import OneColumnLayout from "../shared/OneColumnLayout";
import SecondaryTabs from "../shared/SecondaryTabs";
import TabPanel from "../shared/TabPanel";
import SearchPanel from "../search/SearchPanel";
import MyConnections from "./MyConnections";
import { useDispatch } from "react-redux";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { AlumniNetworkApi } from "../../apis/AlumniNetworkApi";
import { useSearchParams } from "react-router-dom";

const MyNetwork = ({}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  let [searchParams, setSearchParams] = useSearchParams();
  let subtab = searchParams.get("subtab") || "search"

  const networkTabs = [
    {
      key: "search",
      value: 0, //used to show or hide tab panel
      label: "Search Connection",
    },
    {
      key: "connections",
      value: 1,
      label: "My Connections",
    },
  ];

  useEffect(() => {
    const activeTabValue = networkTabs.find(t => t.key.toLowerCase() === subtab.toLowerCase()).value;
    setActiveTab(activeTabValue);
  }, [subtab]);


  const handleTabChange = (event, newValue) => {    
    searchParams.delete("subtab"); 
    searchParams.delete("conntab");   
    setSearchParams(searchParams);
    
    setActiveTab(newValue);
  };

  async function makeApiCalls(selectedNetwork) {
    dispatch(toggleLoader({ isLoading: true }));
    try {
      for (let i = 0; i < selectedNetwork.length; i++) {
        let network = selectedNetwork[i];
        await AlumniNetworkApi.addConnections(
          {
            userProfileId: network.user.userProfileId,
            connectionProfileId: network.connection.userProfileId,
            connectionInfo: {
              alumniInfo: { ...network.user },
              connInfo: { ...network.connection },
            },
            connectStatus: network.connStatus,
          },
          network.user.userProfileId
        )
          .then((data) => {})
          .catch((err) => {
            console.log("ErrorAtAddConnections", err);
            dispatch(
              showAlert({
                message: "Error occured while adding connections.",
                severity: "error",
              })
            );
          });
      }
    } catch (error) {
      console.error(error);
      dispatch(
        showAlert({
          message: "Error occured while adding connections.",
          severity: "error",
        })
      );
    }
    dispatch(toggleLoader({ isLoading: false }));
    dispatch(
      showAlert({
        message: "Connections added successfully.",
        severity: "success",
      })
    );
  }

  return (
    <OneColumnLayout>
      <CardRaisedRounded>
        <CardContent sx={{ paddingY: (theme) => theme.spacing(0) }}>
          <Box sx={{ width: "100%" }}>
            <SecondaryTabs
              tabs={networkTabs}
              tabSelected={activeTab}
              handleChange={handleTabChange}
            />

            <TabPanel
              value={activeTab}
              index={0}
              ishidden={networkTabs[0].value !== activeTab}
            >
              <Box sx={{ width: "100%" }}>
                <SearchPanel makeApiCalls={makeApiCalls} />
              </Box>
            </TabPanel>
            <TabPanel
              value={activeTab}
              index={1}
              ishidden={networkTabs[1].value !== activeTab}
            >
              <MyConnections makeApiCalls={makeApiCalls} />
            </TabPanel>
          </Box>
        </CardContent>
      </CardRaisedRounded>
    </OneColumnLayout>
  );
};

export default MyNetwork;
