import React from "react";
import { EVENT_TYPE } from "../../constants/appConstants";
import { DialogContent, MenuItem, TextField } from "@mui/material";

const EventFormFields = ({
  event,
  handleChange,
  errors,
  formData,
}) => {
  const currentDate = new Date().toISOString().split("T")[0];

  return (
    <DialogContent>
      <div
        style={{
          display: "flex",
          width: "60vw",
          height: "10vh",
          margin: "2rem",
          alignItems: "center",
        }}
      >
        <TextField
          id="eventTitle"
          name="eventTitle"
          label="Event title"
          type="text"
          error={!!errors.eventTitle}
          helperText={errors.eventTitle}
          defaultValue={event ? event.eventTitle : formData.eventTitle}
          variant="outlined"
          onChange={(e)=>handleChange(e)}
          fullWidth
          required
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          margin: "2rem",
          gap: "2rem",
          justifyContent: "space-between",
          width: "60vw",
        }}
      >
        <TextField
          id="eventDate"
          name="eventDate"
          label="Date"
          type="date"
          defaultValue={event ? event.eventDate : formData.eventDate}
          helperText={errors.eventTitle}
          error={!!errors.eventDate}
          variant="outlined"
          onChange={(e)=>handleChange(e)}
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: currentDate }}
          fullWidth
          required
        />
        <TextField
          id="eventTime"
          name="eventTime"
          label="Time"
          type="time"
          defaultValue={event ? event.eventTime : formData.eventTime}
          helperText={errors.eventTitle}
          error={!!errors.eventTime}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(e)=>handleChange(e)}
          fullWidth
          required
        />
        <TextField
          id="eventType"
          name="eventType"
          defaultValue={event ? event.eventType : ""}
          variant="outlined"
          fullWidth
          onChange={(e)=>handleChange(e)}
          label="Select type"
          select
          required
        >
          <MenuItem value={EVENT_TYPE.INPERSON}>In-Person</MenuItem>
          <MenuItem value={EVENT_TYPE.ONLINE}>Online</MenuItem>
        </TextField>
      </div>
      <div
        style={{
          display: "flex",
          width: "60vw",
          margin: "2rem",
          alignItems: "center",
        }}
      >
        <TextField
          id="address"
          name="address"
          label="Address"
          type="text"
          helperText={errors.eventTitle}
          error={!!errors.address}
          defaultValue={event ? event.address : formData.address}
          variant="outlined"
          onChange={(e)=>handleChange(e)}
          fullWidth
          autoComplete="false"
          required
        />
      </div>
    </DialogContent>
  );
};

export default EventFormFields;
