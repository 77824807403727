import { API } from "aws-amplify";
import alumniProfilesMock from "../mocks/api/alumni_profile_mock.json";

const ALUMNI_API_NAME = "alumni";

export const AlumniApi = {
  /**
   * List all Alumni profiles for a given parent.
   * @param {string} [parentId] - ID of the parent user.
   * @returns A list with at least one Alumni profile for the given parent user.
   */
  getAlumniProfilesForParent: async (parentId) => {
    if (process.env.NODE_ENV === "development") return alumniProfilesMock;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    return API.get(ALUMNI_API_NAME, `/parent/${parentId}`, init);
  },

  postAlumniParentProfile: async (alumniData, parentId) => {
    if (process.env.NODE_ENV === "development") return alumniProfilesMock;

    const relationData = alumniData.map((child) => {
      return {
        parentId: parentId,
        alumniId: child.alumniId,
        firstName: child.given_name,
        lastName: child.family_name,
      };
    });

    relationData.forEach((rel) => {
      const init = {
        headers: {},
        response: true,
        body: rel,
      };

      return API.post(ALUMNI_API_NAME, "/parent", init);
    });
  },
};
