import { React, useState } from "react";
import { Grid } from "@mui/material";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import FieldText from "../shared/FieldText";

export const AddCertificationForm = ({ arrayData, handleChange }) => {
  const [certification, setCertification] = useState(arrayData);
  const [errors, setErrors] = useState({});

  const onChange = (e) => {
    const { name, value } = e.target;
    let updateValue = value;
    let updateError = { ...errors };

    if (value.length >= 50) {
      updateValue = value.slice(0, 50);
      updateError[name] = "Maximum length exceeded (50 characters)";
    } else {
      updateValue = value;
      delete updateError[name];
    }
    setErrors(updateError);
    handleChange(e);
    setCertification({ ...certification, [name]: updateValue });
  };

  return (
    <>
      <Grid container spacing={2} marginLeft={2} paddingTop={2}>
        <GridItemProfileInfo>
          <FieldText
            name="certificationName"
            label="CERTIFICATION NAME"
            type="text"
            error={!certification.certificationName || !!errors.certificationName}
            helperText={errors.certificationName}
            defaultValue={certification.certificationName || ""}
            onChange={(e) => onChange(e)}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="issuedBy"
            label="ISSUED BY"
            type="text"
            error={!!errors.issuedBy}
            helperText={errors.issuedBy}
            defaultValue={certification.issuedBy || ""}
            onChange={(e) => onChange(e)}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="issuedDate"
            label="ISSUED DATE"
            type="date"
            defaultValue={certification.issuedDate || ""}
            onChange={(e) => onChange(e)}
          />
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <FieldText
            name="expiryDate"
            label="EXPIRY DATE"
            type="date"
            defaultValue={certification.expiryDate || ""}
            onChange={(e) => onChange(e)}
          />
        </GridItemProfileInfo>
      </Grid>
    </>
  );
};
