import { Box, Typography } from "@mui/material";

const AdminDetails = ({ admin }) => {
  return (
    <Box
      sx={{
        width: 1,

        paddingX: (theme) => theme.spacing(2),

        color: (theme) => theme.palette.white.contrastText,
      }}
    >
      <Typography variant="body2" align="justify" noWrap={true} gutterBottom>
        Name: {admin.firstName} {admin.lastName}
      </Typography>

      <Typography variant="body2" align="justify" noWrap={true} gutterBottom>
        Email: {admin.email}
      </Typography>
    </Box>
  );
};

export default AdminDetails;
