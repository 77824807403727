import { EventAvailable, GetApp, MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import RichtTextReadOnly from "./RichtTextReadOnly";
import { EventApi } from "../../apis/EventApi";
import { useDispatch, useSelector } from "react-redux";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { formatTime } from "../../utils/utils";
import { APP_ROLES } from "../../constants/appConstants";
import { Storage } from "aws-amplify";
// import Atendees from "./Atendees";
import TextButton from "../shared/TextButton";
import EditEventForm from "./EditEventForm";

const EventCard = ({ eventToUpdate, handleEventEdit, handleDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editingEvent, setEditingEvent] = useState(false);
  // const [viewAttendees, setViewAttendees] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [filesUrl, setFilesUrl] = useState([]);
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const dispatch = useDispatch();

  const handleDotsOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleDotsClose = () => {
    setAnchorEl(null);
    setEditingEvent(false);
  };

  const openEditMode = () => {
    setEditingEvent(true);
  };

  const handleDeleteEvent = async (eventId, eventNumber) => {
    dispatch(toggleLoader({ isLoading: true }));
    try {
      await EventApi.deleteEvent(eventId, eventNumber);
      handleDelete(eventNumber);
    } catch (error) {
      dispatch(
        showAlert({
          message: "An error ocurred while deleting the event." + error.message,
          severity: "error",
        })
      );
      dispatch(toggleLoader({ isLoading: false }));
    } finally {
      dispatch(
        showAlert({
          message: "Event deleted successfully.",
          severity: "success",
        })
      );
      dispatch(toggleLoader({ isLoading: false }));
    }
  };

  const getFiles = async () => {
    if (eventToUpdate.imageS3Key) {
      let imageURL = "";
      imageURL = await Storage.get(eventToUpdate.imageS3Key);
      setImageUrl(imageURL);
    }
    if (eventToUpdate.fileS3Key?.length > 0) {
      let filesURL = [];
      const attachmentPromises = eventToUpdate.fileS3Key.map(async (file) => {
        return { name: file, url: await Storage.get(file) };
      });
      filesURL = await Promise.all(attachmentPromises);
      setFilesUrl(filesURL);
    }
  };

  useEffect(() => {
    getFiles();
  }, []);

  const copyToClipboard = () => {
    const link = `${window.location.origin}${window.location.pathname}?eventNumber=${eventToUpdate.eventNumber}`;
    window.navigator.clipboard
      .writeText(link)
      .then(() => {
        dispatch(
          showAlert({
            message: "Link copied to clipboard!",
            severity: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          showAlert({
            message: "Error copying the link to clipboard!",
            severity: "error",
          })
        );
      });
  };

  return (
    <Card
      sx={{
        border: "1px solid #cccccc85",
        borderRadius: 2,
        boxShadow: 1,
        marginY: 3,
        p: 2,
      }}
    >
      <Box
        sx={{
          maxwidth: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            sx={{
              backgroundColor: "#f7f7f7",
              cursor: "auto",
              boxShadow: " 0px 3px 6px #00000029",
            }}
          >
            <EventAvailable sx={{ fontSize: "3rem", color: "black" }} />
          </IconButton>
          <Box>
            <Typography sx={{ paddingTop: 0.5, paddingX: 2, color: "grey" }}>
              {eventToUpdate.eventTitle}
            </Typography>
            <Box sx={{ display: "flex", paddingX: 2 }}>
              <Typography
                sx={{ marginRight: 0.5, fontSize: 12, color: "grey" }}
              >
                {eventToUpdate.createdBy}
              </Typography>
              <Typography sx={{ fontSize: 12, color: "grey" }}>
                {eventToUpdate.editedAt ? "- Edited" : null}
              </Typography>
            </Box>
          </Box>
        </Box>
        {userLoggedIn.profileType === APP_ROLES.SCHOOL_ADMIN && (
          <Box>
            <IconButton
              aria-controls="menu"
              aria-haspopup="true"
              onClick={handleDotsOpen}
            >
              <MoreHoriz />
            </IconButton>
            <Menu
              id="menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleDotsClose}
            >
              <MenuItem onClick={() => openEditMode()}>Edit</MenuItem>
              <MenuItem
                onClick={() =>
                  handleDeleteEvent(
                    eventToUpdate.eventId,
                    eventToUpdate.eventNumber
                  )
                }
              >
                Delete
              </MenuItem>
            </Menu>
            <EditEventForm
              open={editingEvent}
              onClose={() => handleDotsClose()}
              eventToUpdate={eventToUpdate}
              handleEventEdit={handleEventEdit}
            />
          </Box>
          // ) : (
          //   /*This option was hardcoded for future implementation. Go to Attendees.jsx file for more information */
          //   <Button color="primary" variant="outlined" size="small" sx={{ height: "3rem", textTransform: "none" }}>
          //     Accept Event
          //   </Button>
        )}
      </Box>
      <Box padding={2}>
        <Typography>{`${eventToUpdate.eventDate} ${formatTime(
          eventToUpdate.eventTime
        )}`}</Typography>
        <Typography>{eventToUpdate.address}</Typography>
        <Typography>{eventToUpdate.eventType}</Typography>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        > */}
        {/*This option was hardcoded for future implementation. Go to Attendees.jsx file for more information */}
        {/* <Typography>Atendees</Typography>
          <Typography
            sx={{
              cursor: "pointer",
              color: "blue",
              ml: 1,
              fontSize: "12px",
            }}
            onClick={() => setViewAttendees(true)}
          >
            View all
          </Typography>
          <Atendees open={viewAttendees} onClose={() => setViewAttendees(false)} event={eventToUpdate} />
        </div> */}
        <RichtTextReadOnly message={JSON.parse(eventToUpdate.message)} />
        {imageUrl && (
          <Box
            sx={{
              marginY: 1,
              display: "flex",
            }}
          >
            <img
              src={imageUrl}
              alt="Shared image"
              style={{ cursor: "pointer" }}
            />
          </Box>
        )}
        {filesUrl &&
          filesUrl?.map((file, index) => (
            <Box
              key={index}
              sx={{
                marginTop: 1,
                display: "flex",
              }}
            >
              <Link
                href={file.url}
                target="_blank"
                download
                underline="none"
                color="secondary"
                display="flex"
                alignItems="center"
                paddingBottom={3}
              >
                <GetApp /> {`${file.name}`}
              </Link>
            </Box>
          ))}
      </Box>
      <Divider />
      <CardActions sx={{ padding: 0, marginTop: 0.5 }}>
        <TextButton onClick={() => copyToClipboard()} label={"Share"} />
      </CardActions>
    </Card>
  );
};

export default EventCard;
