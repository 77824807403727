import { StorageManager } from '@aws-amplify/ui-react-storage';
import { Image } from "@mui/icons-material";
import { Tooltip, IconButton, Dialog, DialogTitle } from "@mui/material";
import { useState } from "react";

const ShareImageUpload = ({ onSuccessUpload, onErrorUpload, bucketPath }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const processFile = ({ file }) => {
    const uniqueId = Date.now(); //timeStamp as unique Id
    const originalName = file.name; 
    const fileExtension = originalName.split('.').pop();
    const newKey = `${originalName}_${uniqueId}.${fileExtension}`;
    return { file, key: newKey };
  };

  return (
    <>
      <Tooltip title="Attach an image">
        <IconButton onClick={handleClickOpen}>
          <Image />
        </IconButton>
      </Tooltip>

      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>Upload image</DialogTitle>

        <StorageManager
          acceptedFileTypes={["image/*"]}
          accessLevel="public"
          isResumable={false}
          maxFileCount={1}
          maxSize={7000000} //equals to 3MB. to be defined
          onUploadSuccess={onSuccessUpload}
          onUploadError={onErrorUpload}
          path={bucketPath}
          processFile={processFile}
          shouldAutoProceed={true}
          showThumbnails={true}
        />
      </Dialog>
    </>
  );
};

export default ShareImageUpload;