import Avatar from "@mui/material/Avatar";
import PersonIcon from '@mui/icons-material/Person';
import FaceIcon from '@mui/icons-material/Face';
import Face3Icon from '@mui/icons-material/Face3';
import { APP_GENDERS } from "../../constants/appConstants";
import Box from "@mui/material/Box";

const defaultOnClick = () => { return; }

/**
 * An user profile Avatar to display its profile picture. If there is an error loading the avatar image, it falls back an alternative in the following order:
 * * the provided children
 * * the first letter of the alt text
 * * a generic avatar icon
 * @param {string} source - Source of the image to be displayed in the Avatar
 * @param {object} userProfile - Details of the user profile. It should have, at least, first name, last name and gender
 * @param {object} handleAvatarOnClick - Optional. Used when it's required capture and handle an `onClick` event on the Avatar
 * @returns A React component to display user profile's picture
 */
const UserProfileAvatar = ({ source, size = 56, userProfile, handleAvatarOnClick = defaultOnClick }) => {
    const { firstName = "Student", lastName = "Name", gender, ...rest } = userProfile;

    const IconBasedOnGender = () => {
        switch (gender) {
            case APP_GENDERS.MALE:
                return <FaceIcon />
                break;
            case APP_GENDERS.FEMALE:
                return <Face3Icon />
                break
            default:
                return <PersonIcon />
                break;
        };
    };

    return (
        <Box onClick={handleAvatarOnClick}>
            <Avatar
                sx={{
                    height: size,
                    width: size,
                }}
                alt={`${firstName} ${lastName}`}
                src={source}
            >
                {gender && IconBasedOnGender()}
            </Avatar>
        </Box>
    );
};

export default UserProfileAvatar;