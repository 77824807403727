import { API } from "aws-amplify";
import AllAnnouncementsMocked from "../mocks/api/announcements_mock.json";

const ANNOUNCEMENT_API_NAME = "announcement";

export const AnnouncementApi = {
  /**
   * List all ...
   * @param {string} [] - .
   * @returns 
   */
  getAllAnnouncements: async (lastEvaluatedKey) => {
    if (process.env.NODE_ENV === "development") return AllAnnouncementsMocked;

    const init = {
      headers: {},
      response: true,
    };
    
    if (Object.keys(lastEvaluatedKey).length !== 0) {
      init.queryStringParameters = {
        lastEvaluatedKey: JSON.stringify(lastEvaluatedKey),
      }
    }

    return API.get(ANNOUNCEMENT_API_NAME, `/announcement`, init);
  }, 

  getSingleAnnouncement: async (postNumber) => {
    if (process.env.NODE_ENV === "development") return ;

    const init = {
      headers: {},
      response: true,
    };

    return API.get(ANNOUNCEMENT_API_NAME, `/announcement/announcement/${postNumber}`, init);
  }, 

  postAnnouncement: async (announcementData) =>  {
    if (process.env.NODE_ENV === "development") return;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
      body: announcementData
    };

    return API.post(ANNOUNCEMENT_API_NAME, `/announcement`, init);
  },

  updateAnnouncement: async (announcementData) =>  {
    if (process.env.NODE_ENV === "development") return;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
      body: announcementData
    };

    return API.patch(ANNOUNCEMENT_API_NAME, `/announcement`, init);
  },

  deleteAnnouncement: async (announcementId, postNumber) =>  {
    if (process.env.NODE_ENV === "development") return;

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {},
    };

    return API.del(ANNOUNCEMENT_API_NAME, `/announcement/${announcementId}/${postNumber}`, init);
  },
}