import { Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { NotificationApi } from "../../apis/NotificationApi";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { useNavigate } from "react-router-dom";
import TopicAvatar from "./TopicAvatar";
import { convertISOToCustomFormat } from "../../utils/utils";

const AppNotificationCard = ({ appNotification, closeDialog }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCardOnClick = (id) => {
    dispatch(toggleLoader({ isLoading: true }));

    //Mark notification READ
    NotificationApi.updateNotificaion(appNotification.id, true, true)
      .then((data) => {
        dispatch(toggleLoader({ isLoading: false }));
        navigate(appNotification.navLink);
        closeDialog();
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          showAlert({
            message:
              "An error ocurred while updating notification. Please, try again.",
            severity: "error",
          })
        );
        dispatch(toggleLoader({ isLoading: false }));
      });
  };

  const bgcolor = appNotification.seen ? "lightGray" : "white";

  return (
    <Stack
      direction={"row"}
      sx={{
        padding: 1,
        color: "black",
        backgroundColor: (theme) => (appNotification.seen) ? theme.palette.white.main : theme.palette.tertiary.light
      }}
      justifyContent="space-between"
      alignItems="center"
      onClick={(ev) => handleCardOnClick()}
    >
      <Stack direction={"column"} sx={{ paddingLeft: 0 }}>
        <Typography sx={{ fontWeight: "bold" }} textTransform={"capitalize"} variant="h6">
          {appNotification.title}
        </Typography>

        <Typography textTransform={"capitalize"} variant="body2">
          {appNotification.description}
        </Typography>

        <Typography variant="caption">
          {convertISOToCustomFormat(appNotification.createdAt)}
        </Typography>
      </Stack>
      <Stack direction={"column"} justifyContent="center" alignItems="center">
        <TopicAvatar topic={appNotification.topic} />
      </Stack>
    </Stack>
  );
};

export default AppNotificationCard;
