import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EditPersonalInformation from "./EditPersonalInformation";
import { Divider, Typography } from "@mui/material";
import PersonalInformation from "./PersonalInformation";
import { UserProfileApi } from "../../apis/UserProfileApi";
import { useDispatch, useSelector } from "react-redux";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { savePersonSearched } from "../../store/slices/personSearchedSlice";
import { MessagingApi } from "../../apis/MessagingApi";
import { APP_ROLES } from "../../constants/appConstants";
import { useParams } from "react-router-dom";

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
`;

function PersonalInfoTab() {
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState({});
  const [resetUser, setResetUser] = useState({});
  const [socialMedia, setSocialMedia] = useState({
    linkedin: "",
    facebook: "",
    instagram: "",
    twitter: "",
  });
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const alumniSelected = useSelector((state) => state.alumni.alumniSelected);
  let { id } = useParams();
  const dispatch = useDispatch();
  const personSearched = useSelector((state) => state.personSearched.personSearched);
  const [errors, setErrors] = useState({});
  const [socialErrors, setSocialErrors] = useState({});

  useEffect(() => {
    const userId = id ? id : getUserProfileId();

    dispatch(toggleLoader({ isLoading: true }));
    userId &&
      UserProfileApi.getUserById(userId, "PersonalInfoPrivate")
        .then((response) => {
          if (Object.keys(response?.data).length !== 0) {
            //If user don't have data it will start the state with mocked data
            setUser(response.data[0]);
            setResetUser(response.data[0]);
            if (response.data[0]["socialMedia"]) {
              setSocialMedia(response.data[0].socialMedia);
            }
            dispatch(savePersonSearched(response.data[0]));
          }
        })
        .catch((err) => {
          console.error(err);
          dispatch(toggleLoader({ isLoading: false }));
          dispatch(
            showAlert({
              message: "An error ocurred while fetching. Please, try again.",
              severity: "error",
            })
          );
        })
        .finally(() => {
          dispatch(toggleLoader({ isLoading: false }));
        });
  }, [userLoggedIn, alumniSelected]);

  const getUserProfileId = () => {
    //if user logged in is type of Parent, then we need to fetch Alumni's profile picture. otherwise, user's logged in profile picture.
    return userLoggedIn.profileType === APP_ROLES.PARENTS ? alumniSelected.alumniId : userLoggedIn.userName;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updateValue = value;
    let updateError = { ...errors };
    if (value.length >= 50) {
      updateValue = value.slice(0, 50);
      updateError[name] = "Maximum length exceeded (50 characters)";
    } else {
      updateValue = value;
      delete updateError[name];
    }
    setErrors(updateError);
    setUser({ ...user, [name]: updateValue });
  };

  const socialMediaOnChange = (e) => {
    const { name, value } = e.target;
    let updateValue = value;
    let updateError = { ...socialErrors };
    if (value.length >= 50) {
      updateValue = value.slice(0, 50);
      updateError[name] = "Maximum length exceeded (50 characters)";
    } else {
      updateValue = value;
      delete updateError[name];
    }
    setSocialErrors(updateError);
    setSocialMedia({ ...socialMedia, [name]: updateValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(toggleLoader({ isLoading: true }));
    try {
      const finalUser = { ...user, socialMedia };
      const participant = { firstName: finalUser.firstName.trim(), lastName: finalUser.lastName.trim() };

      UserProfileApi.patchUserProfile(finalUser);
      const userId = id ? id : getUserProfileId();
      MessagingApi.updateParticipantDetails(userId, participant);
    } catch (error) {
      dispatch(
        showAlert({
          message: "An error ocurred while trying to update your data",
          severity: "error",
        })
      );
      dispatch(toggleLoader({ isLoading: false }));
    } finally {
      dispatch(toggleLoader({ isLoading: false }));
      setIsEditing(false);
    }
  };

  const handleCancelButton = () => {
    setUser(resetUser);
    setIsEditing(false);
  };

  return (
    <>
      <Title>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "1.125rem",
            paddingTop: 2,
            marginLeft: 5,
            width: "85%",
          }}
          variant="h6"
        >
          Personal Information
        </Typography>
        {isEditing ? (
          <></>
        ) : (
          <EditIcon
            sx={{
              flex: "none",
              paddingBottom: 0.5,
              height: 18,
              cursor: "pointer",
            }}
            onClick={() => setIsEditing(true)}
          />
        )}
      </Title>
      <Divider sx={{ width: "95%", margin: "auto" }} />
      {isEditing ? (
        <EditPersonalInformation
          user={user}
          socialMedia={socialMedia}
          onChange={handleChange}
          handleCancelButton={handleCancelButton}
          socialMediaOnChange={socialMediaOnChange}
          onSubmit={handleSubmit}
          errors={errors}
          socialErrors={socialErrors}
        />
      ) : (
        <PersonalInformation user={user} socialMedia={socialMedia} />
      )}
    </>
  );
}

export default PersonalInfoTab;
