import { Box, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import { AnnouncementTab } from "../posts/AnnouncementTab";
import { useSearchParams } from "react-router-dom";
import MessageTabContainer from "../messaging/MessageTabContainer";
import CardRaisedRounded from "../shared/CardRaisedRounded";
import SecondaryTabs from "../shared/SecondaryTabs";
import TabPanel from "../shared/TabPanel";
import EventsContainer from "../events/EventsContainer";
import { APP_ROLES } from "../../constants/appConstants";
import { useSelector } from "react-redux";

const MyFeedContent = ({ openAt = "posts" }) => {
    const [activeTab, setActiveTab] = useState(0);
    let [searchParams, setSearchParams] = useSearchParams();
    const userRole = useSelector((state) => state.user.userLoggedIn.profileType);

    let feedTabs = [
        {
            key: "posts",
            value: 0, //used to show or hide tab panel
            label: "Posts",
        },
        {
            key: "events",
            value: 1,
            label: "Events",
        },
        {
            key: "messages",
            value: 2,
            label: "Messages",
            badgeCount: 0, //if this value equals to zero (numeric value), then no badge is shown.
        },
    ];
    
    if (userRole === APP_ROLES.PARENTS) {
        feedTabs = feedTabs.filter(tab => tab.key !== "messages");
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);

        //clear, present or not, `tab`, `postNumber` and `eventNumber` key
        searchParams.delete("tab");
        searchParams.delete("postNumber");
        searchParams.delete("eventNumber");
        setSearchParams(searchParams);
    };

    useEffect(() => {
        const activeTabValue = feedTabs.find(t => t.key.toLowerCase() === openAt.toLowerCase()).value;
        setActiveTab(activeTabValue);
    }, [openAt]);

    return (
        <>
            <CardRaisedRounded>
                <CardContent sx={{ paddingY: (theme) => theme.spacing(0) }}>
                    <Box sx={{ width: '100%' }}>
                        <SecondaryTabs tabs={feedTabs} tabSelected={activeTab} handleChange={handleTabChange} />

                        <TabPanel value={activeTab} index={0} ishidden={feedTabs[0].value !== activeTab}>
                            <AnnouncementTab />
                        </TabPanel>
                        <TabPanel value={activeTab} index={1} ishidden={feedTabs[1].value !== activeTab}>
                            <EventsContainer />
                        </TabPanel>
                        {userRole !== APP_ROLES.PARENTS && <TabPanel value={activeTab} index={2} ishidden={feedTabs[2].value !== activeTab}>
                            <MessageTabContainer />
                        </TabPanel>}
                    </Box>
                </CardContent>
            </CardRaisedRounded>
        </>
    );
}

export default MyFeedContent;