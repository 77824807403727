import { createTheme } from "@mui/material/styles";

const appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      light: "#757ce8",
      main: "#0F356F",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#590006",
      dark: "#ba000d",
      contrastText: "#000",
    },
    tertiary: {
      light: "#E5E5E5",
      main: "#666666",
      dark: "#333333",
      contrastText: "#000",
    },
    white: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#ffffff",
      contrastText: "black",
    },
    text: {
      primary: "#0F356F",
    },
  },
  typography: {
    allVariants: {
      color: "#000000",
    },
  },
});

export default appTheme;
