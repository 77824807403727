import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CreateNewChatAdvice from './CreateNewChatAdvice';
import ActiveChatHeader from './ActiveChatHeader';
import ActiveChatBody from './ActiveChatBody';
import ActiveChatSkeleton from './ActiveChatSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { MessagingApi } from '../../apis/MessagingApi';
import { storeChat, storeChatParticipants } from '../../store/slices/messagingSlice';
import Chatbox from './ChatBox';

function ActiveChat({ }) {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
    const chats = useSelector((state) => state.messaging.chats);
    const activeChat = useSelector((state) => state.messaging.activeChat);
    const [isLoading, setIsLoading] = useState(false);
    const [chat, setChat] = useState(null);

    useEffect(() => {
        if (activeChat.chatId === null) return;

        const chatFoundLocally = chats.items.find((c) => c.id === activeChat.chatId);
        
        if (chatFoundLocally) {
            setChat(chatFoundLocally);
            dispatch(storeChatParticipants(chatFoundLocally.participants.items));
        } else {
            setIsLoading(true);

            MessagingApi.getChat(activeChat.chatId)
                .then((result) => {
                    setChat(result.data.getChat);
                    dispatch(storeChat(result.data.getChat));
                    dispatch(storeChatParticipants(result.data.getChat.participants.items));
                })
                .catch((err) => { })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [activeChat]);

    if (chat === null) return <CreateNewChatAdvice />

    if (isLoading) return <ActiveChatSkeleton />

    return (
        <Box>
            <ActiveChatHeader participants={chat.participants} />
            <ActiveChatBody chatId={activeChat.chatId} />
            <Chatbox userLoggedIn={userLoggedIn} />
        </Box>
    )
}

export default ActiveChat;