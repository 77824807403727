import React, { useMemo, useState } from "react";
import { createEditor } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import UserProfileAvatar from "../shared/UserProfileAvatar";
import { GetApp, Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Link,
  DialogTitle,
  DialogContent,
  Dialog,
} from "@mui/material";
import { useSelector } from "react-redux";

function MessageHistoryLoader({ message, index }) {
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const participantsProfilePictures = useSelector((state) => state.shared.profilePictures.items);
  const profilePhoto = useSelector((state) => state.user.profilePicture);
  const [onClickImage, setOnClickImage] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const editor = useMemo(() => withReact(createEditor()), []);

  const openImageModal = (image) => {
    setEnlargedImage(image);
    setOnClickImage(true);
  };

  const closeImageModal = () => {
    setOnClickImage(false);
  };

  const Leaf = (props) => {
    return (
      <span
        {...props.attributes}
        style={{
          fontWeight: props.leaf.bold ? "bold" : "normal",
          fonstStyle: props.leaf.italic ? "italic" : "normal",
          textDecoration: props.leaf.underline ? "underline" : "none",
        }}
      >
        {props.children}
      </span>
    );
  };

  return (
    <div
      index={index}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems:
          userLoggedIn.userName === message.sender ? "flex-end" : "flex-start",
        marginBottom: "5px",
      }}
    >
      <Slate editor={editor} value={message.text} readOnly>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1rem",
            color: "#000",
          }}
        >
          {userLoggedIn.userName !== message.sender && (
            <UserProfileAvatar
              source={participantsProfilePictures.find(ppp => ppp.key === message.sender)?.value.profilePictureKey}
              size={44}
              userProfile={participantsProfilePictures.find(ppp => ppp.key === message.sender)?.value}
            />
          )}
          <Editable style={{
            backgroundColor: "#f7f7f7",
            padding: "1rem",
            borderRadius: "5px",
          }} renderLeaf={(props) => <Leaf {...props} />} readOnly />
          {userLoggedIn.userName === message.sender && (
            <UserProfileAvatar
              source={profilePhoto?.dataFromBlob}
              size={44}
              userProfile={userLoggedIn}
            />
          )}
        </span>
      </Slate>
      {message.image && (
        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyContent: "center",
            ml: 10,
            mr: 10,
          }}
        >
          <img
            src={message.image}
            alt="attached"
            width="300px"
            onClick={() => openImageModal(message.image)}
            style={{ cursor: "pointer" }}
          />
        </Box>
      )}
      <Dialog open={onClickImage} onClose={closeImageModal}>
        <DialogTitle>Image</DialogTitle>
        <DialogContent>
          <img src={enlargedImage} alt="enlarged" style={{ width: "100%" }} />
          <IconButton
            edge="end"
            color="secondary"
            onClick={closeImageModal}
            sx={{ position: "absolute", top: 1, right: 12 }}
          >
            <Close />
          </IconButton>
        </DialogContent>
      </Dialog>
      {message.attachments.map((attachment, attachmentIndex) => (
        <Box
          key={attachmentIndex}
          sx={{
            mt: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ml: 5,
            mr: 5,
          }}
        >
          <Link
            href={attachment.url}
            target="_blank"
            download
            underline="none"
            color="secondary"
            display="flex"
            alignItems="center"
            paddingBottom={3}
          >
            <GetApp /> {` "${attachment.name}" was shared`}
          </Link>
        </Box>
      ))}
    </div>
  );
}

export default MessageHistoryLoader;
