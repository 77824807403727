import { Badge, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import DotBadge from "./DotBadge";

const VerticalTabs = ({ tabs, activeTab, handleTabChange }) => {
  return (
    <Paper elevation={0} sx={{ height: 1 }}>
      <Grid container direction="row" justifyContent="center" alignItems="stretch">
        <Grid item xs={2} md={2} lg={2} xl={2} sx={{ backgroundColor: "#F7F7F7", }}>
          <Tabs
            value={activeTab}
            orientation="vertical"
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
          >
            {tabs.map((t) =>
              t.badgeCount ? (
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  key={t.key}
                  label={
                    <Grid container>
                      <Grid item xs={10}>
                        <Badge color="error" badgeContent={t.badgeCount} max={99}>
                          {t.label} &nbsp;&nbsp;
                        </Badge>
                      </Grid>
                      <Grid item xs={2}>
                        {t.showDotBadge && <DotBadge color={t.dotBadgeColor} />}
                      </Grid>
                    </Grid>           
                  }
                />
              ) : (
                <Tab
                  sx={{ textTransform: "capitalize", borderColor: "green" }}
                  key={t.key}
                  label={
                    <Grid container>
                      <Grid item xs={10}>
                        {t.label}
                      </Grid>
                      <Grid item xs={2}>
                        {t.showDotBadge && <DotBadge color={t.dotBadgeColor} />}
                      </Grid>
                    </Grid>
                  }
                  variant="outlined"
                />
              )
            )}
          </Tabs>
        </Grid>
        <Grid item xs={10} md={10} lg={10} xl={10}>
          <Typography component="div">
            <div>{tabs[activeTab].content}</div>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default VerticalTabs;
