import { Divider } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchApi } from "../../apis/SearchApi";
import {
  APP_ROLES,
  APP_ROUTES,
  CONNECTION_STATUS,
} from "../../constants/appConstants";
import FiltersContainer from "./FiltersContainer";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { useNavigate } from "react-router-dom";
import PaginationContainer from "./PaginationContainer";
import NetworkUserProfileCard from "../network/NetworkUserProfileCard";
import { UserProfileApi } from "../../apis/UserProfileApi";
import { AlumniNetworkApi } from "../../apis/AlumniNetworkApi";
import { attributesToFilterUsersFrom, useDebounce } from "../../utils/utils";
import { pushPageLastKey, pushUserProfiles, resetState, setLastEvaluatedKey, setPage, setPageCount, setUserProfilesFiltered } from "../../store/slices/searchSlice";

const SearchPanel = ({ makeApiCalls }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState();
  const [searchTerm, setSearchTerm] = useState(null);
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const [userInfo, setUserInfo] = useState(null);

  const page = useSelector((state) => state.search.page);
  const pageCount = useSelector((state) => state.search.pageCount);
  const pageLastKey = useSelector((state) => state.search.pageLastKey);
  const userProfiles = useSelector((state) => state.search.userProfiles);
  const userProfilesFiltered = useSelector((state) => state.search.userProfilesFiltered);
  const lastEvaluatedKey = useSelector((state) => state.search.lastEvaluatedKey);

  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const alumniSelected = useSelector((state) => state.alumni.alumniSelected);
  const navigate = useNavigate();

  const handleAppError = (err, msg) => {
    console.error(err);
    dispatch(
      showAlert({
        message: msg,
        severity: "error",
      })
    );
  };

  const getSearchResults = async (userId) => {
    dispatch(toggleLoader({ isLoading: true }));
    let prom1 = AlumniNetworkApi.getConnections(userId);
    let prom2 = await SearchApi.getUserProfiles(
      userId,
      APP_ROLES.ALUMNI,
      filters,
      lastEvaluatedKey
    );

    Promise.all([prom1, prom2])
      .then((results) => {
        let connectionArr = results[0].data;

        let userProfilesArray = results[1].data.Items;

        let newUserProfilesArray = userProfilesArray.map((element) => {
          let user = connectionArr.find(
            (conn) => conn.connectionProfileId === element.userProfileId
          );

          if (user) {
            element = { ...element, netWorkStatus: user.connectStatus };
          } else {
            element = {
              ...element,
              netWorkStatus: CONNECTION_STATUS.NOTCONNECTED,
            };
          }
          return element;
        });
        dispatch(pushUserProfiles(newUserProfilesArray));        
        dispatch(setUserProfilesFiltered([...newUserProfilesArray]));

        dispatch(setLastEvaluatedKey(results[1].data.LastEvaluatedKey?.userProfileId ?? null));
      })
      .catch((err) => {
        handleAppError(
          err,
          "An error ocurred while retrieving list of User Profiles."
        );
      })
      .finally(() => {
        dispatch(toggleLoader({ isLoading: false }));
      });
  };

  useEffect(() => {
    let userId = "";
    if (userLoggedIn.profileType == APP_ROLES.PARENTS) {
      userId = alumniSelected.alumniId;
    } else {
      userId = userLoggedIn.userName;
    }

    if (userId) {
      dispatch(resetState());
      getSearchResults(userId);
      UserProfileApi.getUserById(userId, "UserInfoForNetwork")
        .then((userInfo) => {
          userInfo && userInfo.data.length > 0
            ? setUserInfo(userInfo.data[0])
            : setUserInfo(null);
        })
        .catch((err) => handleAppError(err, "Error while fetching userInfo"));
    }
  }, [userLoggedIn, alumniSelected]);

  useEffect(() => {
    
    if (!filters) return;
    let userId = "";
    if (userLoggedIn.profileType == APP_ROLES.PARENTS) {
      userId = alumniSelected.alumniId;
    } else {
      userId = userLoggedIn.userName;
    }
    getSearchResults(userId);
    
  }, [filters]);

  useEffect(() => {
    const newPageCount = Math.ceil(
      userProfilesFiltered.length / resultsPerPage
    );

    if (lastEvaluatedKey) {
      dispatch(pushPageLastKey({ page: newPageCount, lastKey: lastEvaluatedKey }));
      /// update pageCount based on LastEvaluatedKey. since we are performing a Scan operation, we don't know beforehand
      /// the total amount of results. so, if LastEvaluatedKey is not null, then it means there is more items to be retrieved.
      /// increment pageCount by one unit
      dispatch(setPageCount(newPageCount + 1));
    } else {
      dispatch(setPageCount(newPageCount));
    }

  }, [userProfilesFiltered, lastEvaluatedKey]);

  useEffect(() => {
    //if new page is greater than the lastest one in `_pageLastKey`, a new API call is needed
    const _pairs = JSON.parse(JSON.stringify(pageLastKey));
    const last = _pairs.slice(-1)[0];
    if (last.page === 0) return;

    if (page > last.page) {
      let userId = "";
      if (userLoggedIn.profileType == APP_ROLES.PARENTS) {
        userId = alumniSelected.alumniId;
      } else {
        userId = userLoggedIn.userName;
      }
      getSearchResults(userId);
    }
  }, [page]);

  const filterResultsBySearchInput = (searchInput) => {
    const searchTerm = Array.of(...searchInput.split(" ")).join("#").toString().toLocaleUpperCase();

    const searchResults = userProfiles.filter(up => attributesToFilterUsersFrom(up).includes(searchTerm));

    const newPageCount = Math.ceil(
      searchResults.length / resultsPerPage
    );
    dispatch(setPageCount(newPageCount));    
    dispatch(setUserProfilesFiltered(searchResults));
  };

  const debouncedFilterBySearchInput = useDebounce(
    () => {
      filterResultsBySearchInput(searchTerm)
    }, 250);

  const handleSearchOnChange = (ev) => {
    const value = ev.target.value;

    setSearchTerm(value);

    debouncedFilterBySearchInput(value);
  };

  const handleUserProfileCardOnClick = (_userProfile) => {
    navigate(`${APP_ROUTES.PROFILE_DETAILS}/${_userProfile.userProfileId}`);
  };

  const handlePageChange = (event, newPage) => {
    dispatch(setPage(newPage));
  };

  const handleClickApply = (_filters) => {
    dispatch(resetState());
    setFilters(_filters);
  };

  const visibleResults = useMemo(() => userProfilesFiltered?.slice((page - 1) * resultsPerPage, page * resultsPerPage), [userProfilesFiltered, page, resultsPerPage]);

  return (
    <>
      <FiltersContainer
        viewAs={APP_ROLES.ALUMNI}
        handleSearchOnChange={handleSearchOnChange}
        handleClickApply={handleClickApply}
      />
      <Divider sx={{ marginX: (theme) => theme.spacing(2) }} />
      <PaginationContainer
        count={pageCount}
        page={page}
        handlePageChange={handlePageChange}
        handleConnect={null}
        handleSelectAll={null}
        hideSelectAll={true}
      />
      {visibleResults.map((up) => (
        <NetworkUserProfileCard
          key={up.userProfileId}
          profile={up}
          handleCardOnClick={(ev) => handleUserProfileCardOnClick(up)}
          makeApiCalls={makeApiCalls}
          currentUserInfo={userInfo}
          showApproveDeny={false}
          showSendMessage={false}
        />
      ))}
    </>
  );
};

export default SearchPanel;
