import CardContent from "@mui/material/CardContent";
import { Container } from "@mui/system";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import DeleteIcon from '@mui/icons-material/Delete';
import Button from "@mui/material/Button";
import { useMemo } from "react";
import { useState } from "react";
import ActionToggleIsActive from "./ActionToggleIsActive";
import { UserProfileApi } from "../../../apis/UserProfileApi";
import { useDispatch, useSelector } from "react-redux";
import { showAlert, toggleLoader } from "../../../store/slices/sharedSlice";
import { UserPoolApi } from "../../../apis/UserPoolApi";
import ConfirmDialogBox from "../../shared/ConfirmDialogBox";
import { MessagingApi } from "../../../apis/MessagingApi";
import { useNavigate } from "react-router";
import { APP_ROUTES } from "../../../constants/appConstants";
import { selectChat } from "../../../store/slices/messagingSlice";
import ActionDeleteProfilePicture from "./ActionDeleteProfilePicture";
import { NotificationApi } from "../../../apis/NotificationApi";
import { ProfilePictureApi } from "../../../apis/ProfilePictureApi";

const ACTIONS = {
    SEND_MESSAGE: "send-message",
    SEND_RECOVERY: "send-recovery",
    TOGGLE_ACCOUNT_IS_ACTIVE: "toggle-account-is-active",
    DELETE_PROFILE_PICTURE: "delete-profile-picture",
};

const ActionsOnUser = ({ userId, userProfile, hasProfilePicture = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isActive, firstName, lastName, parentEmail, email } = userProfile;
    const userEmail = parentEmail || email;
    const [userIsActive, setUserIsActive] = useState(isActive);
    const [userHasProfilePicture, setUserHasProfilePicture] = useState(hasProfilePicture);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [toggleActionIsOpen, setToggleActionIsOpen] = useState(false);
    const [deletePictureIsOpen, setDeletePictureIsOpen] = useState(false);
    const userLoggedIn = useSelector((state) => state.user.userLoggedIn);

    const actions = useMemo(() => {
        return [
            {
                name: ACTIONS.SEND_MESSAGE,
                label: "Send message",
                icon: <ChatBubbleIcon />,
                disabled: !userIsActive
            },
            {
                name: ACTIONS.SEND_RECOVERY,
                label: "Send account recovery e-mail",
                icon: <AccountCircleIcon />,
                disabled: !userIsActive
            },
            {
                name: ACTIONS.TOGGLE_ACCOUNT_IS_ACTIVE,
                label: (userIsActive) ? "Disable/Deactivate account" : "Enable/Reactivate account",
                icon: (userIsActive) ? <NoAccountsIcon /> : <AccountCircleIcon />,
                disabled: false
            },
            {
                name: ACTIONS.DELETE_PROFILE_PICTURE,
                label: "Delete profile picture",
                icon: <DeleteIcon />,
                disabled: !userHasProfilePicture
            },
        ];
    }, [userIsActive, userHasProfilePicture]);

    const handleOnClick = (action) => {
        //if, for any reason, action is disabled but end user manage to enable it from UI, prevent further actions.
        if (action.disabled) return;

        switch (action.name) {
            case ACTIONS.SEND_MESSAGE:
                sendMessageToUser();
                break;
            case ACTIONS.SEND_RECOVERY:
                setConfirmPopup(true);
                break;
            case ACTIONS.TOGGLE_ACCOUNT_IS_ACTIVE:
                //oper ActionToggleIsActive component
                setToggleActionIsOpen(true);
                break;
            case ACTIONS.DELETE_PROFILE_PICTURE:
                setDeletePictureIsOpen(true);
                break;
            default:
                break;
        }
    };

    const sendMessageToUser = async () => {
        dispatch(toggleLoader({ isLoading: true }));

        try {
            //check for existing chat between Admin logged in and user.
            const participantsId = [userLoggedIn.userName, userId];
            const result = await MessagingApi.lookUpForChatBetweenParticipants(participantsId);
            //if not found, start a new chat
            if (!result.found) {
                const newChat = await MessagingApi.startNewChat(participantsId);
                //set newChat as active
                const _chat = { chatId: newChat.id };
                dispatch(selectChat(_chat));
            } else {
                //set Chat in comun as active
                const _chat = { chatId: result.chatId };
                dispatch(selectChat(_chat));
            }
    
            //navigate to "My Feed" and set "Message" tab as active
            dispatch(toggleLoader({ isLoading: false }));
            navigate(`${APP_ROUTES.MY_FEED}?tab=messages`);
        } catch (error) {
            dispatch(toggleLoader({ isLoading: false }));
            dispatch(showAlert({
                message: `Unable to start a chat with ${firstName} ${lastName}. Please, try again.`,
                severity: "error",
            }));
        }
    };

    const confirmSendEmailRecovery = () => {
        setConfirmPopup(false);

        dispatch(toggleLoader({ isLoading: true }));
        UserPoolApi.sendRecoveryEmail(userEmail)
            .then((response) => {
                dispatch(
                    showAlert({
                        message: "Email recovery sent successfully.",
                        severity: "success",
                    })
                );
            })
            .catch((err) => {
                console.error(err);
                dispatch(toggleLoader({ isLoading: false }));
                dispatch(
                    showAlert({
                        message: "Failed to send email recovery",
                        severity: "error",
                    })
                );
            })
            .finally(() => {
                dispatch(toggleLoader({ isLoading: false }));
            });
    };

    const handleToggleOnClose = (reason) => {
        if (reason) {
            dispatch(toggleLoader({ isLoading: true }));
            UserProfileApi.toggleIsActive(userId, !userIsActive, reason.trim())
                .then(async (response) => {
                    const toggleCognitoResponse = await UserPoolApi.toggleCognitoUserStatus(userId);

                    ///Reflect this change in Participant table
                    MessagingApi.updateParticipantIsActive(userId, !userIsActive);

                    ///toggle state after sucsessfull API call
                    setUserIsActive((prevState) => !prevState);

                    //we can now close the Dialog component
                    setToggleActionIsOpen(false);

                    //display response's message
                    dispatch(showAlert({ message: toggleCognitoResponse.data?.message, severity: "success" }));
                })
                .catch((err) => {
                    dispatch(showAlert({ message: "An error ocurred while processing your request.", severity: "error" }));
                })
                .finally(() => {
                    dispatch(toggleLoader({ isLoading: false }));
                });
        } else {
            //user cancel the action
            setToggleActionIsOpen(false);
        }
    };

    const handleDeletePictureOnClose = (reason) => {
        if (reason) {
            dispatch(toggleLoader({ isLoading: true }));
            
            ProfilePictureApi.deleteUserProfilePicture(userId)
                .then((response) => {
                    const userName = `${firstName} ${lastName}`;
                    NotificationApi.notifyUserProfilePictureDeleted(userEmail, reason, userName)
                        .then((response) => {
                            dispatch(showAlert({ message: "Profile picture deleted successfully. An e-mail notification was sent to the user.", severity: "success" }));
                            
                            ///update userHasProfilePicture to disable action once entire transaction is finished
                            setUserHasProfilePicture(false);
                        })
                        .catch((err) => {
                            dispatch(showAlert({ message: "Profile picture deleted. However, an error ocurred while sending an e-mail notification.", severity: "warning" }));
                        })
                        .finally(() => {
                            dispatch(toggleLoader({ isLoading: false }));
                            setDeletePictureIsOpen(false);
                        })
                 })
                .catch((err) => {
                    dispatch(toggleLoader({ isLoading: false }));
                    dispatch(showAlert({ message: "An error ocurred while deleting the profile picture. Please, try again.", severity: "error" }));
                    setDeletePictureIsOpen(false);
                 })

        } else {
            //user canceled the action
            setDeletePictureIsOpen(false);
        }
    };

    return (
        <CardContent>
            <Container maxWidth="xs">
                {actions.map((action, index) => (
                    <Button
                        key={index}
                        fullWidth
                        sx={{
                            height: "40px",
                            justifyContent: "flex-start",
                            color: "black",
                            marginBottom: 1,
                            textTransform: "capitalize"
                        }}
                        variant="text"
                        startIcon={action.icon}
                        disabled={action.disabled}

                        onClick={(ev) => handleOnClick(action)}
                    >
                        {action.label}
                    </Button>
                ))}
            </Container>
            <ActionToggleIsActive
                isOpen={toggleActionIsOpen}
                actionDetail={actions[2].label}
                handleOnClose={handleToggleOnClose}
            />
            <ActionDeleteProfilePicture
                isOpen={deletePictureIsOpen}
                handleOnClose={handleDeletePictureOnClose}
            />
            <ConfirmDialogBox
                open={confirmPopup}
                title={"Confirmation"}
                message={"Do you want to resend a recovery email to the user?"}
                okText={"Confirm"}
                cancelText={"Cancel"}
                handleClose={() => setConfirmPopup(false)}
                handleOk={() => confirmSendEmailRecovery()}
            />
        </CardContent>
    );
};

export default ActionsOnUser;
