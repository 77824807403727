import React, { useState } from "react";
import { CardContent, Grid, Tooltip, Typography } from "@mui/material";
import CardRaisedRounded from "../shared/CardRaisedRounded";
import UserProfileAvatar from "../shared/UserProfileAvatar";
import {
  APP_ROLES,
  APP_ROUTES,
  CONNECTION_STATUS,
  NOTIFICATION_TOPICS,
} from "../../constants/appConstants";
import { showAlert, toggleLoader } from "../../store/slices/sharedSlice";
import { useDispatch, useSelector } from "react-redux";
import { AlumniNetworkApi } from "../../apis/AlumniNetworkApi";
import OutlinedButton from "../shared/OutlinedButton";
import { NotificationApi } from "../../apis/NotificationApi";
import { useNavigate } from "react-router-dom";
import { selectChat } from "../../store/slices/messagingSlice";
import { MessagingApi } from "../../apis/MessagingApi";

const NetworkUserProfileCard = ({
  profile,
  handleCardOnClick,
  makeApiCalls,
  currentUserInfo,
  showApproveDeny = false,
  showSendMessage = false,
}) => {
  const [userProfile, setUserProfile] = useState(profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
  const alumniSelected = useSelector((state) => state.alumni.alumniSelected);

  const getUserProfileId = () => {
    //if user logged in is type of Parent, then we need to fetch Alumni's profile picture. otherwise, user's logged in profile picture.
    return (userLoggedIn.profileType === APP_ROLES.PARENTS) ? alumniSelected.alumniId : userLoggedIn.userName;
  };

  const sendConnectRequest = async (selectedAlumni) => {    
    let selectedNetwork = [];
    let connRequestorToAlumni = {
      user: {
        userProfileId: currentUserInfo.userProfileId,
        firstName: currentUserInfo.firstName,
        lastName: currentUserInfo.lastName,
        cohort: currentUserInfo.cohort,
        city: currentUserInfo.city,
        yearsAttendedBWS: currentUserInfo.yearsAttendedBWS,
      },
      connection: JSON.parse(selectedAlumni),
      connStatus: CONNECTION_STATUS.REQUESTSENT,
    };
    selectedNetwork.push(connRequestorToAlumni);

    let connAlumniToRequestor = {
      user: JSON.parse(selectedAlumni),
      connection: {
        userProfileId: currentUserInfo.userProfileId,
        firstName: currentUserInfo.firstName,
        lastName: currentUserInfo.lastName,
        cohort: currentUserInfo.cohort,
        city: currentUserInfo.city,
        yearsAttendedBWS: currentUserInfo.yearsAttendedBWS,
      },
      connStatus: CONNECTION_STATUS.REQUESTPENDING,
    };
    selectedNetwork.push(connAlumniToRequestor);

    dispatch(
      showAlert({
        message: "Connections being added.",
        severity: "info",
      })
    );
    await makeApiCalls(selectedNetwork, CONNECTION_STATUS.REQUESTSSENT);

    AlumniNetworkApi.getConnections(
      currentUserInfo.userProfileId,
      userProfile.userProfileId
    )
      .then((resp) =>
        setUserProfile({
          ...userProfile,
          netWorkStatus: resp.data[0].connectStatus,
        })
      )
      .catch((err) => console.log(err));

    const alumni = JSON.parse(selectedAlumni);
    NotificationApi.createNotification(
      "New Connection Request",
      `You have a new connection request from ${currentUserInfo.firstName} ${currentUserInfo.lastName}.`,
      "/my-network?subtab=connections&conntab=requested",
      NOTIFICATION_TOPICS.NETWORK,
      alumni.userProfileId
    )
      .then((data) => {})
      .catch((err) => console.log(err));
  };

  const approveDenyConnectRequest = (
    action,
    connectionId,
    connFirstName,
    connLastName,
    email
  ) => {
    const connReqStatus =
      action == CONNECTION_STATUS.CONNECTED ? "Approved" : "Rejected";
    const currentLoggedInUserName = `${currentUserInfo.firstName} ${currentUserInfo.lastName}`;
    const connectionUserName = `${connFirstName} ${connLastName}`;
    const subject = `BWS Alumni Connect - Updates related to your connection request`;
    const subtitle = `Connection request ${connReqStatus}`;
    const body = `Your connection request has been ${connReqStatus} by ${currentLoggedInUserName}.`;

    const userId = getUserProfileId();

    //Update Connection Status for both users(Requestor-Alumni)
    AlumniNetworkApi.patchConnection(userId, connectionId, {
      connectStatus: action,
    })
      .then((data) => {

        //Update same status for the other user
        AlumniNetworkApi.patchConnection(connectionId, userId, {
          connectStatus: action,
        })
          .then((data) => {

            //Notify User by AppNotification on bell icon.
            let redirectPath = (action == CONNECTION_STATUS.CONNECTED) ? "/my-network?subtab=connections&conntab=connected" : "/my-network?subtab=search";
            NotificationApi.createNotification(
              subtitle,
              body,
              redirectPath,
              NOTIFICATION_TOPICS.NETWORK,
              connectionId
            )
            .then((data) => {})
            .catch((err) => console.log(err));

          
            //Notify User by email
            NotificationApi.sendEmail(
              email,
              subject,
              subtitle,
              connectionUserName,
              body
            ).then(data => {}).catch(err => {
              dispatch(
                showAlert({
                  message: `Failed to send an e-mail notification.`,
                  severity: "error",
                })
              );
            });

            //If request is approved, then start a new chat between them
            if (action === CONNECTION_STATUS.CONNECTED) {
              const participantsId = [connectionId, userId];
              MessagingApi.startNewChat(participantsId);
            }

            dispatch(
              showAlert({
                message: `Connection request has been ${connReqStatus}.`,
                severity: "success",
              })
            );

            navigate(redirectPath);
          })
          .catch((err) => {
            console.log(err);
            dispatch(
              showAlert({
                message: "Error occured while saving connection.",
                severity: "error",
              })
            );
          });

        

      })
      .catch((err) => {
        console.log(err);
        dispatch(
          showAlert({
            message: "Error occured while saving connection.",
            severity: "error",
          })
        );
      });

    
  };

  const sendMessage = async (receiverId) => {
    dispatch(toggleLoader({ isLoading: true }));

    //check for existing chat between logged in user and receiver.
    const userId = getUserProfileId();
    const participantsId = [receiverId, userId];
    const result = await MessagingApi.lookUpForChatBetweenParticipants(participantsId);

    //set Chat in comun as active
    const _chat = { chatId: result.chatId };
    dispatch(selectChat(_chat));

    //navigate to "My Feed" and set "Message" tab as active
    dispatch(toggleLoader({ isLoading: false }));
    navigate(`${APP_ROUTES.MY_FEED}?tab=messages`);
  };

  return (
    <>          
      <CardRaisedRounded
        sx={(theme) => ({
          marginY: theme.spacing("20px"),
          marginX: theme.spacing(2),
          boxShadow: 3,
        })}
      >
        <CardContent>
          <Grid container spacing={2} sx={{ color: "black" }}>
            <Grid
              item
              xs={6}
              md={2}
              lg={2}
              xl={2}
              alignItems="center"
              justify="center"
            >
              {/* profile picture avatar with ID below */}
              <Tooltip
                title="Click here to view more details"
                placement="bottom"
                enterDelay={200}
              >
                <span>
                  <UserProfileAvatar
                    userProfile={profile}
                    handleAvatarOnClick={handleCardOnClick}
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} md={2} lg={2} xl={2}>
              {/* user profile name */}
              <Typography textTransform={"uppercase"} variant="caption">
                NAME
              </Typography>
              <Typography textTransform={"capitalize"} variant="body1">
                {userProfile?.firstName} {userProfile?.lastName}
              </Typography>
            </Grid>

            <Grid item xs={12} md={2} lg={2} xl={2}>
              {/* YEARS ATTENDED IN BWS */}
              <Typography textTransform={"uppercase"} variant="caption">
                YEARS ATTENDED IN BWS
              </Typography>
              <Typography variant="body1">
                {userProfile?.yearsAttendedBWS || "-"}
              </Typography>
            </Grid>

            <Grid item xs={12} md={2} lg={2} xl={2}>
              {/* BWS graduation year */}
              <Typography textTransform={"uppercase"} variant="caption">
                BWS GRADUATION YEAR
              </Typography>
              <Typography variant="body1">
                {userProfile?.cohort || "-"}
              </Typography>
            </Grid>

            <Grid item xs={12} md={2} lg={2} xl={2}>
              {/* Location */}
              <Typography textTransform={"uppercase"} variant="caption">
                location
              </Typography>
              <Typography variant="body1">
                {userProfile?.city || "-"}
              </Typography>
            </Grid>
            {!showSendMessage && !showApproveDeny && (
              <Grid item xs={12} md={2} lg={2} xl={2}>
                {(userProfile.netWorkStatus ===
                  CONNECTION_STATUS.NOTCONNECTED || userProfile.netWorkStatus ===
                  CONNECTION_STATUS.REJECTED) && (
                  <OutlinedButton
                    label="Connect"
                    onClick={(ev) =>
                      sendConnectRequest(
                        JSON.stringify({
                          userProfileId: userProfile.userProfileId,
                          firstName: userProfile.firstName,
                          lastName: userProfile.lastName,
                          cohort: userProfile.cohort,
                          city: userProfile.city,
                          yearsAttendedBWS: userProfile.yearsAttendedBWS,
                        })
                      )
                    }
                  ></OutlinedButton>
                )}
                {(userProfile.netWorkStatus !==
                  CONNECTION_STATUS.NOTCONNECTED && userProfile.netWorkStatus !==
                  CONNECTION_STATUS.REJECTED) && (
                  <>
                    <Typography textTransform={"uppercase"} variant="caption">
                      In Network
                    </Typography>
                    <Typography variant="body1">
                      {userProfile?.netWorkStatus || "-"}
                    </Typography>
                  </>
                )}
              </Grid>
            )}
            {showApproveDeny && (
              <Grid item xs={6} md={1} lg={1} xl={1}>
                <OutlinedButton
                  label="Approve"
                  onClick={(ev) =>
                    approveDenyConnectRequest(
                      CONNECTION_STATUS.CONNECTED,
                      userProfile.userProfileId,
                      userProfile.firstName,
                      userProfile.lastName,
                      userProfile.email || userProfile.parentEmail
                    )
                  }
                ></OutlinedButton>
              </Grid>
            )}
            {showApproveDeny && (
              <Grid item xs={6} md={1} lg={1} xl={1}>
                <OutlinedButton
                  label="Deny"
                  onClick={(ev) =>
                    approveDenyConnectRequest(
                      CONNECTION_STATUS.REJECTED,
                      userProfile.userProfileId,
                      userProfile.firstName,
                      userProfile.lastName,
                      userProfile.email || userProfile.parentEmail
                    )
                  }
                ></OutlinedButton>
              </Grid>
            )}
            {showSendMessage && (
              <Grid item xs={6} md={1} lg={1} xl={1}>
                <OutlinedButton
                  label="Send message"
                  onClick={(ev) => sendMessage(userProfile.userProfileId)}
                ></OutlinedButton>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </CardRaisedRounded>
    </>
  );
};

export default NetworkUserProfileCard;
