import React from "react";
import { Typography } from "@mui/material";
import { GridDiv } from "../../utils/styles";
import { Grid } from "@mui/material";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import TypographyPersonalInfoField from "../shared/TypographyPersonalInfoField";
import TypographyPersonalInfoFieldTitle from "../shared/TypographyPersonalInfoFieldTitle";

export const SchoolDetails = ({ arrayData }) => {
  return (
    <>
      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>SCHOOL NAME</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{arrayData.schoolName}</TypographyPersonalInfoField>
        </GridItemProfileInfo>

        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>TYPE OF SCHOOL</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{arrayData.typeOfSchool}</TypographyPersonalInfoField>
        </GridItemProfileInfo>

        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>SCHOOL ASSOCIATED WITH NAES</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{arrayData.associatedWithNaes}</TypographyPersonalInfoField>
        </GridItemProfileInfo>

        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>LEVEL</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{arrayData.level}</TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>

      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>DEGREE</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{arrayData.degree}</TypographyPersonalInfoField>
        </GridItemProfileInfo>

        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>FIELD OF STUDY</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{arrayData.fieldOfStudy}</TypographyPersonalInfoField>
        </GridItemProfileInfo>

        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>START MONTH, YEAR</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{arrayData.startMonthYear}</TypographyPersonalInfoField>
        </GridItemProfileInfo>

        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>END MONTH, YEAR</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{arrayData.endMonthYear}</TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>

      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>GRADE/GPA</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{arrayData.gradeGpa}</TypographyPersonalInfoField>
        </GridItemProfileInfo>

        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>ACTIVITIES</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{arrayData.activities}</TypographyPersonalInfoField>
        </GridItemProfileInfo>

        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>COMMENTS/REMARKS</TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>{arrayData.commentsRemarks}</TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>

      {arrayData.academicAchievements && (
        <Grid container marginLeft={3} spacing={2} paddingTop={3}>
          <Grid item xs={12} sm={6}>
            <TypographyPersonalInfoFieldTitle>ACADEMIC ACHIEVEMENTS</TypographyPersonalInfoFieldTitle>
            <TypographyPersonalInfoField>{arrayData.academicAchievements}</TypographyPersonalInfoField>
          </Grid>
        </Grid>
      )}
    </>
  );
};
