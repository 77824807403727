import { CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import CardRaisedRounded from "../shared/CardRaisedRounded";
import VerticalTabs from "../shared/VerticalTabs";
import ConnectedNetwork from "./ConnectedNetwork";
import RequestedNetwork from "./RequestedNetwork";
import { useSearchParams } from "react-router-dom";

const MyConnections = ({ makeApiCalls }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [pendingRequests, setPendingRequests] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  let conntab = searchParams.get("conntab") || "connected"

  const networkTabs = [
    {
      key: "connected",
      value: 0, //used to show or hide tab panel
      label: "Connected",
      content: <ConnectedNetwork makeApiCalls={makeApiCalls} />,
      showDotBadge: false,
      dotBadgeColor: ""
    },
    {
      key: "requested",
      value: 1,
      label: "Requests",
      content: <RequestedNetwork makeApiCalls={makeApiCalls} anyPendingRequest={setPendingRequests} />,
      showDotBadge: pendingRequests,
      dotBadgeColor: "red"
    },
  ];

  useEffect(() => {
    const activeTabValue = networkTabs.find(t => t.key.toLowerCase() === conntab.toLowerCase()).value;
    setActiveTab(activeTabValue);
  }, [conntab]);

  const handleTabChange = (event, newValue) => {
    searchParams.delete("conntab");
    setSearchParams(searchParams);
    setActiveTab(newValue);
  };

  return (
    <>
      <CardRaisedRounded>
        <CardContent sx={{ padding: (theme) => theme.spacing(0), '&:last-child': { pb: 0 } }}>
          <VerticalTabs tabs={networkTabs} activeTab={activeTab} handleTabChange={handleTabChange} />
        </CardContent>
      </CardRaisedRounded>
    </>
  );
};

export default MyConnections;
