import { Box } from "@mui/material";
import React from "react";

function CreateNewChatAdvice() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      Please select a user to start a conversation
    </Box>
  );
}

export default CreateNewChatAdvice;
