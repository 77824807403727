import { Typography } from "@mui/material";

const TypographyPersonalInfoGroupHeading = ({ children }) => {
  return (
    <Typography
      variant="subtitle2"
      sx={{ color: "black", fontWeight: "bold", fontSize: "1rem" }}
    >
      {children}
    </Typography>
  );
};

export default TypographyPersonalInfoGroupHeading;
