import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';

const OneColumnLayout = ({ children }) => {
    const theme = useTheme();
    
    return (
        <Grid container padding={theme.spacing(2)} bgcolor="#F1F1F1">
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    );
}

export default OneColumnLayout;