import { CardContent } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ROLES } from '../../constants/appConstants';
import { showAlert } from '../../store/slices/sharedSlice';
import { fetchProfilePicture } from '../../store/slices/userSlice';
import CardRaisedRounded from '../shared/CardRaisedRounded';
import AdminDetails from './AdminDetails';
import AvatarContainer from './AvatarContainer';
import ProfilePicture from './ProfilePicture';
import ProfilePictureNotFound from './ProfilePictureNotFound';
import ProfilePictureUpload from './ProfilePictureUpload';
import { useMemo } from 'react';


const SlotUser = () => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
    const alumniSelected = useSelector((state) => state.alumni.alumniSelected);
    const profilePhoto = useSelector((state) => state.user.profilePicture)

    useEffect(() => {
        /**
         * if profilePhoto's userProfileId matches user logged in or alumni being managed and profilePhoto's dataFromBlob is NULL, 
         * then it means that we've previously checked for S3 Key and was not found. Skip logic
         */
        if (
            (profilePhoto.userProfileId === userLoggedIn.userName || profilePhoto.userProfileId === alumniSelected.alumniId)
            && profilePhoto.dataFromBlob === null
        ) return;

        if (userProfileId === undefined) return;

        /// try to download profile picture for given `userPRofileId`
        dispatch(fetchProfilePicture(userProfileId))

    }, [userLoggedIn, alumniSelected]);

    const getUserProfileId = () => {
        //if user logged in is type of Parent, then we need to fetch Alumni's profile picture. otherwise, user's logged in profile picture.
        return (userLoggedIn.profileType === APP_ROLES.PARENTS) ? alumniSelected.alumniId : userLoggedIn.userName;
    };

    const userProfileId = useMemo(() => getUserProfileId(), [userLoggedIn, alumniSelected]);

    const handleOnSuccessUpload = ({ key }) => {
        ///dispatch action to refresh ProfilePicture with recently uplodaded photo
        dispatch(fetchProfilePicture(userProfileId));
    };
    const handleOnErrorUpload = (error) => {
        dispatch(showAlert({ message: "An error ocurred while uploading your profile picture. Please, try again.", severity: "error" }));
    };

    return (
        <>
            <CardRaisedRounded
                sx={{
                    width: "100%",
                    maxWidth: "480px",
                }}
            >
                <AvatarContainer>
                    {(profilePhoto?.dataFromBlob && profilePhoto?.dataFromBlob !== null)
                        ? <ProfilePicture source={profilePhoto?.dataFromBlob} userProfile={(userLoggedIn.profileType === APP_ROLES.PARENTS) ? alumniSelected : userLoggedIn} />
                        :
                        <>
                            <ProfilePictureNotFound />
                            <ProfilePictureUpload
                                userId={getUserProfileId()}
                                onSuccessUpload={handleOnSuccessUpload}
                                onErrorUpload={handleOnErrorUpload}
                            />
                        </>
                    }
                </AvatarContainer>
                {userLoggedIn.profileType === APP_ROLES.SCHOOL_ADMIN &&
                    <CardContent>
                        <AdminDetails admin={userLoggedIn} />
                    </CardContent>
                }
            </CardRaisedRounded>
        </>
    );
};

export default SlotUser;