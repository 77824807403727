import React from "react";
import { Grid } from "@mui/material";
import GridItemProfileInfo from "../shared/GridItemProfileInfo";
import TypographyPersonalInfoField from "../shared/TypographyPersonalInfoField";
import TypographyPersonalInfoFieldTitle from "../shared/TypographyPersonalInfoFieldTitle";

const TrainingDetails = ({ trainingDetailsInfo }) => {
  return (
    <>
      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            TITLE
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {trainingDetailsInfo.title}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            COMPANY NAME
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {trainingDetailsInfo.companyName}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            START DATE
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {trainingDetailsInfo.startDate}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            END DATE
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {trainingDetailsInfo.endDate}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>
      <Grid container marginLeft={3} spacing={2} paddingTop={3}>
        <GridItemProfileInfo>
          <TypographyPersonalInfoFieldTitle>
            ROLES AND RESPONSIBILITY
          </TypographyPersonalInfoFieldTitle>
          <TypographyPersonalInfoField>
            {trainingDetailsInfo.rolesAndResponsibility}
          </TypographyPersonalInfoField>
        </GridItemProfileInfo>
      </Grid>
    </>
  );
};

export default TrainingDetails;
