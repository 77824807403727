import { Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';

function ActiveChatHeader({ participants }) {
    const userLoggedIn = useSelector((state) => state.user.userLoggedIn);
    const [otherParticipants, setOtherParticipants] = useState([{ firstName: '', lastName: '' }]);

    useEffect(() => {
        const others = participants.items
            .filter((i) => i.participantId !== userLoggedIn.userName)
            .map((i) => i.participant);
        setOtherParticipants(others);

    }, [participants])

    const chatTitle = useMemo(() => {
        const opDetails = otherParticipants.map(op => `${op.firstName} ${op.lastName}`);
        const title = opDetails.join(", ");

        return (
            <Typography variant='h6'>
                {title}
            </Typography>
        );
    }, [otherParticipants]);

    return (
        <div>
            {chatTitle}
        </div>
    )
}

export default ActiveChatHeader;